
import { Component, OnInit ,Inject,  ViewChild, ElementRef,afterNextRender,AfterViewInit, ChangeDetectionStrategy} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { ValidationMessagesComponent } from '../../../../shared/errors/validation-messages/validation-messages.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {provideNativeDateAdapter} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { merge, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {ProfileService} from '../../services/profileService'
import {SharedService} from '../../../../shared/shared.service'
import { HttpErrorResponse } from '@angular/common/http';

import {
  MatDialogModule,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';

@Component({
  selector: 'app-add-education-modal',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule,MatFormFieldModule, MatInputModule, MatSelectModule,MatDatepickerModule,MatCheckboxModule,   CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    FormsModule,
    ValidationMessagesComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
  <div class="container text-center">
  <form [formGroup]="educationForm" (ngSubmit)="addEducation()" autocomplete="off" id="addEducation">
    <h2 mat-dialog-title>Education</h2>
    <mat-dialog-content class="mat-typography">

    <div class="col-12">
      <mat-form-field appearance="fill" class="full-width mt-3">
        <mat-label>Institution Name</mat-label>
        <input matInput formControlName="institutionName" placeholder="ex: UW-Madison">
        <mat-error *ngIf="submitted && educationForm.get('institutionName')?.hasError('required')">
          Institution name is required
        </mat-error>
        <mat-error *ngIf="submitted && educationForm.get('institutionName')?.hasError('maxlength')">
          Max length is 30 characters
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-form-field appearance="fill" class="full-width mt-1">
        <mat-label>Major</mat-label>
        <input matInput formControlName="major" placeholder="ex: Biology">
        <mat-error *ngIf="submitted && educationForm.get('major')?.hasError('required')">
          Major is required
        </mat-error>
        <mat-error *ngIf="submitted && educationForm.get('major')?.hasError('maxlength')">
          Max length is 30 characters
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-form-field appearance="fill" class="full-width mt-1">
        <mat-label>Minor</mat-label>
        <input matInput formControlName="minor" placeholder="ex: English Literature">
        <mat-error *ngIf="submitted && educationForm.get('minor')?.hasError('maxlength')">
          Max length is 30 characters
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-form-field appearance="fill" class="full-width mt-1">
        <mat-label>Degree Type</mat-label>
        <input matInput formControlName="degreeType" placeholder="ex: Bachelor of Science">
        <mat-error *ngIf="submitted && educationForm.get('degreeType')?.hasError('maxlength')">
          Max length is 30 characters
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-checkbox formControlName="graduated">Did you graduate?</mat-checkbox>
    </div>

    <div class="row g-3">
      <div class="col-12 col-md-6 ">
        <mat-form-field class="mt-1">
          <mat-label>Start Date</mat-label>
          <input matInput formControlName="startDate" [matDatepicker]="EducationStartDate">
          <mat-error *ngIf="submitted && educationForm.get('startDate')?.hasError('required')">
            Start date is required
          </mat-error>
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="EducationStartDate"></mat-datepicker-toggle>
          <mat-datepicker #EducationStartDate></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-6">
        <mat-form-field class="mt-1">
          <mat-label>End Date</mat-label>
          <input matInput formControlName="endDate" [matDatepicker]="EducationEndDate">
          <mat-error *ngIf="submitted && educationForm.get('endDate')?.hasError('required')">
            End date is required
          </mat-error>
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="EducationEndDate"></mat-datepicker-toggle>
          <mat-datepicker #EducationEndDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button cdkFocusInitial type="submit">Save</button>
    </mat-dialog-actions>
  </form>
</div>


  `,
  styles: `.full-width {
    width: 100%; /* Ensures the field spans the full container width */
  }`
})
export class AddEducationModalComponent implements OnInit {

   
  educationForm: FormGroup = new FormGroup({
    graduated: new FormControl(false) // Default value: unchecked
  });
  submitted = false;
  errorMessages: string[] = [];
  errorMessage = '';
  
  returnUrl: string | null = null;

  constructor(

    private dialogRef: MatDialogRef<AddEducationModalComponent>,
     private profileService: ProfileService,  // Inject ProfileService here
  private sharedService: SharedService  ,   // Inject SharedService here
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {  
    
    
    this.educationForm = this.formBuilder.group({
    institutionName: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    degreeType: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    major: new FormControl('', [ Validators.maxLength(30)]),
    minor: new FormControl('', [ Validators.maxLength(30)]),
    graduated: new FormControl(false),
    startDate: new FormControl('', [Validators.required, Validators.maxLength(15)]),
    endDate: new FormControl('', [Validators.required, Validators.maxLength(15)])
    
  });



  merge(
    this.educationForm.get('institutionName')!.statusChanges!,
    this.educationForm.get('institutionName')!.valueChanges!,
    this.educationForm.get('degreeType')!.statusChanges!,
    this.educationForm.get('degreeType')!.valueChanges!,
    this.educationForm.get('major')!.statusChanges!,
    this.educationForm.get('major')!.valueChanges!,
    this.educationForm.get('minor')!.statusChanges!,
    this.educationForm.get('minor')!.valueChanges!,
    this.educationForm.get('graduated')!.statusChanges!,
    this.educationForm.get('graduated')!.valueChanges!,
    this.educationForm.get('startDate')!.statusChanges!,
    this.educationForm.get('startDate')!.valueChanges!,
    this.educationForm.get('endDate')!.statusChanges!,
    this.educationForm.get('endDate')!.valueChanges!
  
  )
    .pipe(takeUntilDestroyed())
    .subscribe(() => this.updateErrorMessage());
  
  }







ngOnInit(): void {
  this.initializeForm();
      // Listen for changes in the isChecked checkbox
      this.educationForm.get('graduated')?.valueChanges.subscribe((graduated: boolean) => {
        this.toggleEndDateValidator(graduated);
      });
  
    
  };

  
  
initializeForm() {
  this.educationForm = this.formBuilder.group({
    institutionName: ['', [Validators.required, Validators.maxLength(30)]],
    degreeType: ['', [Validators.required, Validators.maxLength(30)]],
    major: ['', [ Validators.maxLength(30)]],
    minor: ['', [ Validators.maxLength(30)]],
    graduated: [false ],
    startDate: ['', [Validators.required, Validators.maxLength(15)]],
    endDate: ['', [Validators.required, Validators.maxLength(15)]],
  });
}




toggleEndDateValidator(graduated: boolean) {
  const endDateControl = this.educationForm.get('endDate');

  if (!graduated) {
    // If "I still work here" is checked, remove the required validator for End Date
    endDateControl?.clearValidators();
  } else {
    // If unchecked, add the required validator back to End Date
    endDateControl?.setValidators([Validators.required, Validators.maxLength(15)]);
  }
  // Update the validity of the endDate field after changing the validators
  endDateControl?.updateValueAndValidity();
}

addEducation() {
  this.submitted = true;
  this.errorMessages = [];

  if (this.educationForm.valid) {
    this.profileService.addEducation(this.educationForm.value).subscribe({
      next: (response: any) => {
        //console.log(":response!!!!!!!!!!!!:", response);
        if (response.success) {
          this.sharedService.showNotification(response.success, response.title, response.message);
          this.dialogRef.close();
          //console.log(":response@@@@@@@", response);
          
        } else {
          this.sharedService.showNotification(response.success, response.title, response.message);
          //console.log(":response&&&&&&&&:", response);
        }
      },
      error: (error: HttpErrorResponse) => {
        // Log the entire error object for debugging
        console.error('Error in adding educaion experience:', error);
        //console.log(":response&&&&&&&&:");

        // Handle the error gracefully and display to the user
        this.sharedService.showNotification(false, 'Error', error.message || 'Unknown error occurred');
      }
    });
  }
  else{
    //console.log("invalid form");
    //console.log(this.educationForm.value);
    this.focusFirstInvalidControl();
  }
}





focusFirstInvalidControl() {
  let form = document.getElementById('addEducation'); // <-- your formID
  if(form){
    let firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
    firstInvalidControl.scrollIntoView();
    (firstInvalidControl as HTMLElement).focus();
  }
  
}
updateErrorMessage() {
  const institutionNameControl = this.educationForm.get('institutionName');
  const degreeTypeControl = this.educationForm.get('degreeType');
  const majorControl = this.educationForm.get('major');
  const minorControl = this.educationForm.get('minor');;
  const startDateControl = this.educationForm.get('startDate');
  const endDateControl = this.educationForm.get('endDate');

  if (institutionNameControl?.hasError('required')) {
    this.errorMessage = 'You must enter an institution name';
  } else if (institutionNameControl?.hasError('maxlength')) {
    this.errorMessage = 'exceeds max character length';
  } else if (degreeTypeControl?.hasError('required')) {
    this.errorMessage = 'You must enter a degree type';
  } else if(degreeTypeControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  }else if(majorControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  }else if(minorControl?.hasError('maxlength')){
      this.errorMessage = 'exceeds max character length';
  }else if(startDateControl?.hasError('required')){
    this.errorMessage = 'you must enter a start date';
  }else if(startDateControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  }else if(endDateControl?.hasError('required')){
    this.errorMessage = 'you must enter an end date';
  }else if(endDateControl?.hasError('maxLength')){
    this.errorMessage = 'exceeds max character length';
  } else{
    this.errorMessage = '';
  }
}



}
