import { Component, Inject, ChangeDetectionStrategy, inject, OnInit } from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';

import {ProfileHeaderComponent} from '../profilePageModals/profile-header/profile-header.component';
import {FileUploadService} from '../services/fileUploadService'
import {AddWorkModalComponent} from '../profilePageModals/add-work-modal/add-work-modal.component';

import {EditEducationModalComponent} from '../education-experience-page/edit-education-modal/edit-education-modal.component';
import { ProfileService } from '../services/profileService';
import {SharedService} from '../../../shared/shared.service'
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import {MatCheckboxModule} from '@angular/material/checkbox';



import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
 
} from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { ProfileHeader } from '../../../shared/account/profileHeader';
import { ServicesResult } from '../../../shared/account/ServicesResult';
import { About } from '../../../shared/account/about';
import { Job } from '../../../shared/account/job';
import { Education } from '../../../shared/account/education';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ApplicationUser } from '../../../shared/account/applicationUser';

@Component({
  selector: 'app-education-experience',
  standalone: true,
  imports: [MatIconModule,MatCheckboxModule, MatDialogTitle, MatFormFieldModule,MatInputModule,MatSelectModule, MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule,CommonModule,
   
    MatButtonModule,
    MatCardModule,],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
<mat-card *ngFor ="let education of educationHistories" class="example-card col-12 col-md-10 col-lg-8 mb-3" appearance="outlined">
<mat-card-header class="d-flex justify-content-between align-items-center">
<mat-card-actions class="position-absolute top-0 end-0 mt-2 d-flex gap-2">


 

  <!-- Container for icons aligned to the end -->



<!-- <button mat-button (click)="openWorkDialog()"> -->
<button mat-button (click)="openEditEducationDialog(education)">
      <mat-icon  aria-label="edit icon" fontIcon="edit"></mat-icon>
</button>

<button mat-button (click)="deleteEducation(education)">
        <mat-icon aria-label="delete icon"  fontIcon="delete">delete</mat-icon>
      </button>
</mat-card-actions>
  </mat-card-header>
  <mat-card-content>
    <div class="col-12">
      <h5>Institution Name:</h5>
      <p>{{education.institutionName}}</p>
    </div>
    <div class="col-12">
      <h5>Major:</h5>
      <p>{{education.major}}</p>
    </div>
    <div class="col-12">
      <h5>Minor:</h5>
      <p>{{education.minor}}</p>
    </div>
    <div class="col-12">
      <h5>DegreeType:</h5>
      <p>{{education.degreeType}}</p>
    </div>


<mat-checkbox  [checked]="education.graduated" [disabled]="true" >did you graduate?</mat-checkbox>
<div class="row">

    <div class="col-6">
      <h5>Start Date:</h5>
      <p>{{education.startDateString}}</p>
    </div>
    <div class="col-6">
      <h5>End Date:</h5>
      <p>{{education.endDateString}}</p>
    </div>
  

</div>
  </mat-card-content>
</mat-card>

  `,
  styles: ``
})
export class EducationPageExperienceComponent implements OnInit {
  readonly dialog = inject(MatDialog);
  educationHistories: Education[] | null=null; 


  

  constructor(private router: Router,private fileUploadService: FileUploadService, public profileService: ProfileService, private sharedService: SharedService,private cdr: ChangeDetectorRef){}

  ngOnInit(): void {
    this.initializeEducationHistories(this.profileService.user);
  
      
      
    };


    openEditEducationDialog(education: Education) {
      this.dialog.open(EditEducationModalComponent, {
        data: education         // Pass the Job model here
      });
    
    }




    initializeEducationHistories(user:ApplicationUser) {
      this.profileService.getEducation(user.id).subscribe({
        next: (educationHistories: Education[]) => {  
          //console.log("Received educationhistories data:", educationHistories);  // Log the result object
          // Map through the received jobs array and add each job to the list
          this.educationHistories = educationHistories.map(education => ({
            institutionName: education.institutionName || '',  // Default if empty
            major: education.major || '',
            minor: education.minor || '',
            degreeType: education.degreeType || '',
            startDate: education.startDate || new Date('2024-09-17'),
            endDate: education.endDate || new Date('2024-09-17'),
            graduated: education.graduated || false,
            endDateString: education.endDateString || '',
            startDateString: education.startDateString || '',
            educationId: education.educationId || 1
          }));
    
           
                  // Trigger change detection manually
          this.cdr.markForCheck();
        },
        error: (error) => {
          console.error('Error fetching education:', error);
          // Handle error and set default values
         // Set default job in case of error (or handle error as needed)
         this.educationHistories = [{
          institutionName: '',
          major: '',
          minor: '',
          degreeType: '',
          startDate: new Date('2024-09-17'),
          endDate: new Date('2024-09-17'),
          graduated: false,
          endDateString: '',
          startDateString: '',
          educationId : 1
        }];
                // Trigger change detection manually
                this.cdr.markForCheck();
        },
        complete: () => {
         // console.log('education retrieval completed');
        },
      });
    }


    
    deleteEducation(education: Education) {
      // Log the job being deleted for debugging
     // console.log("Deleting education:", education);
  
      // Call the profile service, passing the job details or job ID as needed
      this.profileService.deleteEducation(education).subscribe({
          next: (response: any) => {
              //console.log(":response!!!!!!!!!!!!:", response);
              if (response.success) {
                // this.jobs= this.jobs?.filter(j=>j.jobId !==job.jobId) ?? null;
                  
                  this.sharedService.showNotification(response.success, response.title, response.message);
                 // console.log(":response@@@@@@@", response);
              } else {
                  this.sharedService.showNotification(response.success, response.title, response.message);
                 // console.log(":response&&&&&&&&:", response);
              }
          },
          error: (error: HttpErrorResponse) => {
              // Log the entire error object for debugging
              console.error('Error in deleting education experience:', error);
              this.sharedService.showNotification(false, 'Error', error.message || 'Unknown error occurred');
          }
      });
  }

}
