import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef  } from '@angular/core';
import { FormsModule } from '@angular/forms';


@Component({
  selector: 'app-chat-input',
  standalone: true,
  imports: [FormsModule],
  template: `
  <form #messageForm="ngForm"  (ngSubmit)="sendMessage()" autocomplete="off">
<div class="row">

<div class="col-9">
  <input type="text" class="form-control" name="messageContent" [(ngModel)]="messageContent" required placeholder="send message">



</div>

<div class="col-3">
  <div class="d-grid">
<button class="btn btn-dark btn-block" [disabled]= "!messageForm.valid" type="submit"> send</button>
  </div>

</div>
</div>
  </form>

  `,
  styles: ``
})
export class ChatInputComponent implements OnInit {
messageContent:string='';
@Output() contentEmitter = new EventEmitter();


constructor(private cdr: ChangeDetectorRef){}


  ngOnInit(): void {

  }

  sendMessage(){
if(this.messageContent.trim() !==""){
this.contentEmitter.emit(this.messageContent);

console.log("messagecontent:",this.messageContent)
}

this.messageContent= "";


  }

}
