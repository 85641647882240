import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { DirectMessage } from '../../shared/chat/directMessage';
import { CommonModule } from '@angular/common';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import { ChatService } from '../../Account/profile/services/ChatService';
import { EventComment } from '../../shared/chat/eventComment';
import {  Router } from '@angular/router';
import { PrivateChatModalComponent } from '../../chat/private-chat-modal/private-chat-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AccountService } from '../../Account/account.Service';

@Component({
  selector: 'app-event-comments',
  standalone: true,
  imports: [CommonModule,MatMenuModule, MatIconModule],
  template: `
  <div style="overflow-y:auto; ">


<div class="card-text" *ngFor="let eventComment of eventComments">
<div class="d-flex align-itmes-start">
   <!-- Clickable image with menu trigger -->
   <img 
        [src]="eventComment?.profilePic"
        alt="Avatar" 
        class="avatar me-2" 
        [matMenuTriggerFor]="profileMenu" 
        style="cursor: pointer;" 
      >
      
      <!-- Menu that opens when the image is clicked -->
      <mat-menu #profileMenu="matMenu">
        <button mat-menu-item (click)="viewProfile(eventComment.userId!)">View Profile</button>
        <button  *ngIf="(accountService.user$ | async)?.id !== eventComment.userId"> mat-menu-item (click)="openPrivateChat(eventComment!)">direct message</button>
      </mat-menu>

<div class="d-flex flex-column">
<span class="fw-bold">{{eventComment.firstName}} {{eventComment.lastName}}&middot;{{eventComment.localTime}}{{eventComment.date}}</span>
<span class="ml-1 mb-3 mt-1"> {{eventComment.commentText}}</span>
</div>
</div>
</div>



</div>
  `,
  styles: `.avatar {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    border-radius: 50%;
 }`
})
export class EventCommentsComponent implements OnInit {







@Input() eventComments: EventComment[]=[];


constructor(private modalService: NgbModal,private chatService: ChatService,private cdr: ChangeDetectorRef, private router: Router,public accountService: AccountService){}


viewProfile(userId: string){

  this.router.navigateByUrl(`/view-profile/${userId}`)
}


sendEventComment(eventComment: EventComment){

  //console.log("send event comment message clicked");
}

  ngOnInit(): void {
  
   
  }


  openPrivateChat(eventComment:EventComment)
  {
    
   // console.log(" in event comments#@#@#@#@#@#@#@#@#@:",eventComment)
    const modalRef= this.modalService.open(PrivateChatModalComponent);
    modalRef.componentInstance.eventComment= eventComment;
  }
  

}
