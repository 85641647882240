import { Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-validation-messages',
  standalone: true,
  imports: [CommonModule],
  template: `
   <ul class="text-danger" *ngIf="errorMessages">
    <span *ngFor="let error of errorMessages">
        {{error}}
</span>
</ul>
  `,
  styles: ``
})
export class ValidationMessagesComponent {
  @Input() errorMessages: string[] | undefined;
}
