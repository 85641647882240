
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, OnInit, Output,  ViewChild ,forwardRef } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { GoogleMap } from '@angular/google-maps';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({ 
  selector: 'app-google-map',
  standalone: true,
  imports: [],
  template: `
   <div class="pac-card my-5" id="pac-card">
    <h1>Enter Event Location</h1>
  <div id="pac-container">
    <input id="pac-input" type="text" placeholder="Enter a location" (input)="onAddressChange($event)" [value]="value"/>
  </div>
</div>
<div id="map"></div>
<div id="infowindow-content">
  <span id="place-name" class="title"></span><br />
  <span id="place-address"></span>
</div>
  `,
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => GoogleMapComponent),
        multi: true,
      },
    ],
  
  styles: `/* 
  * Always set the map height explicitly to define the size of the div element
  * that contains the map. 
  */
  #map {
     height: 80vh;
       width: 70vw;
       border-radius: 12px;
       border: #4d90fe;
       
   }
   
   
   /* 
    * Optional: Makes the sample page fill the window. 
    */
   html,
   body {
     height: 100%;
     margin: 0;
     padding: 0;
   }
   
   #description {
     font-family: Roboto;
     font-size: 15px;
     font-weight: 300;
   }
   
   #infowindow-content .title {
     font-weight: bold;
   }
   
   #infowindow-content {
     display: none;
   }
   
   #map #infowindow-content {
     display: inline;
   }
   
   .pac-card {
     background-color: #fff;
     border: 0;
     border-radius: 2px;
     box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
     font: 400 18px Roboto, Arial, sans-serif;
     font-family: Roboto;
   }
   
   #pac-container {
     padding-bottom: 12px;
     margin-right: 12px;
   }
   
   #pac-input {
     background-color: #fff;
     font-family: Roboto;
     font-size: 15px;
     font-weight: 300;
   }
   
   #pac-input:focus {
     border-color: #4d90fe;
   }
   
   #title {
     color: #fff;
     background-color: #4d90fe;
     font-size: 25px;
     font-weight: 500;
     padding: 6px 12px;
   }`
})
export class GoogleMapComponent implements AfterViewInit, ControlValueAccessor {

   // Define an EventEmitter to send data to the parent
   @Output() dataToParent: EventEmitter<{ address: string; latitude: number; longitude: number }> = new EventEmitter();

   value: string = '';
  onChange = (value: string) => {};
   onTouched: any = () => {};

  constructor(){}
 // This method is called when the input value changes
 onAddressChange(event: any) {
  this.value = event.target.value;
  this.onChange(this.value);
  this.onTouched();
}


  // Writes the form value to the input field
  writeValue(value: string): void {
    this.value = value;
  }

  // Registers change callback
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Registers touch callback
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // ngAfterViewInit() {
  //   // Load Google Maps script dynamically
  //   this.loadGoogleScript()
  //     .then(() => {
  //       this.initializeMap();
    
  
  //     })
  //     .catch((error) => {
  //       console.error('Google Maps API failed to load:', error);
  //       console.log('Google Maps API failed to load:');
  //     });
  // }
  
  ngAfterViewInit() {
    // Load Google Maps script dynamically
   
        this.initializeMap();
    

     
  }
  
  
  
      // loadGoogleScript(): Promise<void>{
      //   return new Promise((resolve,reject)=> {
      //     if (isPlatformBrowser(this.platformId)) {
      //       console.log("google script loaded");
      //      // console.log('scriptElementRef:', this.scriptElementRef);  // Check if scriptElementRef is set
      //       const script = document.createElement('script');
      //       script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCDRCW8LibsSE8deZsLgKvchXQ5K72GNns&libraries=places&v=weekly";
      //       script.async = true;
      //       script.defer = true;
      //       script.onload = () => resolve();
      //       script.onerror = (error) => reject(error);
      //       document.head.appendChild(script);  // Add script to the head or any other appropriate locatio
      //     }
      //   })
    
  
      // }
  
  
      
  
      initializeMap(): void {
       // console.log("initiliaze map script loaded");
        let map: google.maps.Map;
        const center : google.maps.LatLngLiteral= { lat: 30, lng:-110};
    map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,{
     center
      }
    );
  
  
  
    const card = document.getElementById("pac-card") as HTMLElement;
    const input = document.getElementById("pac-input") as HTMLInputElement;
    // const biasInputElement = document.getElementById(
    //   "use-location-bias"
    // ) as HTMLInputElement;
    // const strictBoundsInputElement = document.getElementById(
    //   "use-strict-bounds"
    // ) as HTMLInputElement;
    const options = {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
    };
  
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(card);
  
    const autocomplete = new google.maps.places.Autocomplete(input, options);
  
    // Bind the map's bounds (viewport) property to the autocomplete object,
    // so that the autocomplete requests use the current map bounds for the
    // bounds option in the request.
    autocomplete.bindTo("bounds", map);
  
    const infowindow = new google.maps.InfoWindow();
    const infowindowContent = document.getElementById(
      "infowindow-content"
    ) as HTMLElement;
  
    infowindow.setContent(infowindowContent);
  
    const marker = new google.maps.Marker({
      map,
      anchorPoint: new google.maps.Point(0, -29),
    });
  
    autocomplete.addListener("place_changed", () => {
      infowindow.close();
      marker.setVisible(false);
  
      const place = autocomplete.getPlace();

      //console.log('placething:',JSON.stringify(place.geometry?.location));
     // console.log('formatedname:', place.formatted_address)
      
  
      if (!place.geometry || !place.geometry.location) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }
  
      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }
  
      marker.setPosition(place.geometry.location);
      marker.setVisible(true);
  
       infowindowContent.children["place-name"].textContent = place.name;
       infowindowContent.children["place-address"].textContent =
         place.formatted_address;
      infowindow.open(map, marker);

      //console.log("full data", place);
      //emit location full address to parent component(add events page)
      const eventAddress = place.formatted_address || '';
      //console.log("eventAddress",eventAddress);
      const lat = place.geometry.location.lat();
      //console.log("lat",lat);
      const lng = place.geometry.location.lng();
      //console.log("lng",lng);
      const data = {
        address: eventAddress,
        latitude: lat,
        longitude: lng
      };
      this.dataToParent.emit(data);
     
       // Emit the data to the parent
      
    });


 
    // Sets a listener on a radio button to change the filter type on Places
    // Autocomplete.
    // function setupClickListener(id, types) {
    //   const radioButton = document.getElementById(id) as HTMLInputElement;
  
    //   radioButton.addEventListener("click", () => {
    //     autocomplete.setTypes(types);
    //     input.value = "";
    //   });
    // }
  
    // setupClickListener("changetype-all", []);
    // setupClickListener("changetype-address", ["address"]);
    // setupClickListener("changetype-establishment", ["establishment"]);
    // setupClickListener("changetype-geocode", ["geocode"]);
    // setupClickListener("changetype-cities", ["(cities)"]);
    // setupClickListener("changetype-regions", ["(regions)"]);
  
    // biasInputElement.addEventListener("change", () => {
    //   if (biasInputElement.checked) {
    //     autocomplete.bindTo("bounds", map);
    //   } else {
    //     // User wants to turn off location bias, so three things need to happen:
    //     // 1. Unbind from map
    //     // 2. Reset the bounds to whole world
    //     // 3. Uncheck the strict bounds checkbox UI (which also disables strict bounds)
    //     autocomplete.unbind("bounds");
    //     autocomplete.setBounds({ east: 180, west: -180, north: 90, south: -90 });
    //     strictBoundsInputElement.checked = biasInputElement.checked;
    //   }
  
    //   input.value = "";
    // });
  
    // strictBoundsInputElement.addEventListener("change", () => {
    //   autocomplete.setOptions({
    //     strictBounds: strictBoundsInputElement.checked,
    //   });
  
    //   if (strictBoundsInputElement.checked) {
    //     biasInputElement.checked = strictBoundsInputElement.checked;
    //     autocomplete.bindTo("bounds", map);
    //   }
  
    //   input.value = "";
    // });
  //}
  
  // declare global {
  //   interface Window {
  //     initMap: () => void;
  //   }
  
  // window.initMap = initMap;
  }
  }
  
