import { Component, Inject, ChangeDetectionStrategy, inject, OnInit } from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';

import {ProfileHeaderComponent} from '../profilePageModals/profile-header/profile-header.component';
import {FileUploadService} from '../services/fileUploadService'
import {AddWorkModalComponent} from '../profilePageModals/add-work-modal/add-work-modal.component';

import {EditEducationModalComponent} from '../education-experience-page/edit-education-modal/edit-education-modal.component';
import { ProfileService } from '../services/profileService';
import {SharedService} from '../../../shared/shared.service'
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import {EditWorkModalComponent} from './edit-work-modal/edit-work-modal.component';
import {MatCheckboxModule} from '@angular/material/checkbox';

import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
 
} from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { ProfileHeader } from '../../../shared/account/profileHeader';
import { ServicesResult } from '../../../shared/account/ServicesResult';
import { About } from '../../../shared/account/about';
import { Job } from '../../../shared/account/job';
import { Education } from '../../../shared/account/education';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ProfileComponent } from '../profile.component';
import { ApplicationUser } from '../../../shared/account/applicationUser';

@Component({
  selector: 'app-work-experience-page',
  standalone: true,
  imports: [MatIconModule,MatCheckboxModule, MatDialogTitle, MatFormFieldModule,MatInputModule,MatSelectModule, MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule,CommonModule,
   
    MatButtonModule,
    MatCardModule,
    
    ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `

<mat-card *ngFor="let job of jobs" class="example-card col-12 col-md-10 col-lg-8 mb-3" appearance="outlined">
  <mat-card-header class="d-flex justify-content-between align-items-center">
    <!-- Container for icons aligned to the end -->
    <mat-card-actions class="position-absolute top-0 end-0 mt-2 d-flex gap-2">
      <button mat-button (click)="openEditWorkDialog(job)">
        <mat-icon aria-label="edit icon">edit</mat-icon>
      </button>

      <button mat-button (click)="deleteWorkExperience(job)">
        <mat-icon aria-label="delete icon" fontIcon="delete">delete</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card-header>

  <mat-card-content>
    <div class="col-12">
      <h5>Title:</h5>
      <p>{{job.positionTitle}}</p>
    </div>
    <div class="col-12">
      <h5>Company:</h5>
      <p>{{job.companyName}}</p>
    </div>
    <div class="col-12">
      <h5>Description:</h5>
      <p>{{job.positionDescription}}</p>
    </div>

    <mat-checkbox [checked]="job.stillWorkHere" [disabled]="true">Do you still work here?</mat-checkbox>

    <div class="row">
      <div class="col-6">
        <h5>Start Date:</h5>
        <p>{{job.startDateString}}</p>
      </div>
      <div class="col-6">
        <h5>End Date:</h5>
        <p>{{job.endDateString}}</p>
      </div>
    </div>
  </mat-card-content>
</mat-card>


  `,
  styles: ``
})
export class WorkExperiencePageComponent implements OnInit  {
  readonly dialog = inject(MatDialog);
  jobs: Job[] | null=null; 



  constructor( private router: Router,private fileUploadService: FileUploadService, public profileService: ProfileService, private sharedService: SharedService,private cdr: ChangeDetectorRef){}

  ngOnInit(): void {
    this.initializeJobs(this.profileService.user);
  
      
      
    };


    openEditWorkDialog(job: Job) {
      this.dialog.open(EditWorkModalComponent, {
        data: job         // Pass the Job model here
      });
    
    }




    initializeJobs(user:ApplicationUser) {
      this.profileService.getJobs(user.id).subscribe({
        next: (jobs: Job[]) => {  
         // console.log("Received about data:", jobs);  // Log the result object
          // Map through the received jobs array and add each job to the list
          this.jobs = jobs.map(job => ({
            positionTitle: job.positionTitle || '',  // Default if empty
            positionDescription: job.positionDescription || '',
            companyName: job.companyName || '',
            startDate: job.startDate || new Date('2024-09-17'),
            endDate: job.endDate || new Date('2024-09-17'),
            stillWorkHere: job.stillWorkHere || false,
            startDateString: job.startDateString ||'',
            endDateString: job.endDateString|| '',
            jobId: job.jobId || 1
          }));
    
           
                  // Trigger change detection manually
          this.cdr.markForCheck();
        },
        error: (error) => {
          console.error('Error fetching job:', error);
          // Handle error and set default values
         // Set default job in case of error (or handle error as needed)
         this.jobs = [{
          positionTitle: '',
          positionDescription: '',
          companyName: '',
          startDate: new Date('2024-09-17'),
          endDate: new Date('2024-09-17'),
          stillWorkHere: false,
          startDateString: '',
          endDateString: '',
          jobId : 1
        }];
                // Trigger change detection manually
                this.cdr.markForCheck();
        },
        complete: () => {
          //console.log('job retrieval completed');
        },
      });
    }
    

    deleteWorkExperience(job: Job) {
      // Log the job being deleted for debugging
      //console.log("Deleting job:", job);
  
      // Call the profile service, passing the job details or job ID as needed
      this.profileService.deleteWorkExperience(job).subscribe({
          next: (response: any) => {
             // console.log(":response!!!!!!!!!!!!:", response);
              if (response.success) {
                // this.jobs= this.jobs?.filter(j=>j.jobId !==job.jobId) ?? null;
                  this.sharedService.showNotification(response.success, response.title, response.message);
                  //console.log(":response@@@@@@@", response);
              } else {
                  this.sharedService.showNotification(response.success, response.title, response.message);
                 // console.log(":response&&&&&&&&:", response);
              }
          },
          error: (error: HttpErrorResponse) => {
              // Log the entire error object for debugging
              console.error('Error in deleting work experience:', error);
              this.sharedService.showNotification(false, 'Error', error.message || 'Unknown error occurred');
          }
      });
  }
  


}
