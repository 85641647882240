import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { Router, NavigationEnd , Event} from '@angular/router';
import { NavigationBarComponent } from './navigationbar/navigation-bar.component';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [HeaderComponent,NavigationBarComponent,CommonModule],

  //conditionally render header if url is login, register 
  //render navigation bar if matches any other url
  template: `
   <ng-template #headerTemplate>
 <app-header></app-header>
   </ng-template>

   <ng-template #navigationBarTemplate>
 <app-navigation-bar></app-navigation-bar>
   </ng-template>

   <ng-container *ngTemplateOutlet="currentTemplate"></ng-container>
  `,
  styles: ``
})
export class ToolbarComponent implements OnInit {
  @ViewChild('headerTemplate', { static: true }) headerTemplate!: TemplateRef<any>;
  @ViewChild('navigationBarTemplate', { static: true }) navigationBarTemplate!: TemplateRef<any>;

  currentTemplate: TemplateRef<any> | null = null;

  constructor(private router: Router) {}


  ngOnInit() {
    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.updateView(event.urlAfterRedirects);
    });
  }

  updateView(url: string) {
    if (url === '/login' || url === '/register') {
      this.currentTemplate = this.headerTemplate;
    } else {
      this.currentTemplate = this.navigationBarTemplate;
    }
  }
}
