import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { SpinnerService } from '../../Account/profile/services/SpinnerService';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {

  constructor(private spinnerService: SpinnerService) {

    console.log("spinner service inti in interceptort");
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('SpinnerInterceptor hit:', request.url); // Log the intercepted request
    this.spinnerService.show();
    return next.handle(request).pipe(
        
      finalize(() =>
        { console.log('Request completed:', request.url); // Log after request is complete
            this.spinnerService.hide()
  })
    );
  }
}
