import { Component, Inject, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { AccountService } from './Account/account.Service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SpinnerInterceptor } from './shared/interceptors/SpinnerInterceptor';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { SpinnerService } from './Account/profile/services/SpinnerService';




@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToolbarComponent,MatProgressSpinnerModule,CommonModule],
  template: `
    <app-toolbar> </app-toolbar>
    <div *ngIf="spinnerService.loading$ | async" class="loading-spinner">
      <mat-spinner></mat-spinner>
    </div>
    <router-outlet />

  `,
   styles: [`
    .loading-spinner {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1000;
    }
  `]
})
export class AppComponent implements OnInit {

  readonly dialog = inject(MatDialog);

  
  constructor(private accountService:AccountService, public spinnerService: SpinnerService){ 
    console.log('SpinnerInterceptor initialized');
  }

  ngOnInit(): void {
   

      this.accountService.checkAuthStatus();
   
  }

  // openChatDialog() {
  //   this.dialog.open(ChatComponentComponent);
  
  // }
  // private refreshUser() {
  //   console.log('refresh user reached');
  //   this.accountService.refreshUser().subscribe({
  //     next: authResponse => {
  //       if (authResponse) {
  //         console.log('User authenticated:', authResponse);
  //       }
  //     },
  //     error: _ => {
  //       this.accountService.logout();
  //     }
  //   });
  // }
  title = 'Angular-Network-App';
}
