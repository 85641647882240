import { Component, Inject, ChangeDetectionStrategy, inject, OnInit } from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';

import {ProfileHeaderComponent} from './profilePageModals/profile-header/profile-header.component';
import {FileUploadService} from './services/fileUploadService'
import {AddWorkModalComponent} from './profilePageModals/add-work-modal/add-work-modal.component';

import {AddEducationModalComponent} from './profilePageModals/add-education-modal/add-education-modal.component';
import { ProfileService } from './services/profileService';
import {SharedService} from '../../shared/shared.service'
import { ActivatedRoute, Router, RouterModule,NavigationEnd  } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';


import {
  MatDialog
 
} from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { ProfileHeader } from '../../shared/account/profileHeader';
import { ServicesResult } from '../../shared/account/ServicesResult';
import { About } from '../../shared/account/about';
import { Job } from '../../shared/account/job';
import { Education } from '../../shared/account/education';
import { WorkExperiencePageComponent } from './work-experience-page/work-experience-page.component';
import { ApplicationUser } from '../../shared/account/applicationUser';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [RouterModule,MatMenuModule,MatButtonModule,MatCardModule,MatIconModule,MatButtonModule,CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `

<!-- Conditionally show parent content if no child route is active -->
  <div *ngIf="!isChildRouteActive" class="container mt-5">
  <div class="d-flex flex-column align-items-center">
    


    <!-- Card 1 -->
  
      <mat-card class="example-card col-12 col-md-10 col-lg-8 mb-3  rounded-lg shadow-lg" >
      <mat-card-header >
      
      
 <div class="d-flex flex-column ">

 <img   [src]=" profileHeader?.profilePic"    alt="Avatar" class="avatar my-3 mx-3">


 <!-- <p>{{ profileHeader?.profilePic || profileHeader?.googleProfilePic || 'No URL found' }}</p> -->
          <mat-card-title>{{profileHeader?.firstName}}  {{profileHeader?.lastName}}</mat-card-title>
          <mat-card-subtitle>{{profileHeader?.banner}}</mat-card-subtitle>
        
          <h6 *ngIf="profileHeader?.linkedinURL" class="mt-3">LinkedIn: <a [href]="profileHeader?.linkedinURL" target="_blank">{{profileHeader?.linkedinURL}}</a>
</h6>
<h6 *ngIf="profileHeader?.personalWebsite" class="mt-1" >personal website: <a [href]="profileHeader?.personalWebsite" target="_blank">{{profileHeader?.personalWebsite}}</a>
</h6>
       

        <mat-card-title class="mt-3">About</mat-card-title>
        

    <p>
      {{profileHeader?.about}}
    </p>
  
          </div>


<button mat-icon-button  (click)="openProfileHeaderDialog()" class="position-absolute top-0 end-0 mt-2 me-2">
      <mat-icon  aria-label="edit icon" fontIcon="edit"></mat-icon>
</button>

  </mat-card-header>
       
      </mat-card>
   



    

    <!-- Card 3 -->
    <mat-card class="example-card col-12 col-md-10 col-lg-8 mb-3 rounded-lg shadow-lg" >
    <mat-card-header class="d-flex justify-content-between align-items-center">
    <mat-card-title class=" h1">Experience</mat-card-title>
    
      <!-- Container for icons aligned to the end -->
      <mat-card-actions class="position-absolute top-0 end-0 mt-2 d-flex gap-2">



      <button   *ngIf="hasExperience !== null && hasExperience" mat-icon-button  [routerLink]="['/profile', 'workExperience']">
  <mat-icon aria-label="edit icon">edit</mat-icon>
</button>
      <button mat-button  [matMenuTriggerFor]="workMenu" ><mat-icon  aria-label="add icon" fontIcon="add"></mat-icon><mat-menu #workMenu="matMenu">
  <button mat-menu-item (click)="openWorkDialog()" >Add Position</button>
  

</mat-menu>
    
</button>





</mat-card-actions>
  </mat-card-header>
        <mat-card-content class="mt-5" *ngFor= "let job of jobs">
          <hr>
        <mat-card-title>{{job.positionTitle}} </mat-card-title>
        <mat-card-subtitle  >{{job.companyName}} </mat-card-subtitle>
       
          <mat-card-subtitle class="mb-3">{{job.startDateString}}-{{job.endDateString !== '' ? job.endDateString : 'present' }}</mat-card-subtitle >
         

          <p class="fw-normal">{{job.positionDescription}}</p>
  
        </mat-card-content>
      </mat-card>
    

    <!-- Card 4 -->
    
    <mat-card class="example-card col-12 col-md-10 col-lg-8 mb-3 rounded-lg shadow-lg">
    <mat-card-header class="d-flex justify-content-between align-items-center">
    <mat-card-title class="mb-3" >Education</mat-card-title>
    
      <!-- Container for icons aligned to the end -->
      <mat-card-actions class="position-absolute top-0 end-0 mt-2 d-flex gap-2">
      <button  *ngIf="hasEducation !== null && hasEducation" mat-icon-button [routerLink]="['/profile', 'educationExperience']">
  <mat-icon aria-label="edit icon">edit</mat-icon>
</button>
      <button mat-button [matMenuTriggerFor]="educationMenu" ><mat-icon aria-label="add icon" fontIcon="add"></mat-icon><mat-menu #educationMenu="matMenu">
  <button mat-menu-item (click)="openEducationDialog()">Add Education</button>

</mat-menu>
    
</button>

</mat-card-actions>
  </mat-card-header>
        <mat-card-content class="mt-3" *ngFor ="let education of educationList">
          <hr>
         <mat-card-title>{{education.institutionName}}</mat-card-title>
         <mat-card-subtitle >{{education.degreeType}}</mat-card-subtitle >
         <mat-card-subtitle class="mb-3">{{education.startDateString}}-{{education.endDateString !== '' ? education.endDateString : 'present' }}</mat-card-subtitle >
         <mat-card-subtitle >{{education.major}}</mat-card-subtitle >
         <mat-card-subtitle >{{education.minor}}</mat-card-subtitle >
         <p> graduated:{{education.graduated}}</p>
        </mat-card-content>
      </mat-card>
    </div>
    
  </div>


<!--child compoent goes here--> 
  <router-outlet></router-outlet>


  `,
  styles: `

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0; /* Remove extra margin */
  padding: 0; /* Remove extra padding */
}

  .example-card {
    max-width: 400px;
  }
  .example-header-image {
  background-image: url('https://material.angular.io/assets/img/examples/shiba1.jpg');
  background-size: cover;
  }
  
`
})
export class ProfileComponent implements OnInit {
  profileHeader: ProfileHeader | null =null;
  about: About | null=null ;
  jobs: Job[] | null=null; 
  educationList: Education[] | null=null; 
  hasExperience: boolean =false;
  hasEducation: boolean =false;


  user: ApplicationUser | null = null;
  
  // profileHeader: ProfileHeader = {
  //   firstName: '',
  //   lastName: '',
  //   banner: '',
  //   linkedinURL: '',
  //   personalWebsite: '',
  // };

  readonly dialog = inject(MatDialog);
  isChildRouteActive = false;

  constructor(private router: Router,private route: ActivatedRoute, private fileUploadService: FileUploadService, private profileService: ProfileService, private sharedService: SharedService,private cdr: ChangeDetectorRef){

// Check if the current route has a child route (i.e., workExperience or education) and if so make parent comp disapeear when go to child compeonetn
this.router.events.subscribe(() => {
  this.isChildRouteActive = this.route.firstChild !== null;
});


  }

  async ngOnInit(): Promise<void> {
    try {
      // Wait for getUserData to complete
      await this.getUserData();
  

      if(this.user){
      // Now initialize other data that depends on this.user
       this.initializeProfileHeader(this.user);
       this.initializeJobs(this.user);
       this.initializeEducation(this.user);
      }
      // Subscribe to router events to detect navigation back to /profile
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd && event.urlAfterRedirects === '/profile' && this.user) {
          this.initializeProfileHeader(this.user);
          this.initializeJobs(this.user);
          this.initializeEducation(this.user);
        }
      });
    } catch (error) {
      console.error("Error loading user data:", error);
    }
  }
  

//   openAboutDialog() {
//     this.dialog.open(AboutModalComponent);

// }

openProfileHeaderDialog() {
  this.dialog.open(ProfileHeaderComponent, {
    data: this.profileHeader, // Pass the profileHeader data
  });

}

openWorkDialog() {
  this.dialog.open(AddWorkModalComponent);

}

openEducationDialog() {
  this.dialog.open(AddEducationModalComponent);

}
getUserData(): Promise<ApplicationUser> {
  return new Promise((resolve, reject) => {
    // Simulate async data fetching; replace this with actual service call
    this.profileService.getUser().subscribe({
      next: (user: ApplicationUser) => {
        this.user = user;
        //console.log("user in get userdata",this.user)
        resolve(user);
      },
      error: (err) => reject(err),
    });
  });
}

// getUserData(): void {
//   this.profileService.getUser().subscribe({
//     next: (user: ApplicationUser) => {
//       this.user = user;
//       console.log('User data received:', this.user);

    
//     },
//     error: (error) => {
//       console.error('Error retrieving user data:', error);
//     },
//   });
// }
// openProfilePictureDialog() {
//   this.dialog.open(ProfilePictureModalComponent);

// }


// initializeProfilePic(){

//   this.fileUploadService.getProfilePicture();

// console.log("ryryyryryryryryyryryryr")



// }

initializeProfileHeader(user: ApplicationUser) {
  this.profileService.getProfileHeader(user.id).subscribe({
    next: (profileHeader: ProfileHeader) => {  // Now expect ProfileHeader directly
      //console.log("Received ProfileHeader data:", profileHeader);  // Log the result object
        // Assign the received profileHeader to the component's profileHeader
        this.profileHeader = {
          firstName: profileHeader.firstName || '',  // Default if empty
          lastName: profileHeader.lastName || '',    // Default if empty
          banner: profileHeader.banner || '',                        // Default if empty
          linkedinURL: profileHeader.linkedinURL || '',              // Default if empty
          personalWebsite: profileHeader.personalWebsite || '' ,
           profilePicFile: null,
          profilePic: profileHeader.profilePic || '',
          about: profileHeader.about || '',
          applicationUserId: profileHeader.applicationUserId || '',
          isGoogleAccount: profileHeader.isGoogleAccount,
          googleProfilePic: profileHeader.googleProfilePic  || '',
          useGooglePic: profileHeader.useGooglePic 
                // Default if empty
        };

        //console.log("asasasasasa",profileHeader.banner)
              // Trigger change detection manually
      this.cdr.markForCheck();
    },
    error: (error) => {
      console.error('Error fetching profile header:', error);
      // Handle error and set default values
      this.profileHeader = {
        firstName: '',
        lastName: '',
        banner: '',
        linkedinURL: '',
        personalWebsite: '',
        profilePicFile: null,
        profilePic: '',
        about:'',
        applicationUserId:'',
        isGoogleAccount: false,
        googleProfilePic: '',
        useGooglePic: false
      };
            // Trigger change detection manually
            this.cdr.markForCheck();
    },
    complete: () => {
      //console.log('Profile header retrieval completed');
    },
  });
}




// initializeAbout() {
//   this.profileService.getAbout().subscribe({
//     next: (about: About) => {  
//       console.log("Received about data:", about);  // Log the result object
//         // Assign the received profileHeader to the component's profileHeader
//         this.about = {
//           userBio: about.userBio || '',  // Default if empty
        
//         };

       
//               // Trigger change detection manually
//       this.cdr.markForCheck();
//     },
//     error: (error) => {
//       console.error('Error fetching about:', error);
//       // Handle error and set default values
//       this.about = {
//         userBio: '',
    
//       };
//             // Trigger change detection manually
//             this.cdr.markForCheck();
//     },
//     complete: () => {
//       console.log('about retrieval completed');
//     },
//   });
// }



initializeJobs(user: ApplicationUser) {
  this.profileService.getJobs(user.id).subscribe({
    next: (jobs: Job[]) => {  
      this.jobs = jobs;
      // Properly handle null or undefined educationList
      this.hasExperience= !!jobs && jobs.length > 0;
     // console.log("Received about data:", jobs);  // Log the result object
      // Map through the received jobs array and add each job to the list
      this.jobs = jobs.map(job => ({
        positionTitle: job.positionTitle || '',  // Default if empty
        positionDescription: job.positionDescription || '',
        companyName: job.companyName || '',
        startDate: job.startDate || new Date('2024-09-17'),
        endDate: job.endDate || new Date('2024-09-17'),
        endDateString: job.endDateString || '',
        startDateString: job.startDateString || '',
        stillWorkHere: job.stillWorkHere || false,
        jobId: job.jobId 
      }));

       
              // Trigger change detection manually
      this.cdr.markForCheck();
    },
    error: (error) => {
      console.error('Error fetching job:', error);
      // Handle error and set default values
     // Set default job in case of error (or handle error as needed)
     this.jobs = [{
      positionTitle: '',
      positionDescription: '',
      companyName: '',
      startDate: new Date('2024-09-17'),
      endDate: new Date('2024-09-17'),
      stillWorkHere: false,
      startDateString: '',
      endDateString:'',
      jobId: 1
    }];
            // Trigger change detection manually
            this.cdr.markForCheck();
    },
    complete: () => {
     // console.log('job retrieval completed');
    },
  });
}



initializeEducation(user: ApplicationUser) {
  this.profileService.getEducation(user.id).subscribe({
    next: (educationList: Education[]) => {  
      this.educationList = educationList;
      // Properly handle null or undefined educationList
      this.hasEducation = !!educationList && educationList.length > 0;
     // console.log("Received education data:", educationList);  // Log the result object
      //console.log('Education list:', this.educationList);
      //console.log('Has education:', this.hasEducation);
      // Map through the received jobs array and add each job to the list
      this.educationList = educationList.map(education => ({
        institutionName: education.institutionName || '',  // Default if empty
        major: education.major || '', 
        minor: education.minor || '', 
        startDate: education.startDate || new Date('2024-09-17'),
        endDate: education.endDate || new Date('2024-09-17'),
        graduated: education.graduated || false,
        startDateString: education.startDateString || '',
        endDateString: education.endDateString || '',
        educationId: education.educationId || 0
      }));

       
              // Trigger change detection manually
      this.cdr.markForCheck();
    },
    error: (error) => {
      console.error('Error fetching education list:', error);
      // Handle error and set default values
     // Set default job in case of error (or handle error as needed)
     this.educationList = [{
      institutionName: '',
      major: '',
      minor: '',
      startDate: new Date('2024-09-17'),
      endDate: new Date('2024-09-17'),
      graduated: false,
      startDateString: '',
      endDateString:'',
      educationId: 0
    }];
            // Trigger change detection manually
            this.cdr.markForCheck();
    },
    complete: () => {
     // console.log('job retrieval completed');
    },
  });
}




}
