import { Component } from '@angular/core';
import {FooterComponent} from '../footer/footer.component';
import {NavigationBarComponent} from '../toolbar/navigationbar/navigation-bar.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [FooterComponent,NavigationBarComponent,CommonModule],
  template: ` <div class="home-container">
 
  <div class="welcome-section text-center">
    <h1>Welcome to BadgerSocials!</h1>
    <p class="mt-4">
      Discover a vibrant community where professionals connect, share, and grow.
    </p>
    <p>
      Join events, expand your network, and unlock new opportunities.
    </p>
  </div>
  <app-footer></app-footer>
</div>
`,
styles: [`
.home-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}
.welcome-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
}
p {
  font-size: 1.2rem;
  color: #555;
}
`]
})
export class HomePageComponent {

}
