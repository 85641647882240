
import { Component, OnInit ,Inject,  ViewChild, ElementRef,afterNextRender,AfterViewInit, ChangeDetectionStrategy, Renderer2, ChangeDetectorRef} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { ValidationMessagesComponent } from '../../../../shared/errors/validation-messages/validation-messages.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {provideNativeDateAdapter} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { merge, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {ProfileService} from '../../services/profileService'
import {SharedService} from '../../../../shared/shared.service'
import { fileValidator } from '../../../../Account/profile/services/fileValidator'; 



import { HttpErrorResponse } from '@angular/common/http';
import { FileUploadService } from '../../services/fileUploadService';
import { ProfileHeader } from '../../../../shared/account/profileHeader';


@Component({
  selector: 'app-profile-header-register',
  standalone: true,
  imports: [ MatButtonModule,MatFormFieldModule, MatInputModule, MatSelectModule,MatDatepickerModule,MatCheckboxModule,   CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    FormsModule,
    ValidationMessagesComponent ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
 <!-- Card container with form -->
<mat-card class="container text-center rounded-lg shadow-lg p-3">
  <form [formGroup]="profileHeaderForm" (ngSubmit)="addProfileHeaderRegister()" autocomplete="off" id="profileHeadForm">
    <h2 class="mb-3">Add a profile picture and add something about yourself</h2>

    <!-- Profile Picture Field (Full Width) -->
    <div class="row">
      <div class="col-12">
        <mat-form-field class="mt-5 full-width" appearance="fill">
          <div>
            <input matInput [value]="fileName || 'No file selected'" readonly />
            <input
              formControlName="profilePic"
              type="file"
              id="fileInput"
              (change)="selectFile($event)"
              name="fileInput"
            />
            <mat-error *ngIf="submitted && !currentFile">profile picture picture is required</mat-error>
            <mat-error *ngIf="submitted && profileHeaderForm.get('profilePicFileData')?.hasError('invalidFileType')">
              File type must be png or jpeg
            </mat-error>
            <mat-error *ngIf="submitted && profileHeaderForm.get('profilePicFileData')?.hasError('fileSizeExceeded')">
              File size must be smaller than 5 MB
            </mat-error>
          </div>
        </mat-form-field>
      </div>
    </div>

    <!-- Form Fields in Two-Column Layout on Larger Screens, Stacked on Mobile -->
    <div class="row g-3">
      <div class="col-12 col-md-6">
        <mat-form-field class="mt-1">
          <mat-label>First Name</mat-label>
          <input matInput formControlName="firstName" placeholder="Mike">
          <mat-error *ngIf="submitted && profileHeaderForm.get('firstName')?.hasError('required')">
            First name is required
          </mat-error>
          <mat-error *ngIf="submitted && profileHeaderForm.get('firstName')?.hasError('maxlength')">
            Max length is 30 characters
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-6">
        <mat-form-field class="mt-1">
          <mat-label>Last Name</mat-label>
          <input matInput formControlName="lastName" placeholder="Smith">
          <mat-error *ngIf="submitted && profileHeaderForm.get('lastName')?.hasError('required')">
            Last name is required
          </mat-error>
          <mat-error *ngIf="submitted && profileHeaderForm.get('lastName')?.hasError('maxlength')">
            Max length is 30 characters
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row g-3">
      <div class="col-12 col-md-6">
        <mat-form-field class="mt-1">
          <mat-label>Title Banner</mat-label>
          <input matInput formControlName="banner" placeholder="ex: SWE at Epic">
          <mat-error *ngIf="submitted && profileHeaderForm.get('banner')?.hasError('required')">
            Banner is required
          </mat-error>
          <mat-error *ngIf="submitted && profileHeaderForm.get('banner')?.hasError('maxlength')">
            Max length is 30 characters
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-6">
        <mat-form-field class="mt-1">
          <mat-label>LinkedIn</mat-label>
          <input matInput formControlName="LinkedIn">
          <mat-error *ngIf="submitted && profileHeaderForm.get('LinkedIn')?.hasError('maxlength')">
            Max length is 100 characters
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row g-3">
      <div class="col-12 col-md-6">
        <mat-form-field class="mt-1">
          <mat-label>Personal Website</mat-label>
          <input matInput formControlName="personalWebsite">
          <mat-error *ngIf="submitted && profileHeaderForm.get('personalWebsite')?.hasError('maxlength')">
            Max length is 100 characters
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field class="mt-1 full-width" appearance="fill">
          <mat-label>About</mat-label>
          <textarea matInput rows="5" formControlName="about"></textarea>
          <mat-error *ngIf="submitted && profileHeaderForm.get('about')?.hasError('required')">
            About section is required
          </mat-error>
          <mat-error *ngIf="submitted && profileHeaderForm.get('about')?.hasError('maxlength')">
            Max length is 1000 characters
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-4">
   
      <button mat-flat-button color="primary" type="submit" class="ml-3">Save</button>
    </div>
  </form>
</mat-card>

  `,
  styles: [`
    .profile-setup-card {
      width: 100%;
      max-width: 600px;
    }
    .full-width {
      width: 100%;
    }
  `]
})
export class ProfileRegisterHeaderComponent implements OnInit{

  registerData: any; 
  profileHeaderForm: FormGroup = new FormGroup({
   
  });
  progress = 0;
  message = '';
  submitted = false;
  errorMessages: string[] = [];
  errorMessage = '';
  currentFile: File | null = null;
  maxFileSizeMB = 5;  // Set max size limit
  allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
  fileName = 'Select File'
  returnUrl: string | null = null;

  constructor(


    //@Inject(MAT_DIALOG_DATA) public profileHeader: ProfileHeader,
     private profileService: ProfileService,  // Inject ProfileService here
  private sharedService: SharedService  ,   // Inject SharedService here
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute

  ) {  



    this.profileHeaderForm = this.formBuilder.group({
      firstName: [ '', [Validators.required, Validators.maxLength(30)]],
    lastName: [ '', [Validators.required, Validators.maxLength(30)]],
      banner: new FormControl( '', [Validators.required, Validators.maxLength(30)]),
      LinkedIn: new FormControl( '', [ Validators.maxLength(100)]),
 
    personalWebsite: new FormControl( '', [Validators.maxLength(100)]),
    about: new FormControl( '', [Validators.required,Validators.maxLength(1000)]),
     //eventpic is sanitized filename
     profilePic: new FormControl( '', Validators.required),
     //eventpicDtaafile not inluced in form control , only need to store event photo file object for use in file validatior service
     profilePicFileData: [null, [fileValidator(this.allowedFileTypes, this.maxFileSizeMB)]], // Store the File object here
    
     email: [ ''] // Hidden control
    
  });



  merge(
    this.profileHeaderForm.get('firstName')!.statusChanges!,
    this.profileHeaderForm.get('firstName')!.valueChanges!,
    this.profileHeaderForm.get('lastName')!.statusChanges!,
    this.profileHeaderForm.get('lastName')!.valueChanges!,
    this.profileHeaderForm.get('banner')!.statusChanges!,
    this.profileHeaderForm.get('banner')!.valueChanges!,
    this.profileHeaderForm.get('LinkedIn')!.statusChanges!,
    this.profileHeaderForm.get('LinkedIn')!.valueChanges!,
    this.profileHeaderForm.get('personalWebsite')!.statusChanges!,
    this.profileHeaderForm.get('personalWebsite')!.valueChanges!,
    this.profileHeaderForm.get('profilePic')!.statusChanges!,
    this.profileHeaderForm.get('profilePic')!.valueChanges!,
    this.profileHeaderForm.get('about')!.statusChanges!,
    this.profileHeaderForm.get('about')!.valueChanges!
  
  
  )
    .pipe(takeUntilDestroyed())
    .subscribe(() => this.updateErrorMessage());
  
  }







ngOnInit(): void {
  // if (this.registerData?.email) {
  //   this.profileHeaderForm.patchValue({ email: this.registerData.email });
  // }    // Access the passed `registerData` object from the navigation state
    const navigation = this.router.getCurrentNavigation();
    //console.log("frfrfrfrfrf", navigation)
    this.registerData = navigation?.extras.state?.['registerData'] || {};

    this.route.queryParams.subscribe(params => {
      this.registerData = params;
      if (this.registerData) {
        this.profileHeaderForm.patchValue(this.registerData);
       // console.log('Received registerData:', this.registerData);
      }
    });
    this.initializeForm();
  };

  selectFile(event: Event): void {
    this.progress = 0;
    this.message = "";
  
    const input = event.target as HTMLInputElement;
 
  
    if (input.files && input.files[0]) {
      const file: File = input.files[0];
      this.currentFile = file;
      //console.log("file in add profileheader:", file);
      //console.log("filetype prof head:", file.type);
     
      this.fileName = this.currentFile.name;  // Store the file name for display purposes
const sanitizedName= this.fileName.replace(/[^a-zA-Z0-9_.]/g, "");
this.fileName= sanitizedName;
// Store the file object in a separate FormControl (not the input itself)
this.profileHeaderForm.get('profilePicFileData')?.setValue(this.currentFile); // Use another field to hold the File object

    } else {
      this.fileName = 'Select File';  // Default display text if no file is selected
    }




  }

  
initializeForm() {
  this.profileHeaderForm = this.formBuilder.group({
    firstName: [this.registerData?.firstName || '', [Validators.required, Validators.maxLength(30)]],
    lastName: [this.registerData?.lastName || '', [Validators.required, Validators.maxLength(30)]],
    banner: [ '', [Validators.required, Validators.maxLength(30)]],
    LinkedIn: ['', [ Validators.maxLength(100)]],
    personalWebsite: [ '', [Validators.maxLength(100)]],
    profilePic: [ '', Validators.required],
    about: ['', [Validators.required,Validators.maxLength(1000)]],
    //eventpicDtaafile not inluced in form control 
    profilePicFileData: [null, [fileValidator(this.allowedFileTypes, this.maxFileSizeMB)]], // Store the File object here
    email: [this.registerData.email], // Store the File object here
  });
}




addProfileHeaderRegister() {
  this.submitted = true;
  this.errorMessages = [];

  if (this.profileHeaderForm.valid && this.currentFile) {

    const formData: FormData = new FormData();

    // Append form values to FormData


    // Convert date to ISO format and append
    



    formData.append('firstName', this.profileHeaderForm.get('firstName')?.value);
    formData.append('lastName', this.profileHeaderForm.get('lastName')?.value);
    formData.append('LinkedIn', this.profileHeaderForm.get('LinkedIn')?.value);
    formData.append('banner', this.profileHeaderForm.get('banner')?.value);
    formData.append('personalWebsite', this.profileHeaderForm.get('personalWebsite')?.value);
   formData.append('profilePic', this.profileHeaderForm.get('profilePic')?.value);
    formData.append('about', this.profileHeaderForm.get('about')?.value);
 

    // Append the file
    formData.append('email', this.registerData.email)
    formData.append('profilePicFile', this.currentFile);

    this.profileService.AddPersonalInfoRegister(formData).subscribe({
      next: (response: any) => {
       // console.log(":response:", response);
        if (response.success) {
          
          //this.sharedService.showNotification(response.success, response.title, response.message);
          //this.dialogprofileRef.close();
          this.router.navigateByUrl('/login');
          //console.log("prof header repsonse suscces:", response);
          
        } else {
          this.sharedService.showNotification(response.success, response.title, response.message);
          //console.log("prodf header resp fail:", response);
        }
      },
      error: (error: HttpErrorResponse) => {
        // Log the entire error object for debugging
        console.error('Error in adding profile header:', error);
        //console.log(":response&&&&&&&&:");

        // Handle the error gracefully and display to the user
        this.sharedService.showNotification(false, 'Error', error.message || 'Unknown error occurred');
      }
    });
  } else{

   // console.log("invalid form");
   // console.log(this.profileHeaderForm.value);
    this.focusFirstInvalidControl();
  }
}


focusFirstInvalidControl() {
  let form = document.getElementById('profileHeadForm'); // <-- your formID
  if(form){
    let firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
    firstInvalidControl.scrollIntoView();
    (firstInvalidControl as HTMLElement).focus();
  }
  
}

updateErrorMessage() {
  const firstNameControl = this.profileHeaderForm.get('firstName');
  const lastNameControl = this.profileHeaderForm.get('lastName');
  const bannerControl = this.profileHeaderForm.get('banner');
  const LinkedInControl = this.profileHeaderForm.get('LinkedIn');
  const websiteControl = this.profileHeaderForm.get('personalWebsite');
  const profilePicControl = this.profileHeaderForm.get('profilePic');
  const aboutControl = this.profileHeaderForm.get('about');
 

  if (firstNameControl?.hasError('required')) {
    this.errorMessage = 'You must enter your first name';
  } else if(firstNameControl?.hasError('required')){
    "You must enter your first name"
  } else if(lastNameControl?.hasError('required')){
    "You must enter your last name"
  } else if (lastNameControl?.hasError('maxlength')) {
    this.errorMessage = 'exceeds max character length';
  } else if (bannerControl?.hasError('required')) {
    this.errorMessage = 'You must enter a company';
  } else if(bannerControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  }else if(LinkedInControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  }else if(websiteControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  }else if(profilePicControl?.hasError('required')){
    this.errorMessage = 'profile pic required';
  }else if(profilePicControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  }else if(aboutControl?.hasError('required')){
    this.errorMessage = 'about is required';
  }else if(aboutControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  }else{
    this.errorMessage = '';
  }
}

}
