import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationComponent } from './modals/notification.component';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private dialog: MatDialog) { }

  showNotification(isSuccess: boolean, title: string, message: string) {
    this.dialog.open(NotificationComponent, {
      data: {
        isSuccess,
        title,
        message
      }
    });
  }
}
