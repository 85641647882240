import { Component, OnInit ,Inject,  ViewChild, ElementRef,afterNextRender,AfterViewInit,  Renderer2, ChangeDetectionStrategy,ViewEncapsulation, inject} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {MatButtonModule} from '@angular/material/button';

import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { ValidationMessagesComponent } from '../../shared/errors/validation-messages/validation-messages.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {provideNativeDateAdapter} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { merge, Observable, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {SharedService} from '../../shared/shared.service'
import { enableRipple } from '@syncfusion/ej2-base';
import { TimePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { TimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { GoogleMapComponent } from '../google-map/google-map.component';
import {EventService}  from '../../Account/profile/services/eventService'
//import {UploadEventPhotoModalComponent} from '../upload-event-photo-modal/upload-event-photo-modal.component'
import { fileValidator } from '../../Account/profile/services/fileValidator'; 

import { EventEmitter, Output } from '@angular/core';

import {
  MatDialogModule,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

import { Event as EventDTO } from '../../shared/event/event';
import { NgIf, DatePipe } from '@angular/common';
import { FileUploadService } from '../../Account/profile/services/fileUploadService';

//enable ripple style
enableRipple(true);


@Component({
  selector: 'app-add-event-modal',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [ GoogleMapComponent, NgIf, DatePipe,NgIf, DatePipe,TimePickerModule, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule,MatFormFieldModule, MatInputModule, MatSelectModule,MatDatepickerModule,MatCheckboxModule,   CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    FormsModule,
    ValidationMessagesComponent ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
<div class="container text-center overflow-auto">
  <!-- <form [formGroup]="AddEventForm" (ngSubmit)="addEvent()" autocomplete="off">  -->
  <form [formGroup]="AddEventForm" (ngSubmit)="addEvent()" autocomplete="off" id="eventForm">
    <div class="row justify-content-center">
      <h2 mat-dialog-title>Create Event</h2>
    </div>

    <div class="row justify-content-center">
      <mat-dialog-content class="mat-typography col -md-8">
        <div class="form-group">
          <mat-form-field class="w-100 my-3">
            <mat-label>Event Name</mat-label>
            <input matInput formControlName="name" placeholder="ex: welders networking dinner">
            <mat-error *ngIf="submitted && AddEventForm.get('name')?.hasError('required')">
              Event name is required
            </mat-error>
            <mat-error *ngIf="submitted && AddEventForm.get('name')?.hasError('maxlength')">
              Max length is 30 characters
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field class="w-100 my-3">
            <mat-label>Event Description</mat-label>
            <input matInput formControlName="description" placeholder="ex: dinner party to network">
            <mat-error *ngIf="submitted && AddEventForm.get('description')?.hasError('required')">
              Event description is required
            </mat-error>
            <mat-error *ngIf="submitted && AddEventForm.get('description')?.hasError('maxlength')">
              Max length is 30 characters
            </mat-error>
          </mat-form-field>
        </div>

        <div class="row">
          <div class="col-md-6 form-group">
            <mat-form-field class="w-100 my-3" >
              <mat-label>Start Date</mat-label>
              <input matInput formControlName="startDate" [matDatepicker]="startDate">
              <mat-error *ngIf="submitted && AddEventForm.get('startDate')?.hasError('required')">
                Start date is required
              </mat-error>
              <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
              <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-md-6 form-group">
            <mat-form-field class="w-100 my-3">
              <mat-label>End Date</mat-label>
              <input matInput formControlName="endDate" [matDatepicker]="endDate">
              <mat-error *ngIf="submitted && AddEventForm.get('endDate')?.hasError('required')">
                End date is required
              </mat-error>
              <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
              <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 form-group my-3">
            <ejs-timepicker formControlName="startTime" name="startTime" placeholder='Select a start time' floatLabelType='Always'></ejs-timepicker>
            <mat-error *ngIf="submitted && AddEventForm.get('startTime')?.hasError('required')">
              Start time is required
            </mat-error>
          </div>

          <div class="col-md-6 form-group my-3">
            <ejs-timepicker formControlName="endTime" name="endTime" placeholder='Select an end time' floatLabelType='Always'></ejs-timepicker>
            <mat-error *ngIf="submitted && AddEventForm.get('endTime')?.hasError('required')">
              End time is required
            </mat-error>
          </div>
        </div>




        <mat-form-field>
  <div>
    <!-- Display the file name or a placeholder -->
    <input matInput [value]="fileName || 'No file selected'" readonly />

    <!-- File input for selecting the event picture -->
    <input
    formControlName="eventPic"
      type="file"
      id="fileInput"
      (change)="selectFile($event)"
      name="fileInput"
    />

    <!-- Error messages -->
    <mat-error *ngIf="submitted && !currentFile">
      Event picture is required
    </mat-error>
        <!-- Error messages -->
        <mat-error *ngIf="submitted  && AddEventForm.get('eventPicFileData')?.hasError('invalidFileType')">
      file type must be png or jpeg
    </mat-error>
    <mat-error *ngIf="submitted  && AddEventForm.get('eventPicFileData')?.hasError('fileSizeExceeded')">
    File size must be smaller than 5 MB
    </mat-error>
  </div>
</mat-form-field>

<div>

<mat-error *ngIf="submitted && AddEventForm.get('eventAddress')?.hasError('required')">
              event adress is required
            </mat-error>
            <mat-error *ngIf="submitted && AddEventForm.get('eventAddress')?.hasError('maxlength')">
              Max length is 30 characters
            </mat-error>
        <!-- Google map component included -->
        <app-google-map (dataToParent)="receiveData($event)" class="my-5" formControlName="eventAddress"></app-google-map>
       
  </div>
      </mat-dialog-content>
    </div>

    <mat-dialog-actions align="end" class="justify-content-end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button cdkFocusInitial type="submit">Save</button>
    </mat-dialog-actions>
  </form>
</div>



  `, 
  styles: `


   `
})
export class AddEventModalComponent implements OnInit{
  
  @Output() eventCreated = new EventEmitter<EventDTO>(); // Emit the created event
  currentFile: File | null = null;
  progress = 0;
  message = '';
  maxFileSizeMB = 5;  // Set max size limit
  allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
  fileName = 'Add Event Picture';
  fileInfos?: Observable<any>;

  readonly dialog = inject(MatDialog);
  AddEventForm: FormGroup = new FormGroup({
    
  });
  submitted = false;
  errorMessages: string[] = [];
  errorMessage = '';
  receivedData: object = {};
  
  returnUrl: string | null = null;

  constructor(

    private el: ElementRef,
    private dialogRef: MatDialogRef<AddEventModalComponent>,
    private renderer: Renderer2,
    private eventService: EventService,
    private sharedService: SharedService  ,   // Inject SharedService here
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private uploadService: FileUploadService,
  ) {  
    
    
    this.AddEventForm = this.formBuilder.group({
      lat: [0],
      lng: [0],
    name: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    description: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    eventAddress: new FormControl('', [Validators.required, Validators.maxLength(300)]),
    startTime: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    endTime: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    startDate: new FormControl('', [Validators.required, Validators.maxLength(15)]),
    endDate: new FormControl('', [Validators.required, Validators.maxLength(15)]),
    //eventpic is sanitized filename
    eventPic: new FormControl('', Validators.required),
    //eventpicDtaafile not inluced in form control , only need to store event photo file object for use in file validatior service
    eventPicFileData: [null, [fileValidator(this.allowedFileTypes, this.maxFileSizeMB)]], // Store the File object here
    
  });



  merge(
    this.AddEventForm.get('name')!.statusChanges!,
    this.AddEventForm.get('name')!.valueChanges!,
    this.AddEventForm.get('description')!.statusChanges!,
    this.AddEventForm.get('description')!.valueChanges!,
    this.AddEventForm.get('startTime')!.statusChanges!,
    this.AddEventForm.get('startTime')!.valueChanges!,
    this.AddEventForm.get('endTime')!.statusChanges!,
    this.AddEventForm.get('endTime')!.valueChanges!,
    this.AddEventForm.get('startDate')!.statusChanges!,
    this.AddEventForm.get('startDate')!.valueChanges!,
    this.AddEventForm.get('endDate')!.statusChanges!,
    this.AddEventForm.get('endDate')!.valueChanges!,
    this.AddEventForm.get('eventAddress')!.statusChanges!,
    this.AddEventForm.get('eventAddress')!.valueChanges!,
    this.AddEventForm.get('eventPic')!.statusChanges!,
    this.AddEventForm.get('eventPic')!.valueChanges!,
    
  
  )
    .pipe(takeUntilDestroyed())
    .subscribe(() => this.updateErrorMessage());
  
  }


// Method to receive data from the child component
receiveData(data: { address: string; latitude: number; longitude: number }): void {
  this.receivedData = data;

  // Update the eventAddress form control with the emitted addressfile.type
  this.AddEventForm.get('eventAddress')?.setValue(data.address);
  // Update the lat form control with the emitted lat
  // Update the lat form control with the emitted latitude
  this.AddEventForm.get('lat')?.setValue(data.latitude);
  
  // Update the lng form control with the emitted longitude
  this.AddEventForm.get('lng')?.setValue(data.longitude);
  //console.log("Received data:", data);
}

ngOnInit(): void {
  this.initializeForm();


  
    
  };
  
  selectFile(event: Event): void {
    this.progress = 0;
    this.message = "";
  
    const input = event.target as HTMLInputElement;
 
  
    if (input.files && input.files[0]) {
      const file: File = input.files[0];
      this.currentFile = file;
      //console.log("file in add event:", file);
      //console.log("filetype frfr:", file.type);
     
      this.fileName = this.currentFile.name;  // Store the file name for display purposes
const sanitizedName= this.fileName.replace(/[^a-zA-Z0-9_.]/g, "");
this.fileName= sanitizedName;
// Store the file object in a separate FormControl (not the input itself)
this.AddEventForm.get('eventPicFileData')?.setValue(this.currentFile); // Use another field to hold the File object

    } else {
      this.fileName = 'Add Event picture';  // Default display text if no file is selected
    }




  }

initializeForm() {
  this.AddEventForm = this.formBuilder.group({
    lat: [0],
      lng: [0],
    name: ['', [Validators.required, Validators.maxLength(30)]],
    description: ['', [Validators.required, Validators.maxLength(500)]],
    eventAddress: ['', [Validators.required, Validators.maxLength(300)]],
    startTime:['', [Validators.required, Validators.maxLength(30)]],
    endTime:['', [Validators.required, Validators.maxLength(30)]],
    startDate: ['', [Validators.required, Validators.maxLength(15)]],
    endDate: ['', [Validators.required, Validators.maxLength(15)]],
    eventPic: ['', Validators.required],
    //eventpicDtaafile not inluced in form control 
    eventPicFileData: [null, [fileValidator(this.allowedFileTypes, this.maxFileSizeMB)]], // Store the File object here
    
  });
}

addEvent() {
  this.submitted = true;
  this.errorMessages = [];
//console.log('event form here:',this.AddEventForm.value);
  if (this.AddEventForm.valid && this.currentFile) {
    //console.log('Event form is valid, preparing to send data.');

    const formData: FormData = new FormData();

    // Append form values to FormData


    // Convert date to ISO format and append
    // const startDate = new Date(this.AddEventForm.get('startDate')?.value).toISOString();
    // const endDate = new Date(this.AddEventForm.get('endDate')?.value).toISOString();
    // formData.append('startDate', startDate);
    // formData.append('endDate', endDate);


    formData.append('name', this.AddEventForm.get('name')?.value);
    formData.append('description', this.AddEventForm.get('description')?.value);
    formData.append('startDate', this.AddEventForm.get('startDate')?.value.toISOString());
    formData.append('endDate', this.AddEventForm.get('endDate')?.value.toISOString());
    formData.append('startTime', this.AddEventForm.get('startTime')?.value.toISOString());
    formData.append('endTime', this.AddEventForm.get('endTime')?.value.toISOString());
    formData.append('eventAddress', this.AddEventForm.get('eventAddress')?.value);
    formData.append('lat', this.AddEventForm.get('lat')?.value);
    formData.append('lng', this.AddEventForm.get('lng')?.value);

    // Append the file
    formData.append('eventPicFile', this.currentFile);


    this.eventService.addEvent(formData).subscribe({
      next: (response: any) => {
        //console.log("event reposnse:", response);
        if (response.success) {
          const newEvent: EventDTO = {
            name:  this.AddEventForm.get('name')?.value, // Replace with actual data
            description:  this.AddEventForm.get('description')?.value,
            startDate: this.AddEventForm.get('startDate')?.value.toISOString(),
            endDate: this.AddEventForm.get('endDate')?.value.toISOString(),
            startTime: this.AddEventForm.get('startTime')?.value.toISOString(),
            endTime: this.AddEventForm.get('endTime')?.value.toISOString(),
            eventAddress: this.AddEventForm.get('eventAddress')?.value,
            lat: this.AddEventForm.get('lat')?.value,
            lng:this.AddEventForm.get('lng')?.value,
            eventPicFile: this.currentFile


            // Add all other required properties
          };
              // Emit the new event to the parent component
          this.eventCreated.emit(newEvent);
          this.sharedService.showNotification(response.success, response.title, response.message);

          this.dialogRef.close();
         // console.log(":event reposes", response);
      
        

     

          
          
        } else {
          this.sharedService.showNotification(response.success, response.title, response.message);
         // console.log(":response&&&&&&&&:", response);
          
        }
      },
      error: (error: HttpErrorResponse) => {
        // Log the entire error object for debugging
        console.error('Error in adding event:', error);
       // console.log(":response&:");

        // Handle the error gracefully and display to the user
        this.sharedService.showNotification(false, 'Error', error.message || 'Unknown error occurred');
      }
    });
  }
  else{
   //console.log("invalid form");
    //console.log(this.AddEventForm.value);
    this.focusFirstInvalidControl();
  }
}


  focusFirstInvalidControl() {
    let form = document.getElementById('eventForm'); // <-- your formID
    if(form){
      let firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
      firstInvalidControl.scrollIntoView();
      (firstInvalidControl as HTMLElement).focus();
    }
    
  }
// uploadEventPic(): void {
//   //if (this.currentFile) {
//     // Create an object of type EventPicture
//   //const eventPicture: EventPicture = {
//    // eventPic: this.currentFile // Assign the file to the eventpic property
  
//   };
//     this.uploadService.uploadEventPic(eventPicture).subscribe({
//       next: (event: any) => {
//         if (event.type === HttpEventType.UploadProgress) {
//           this.progress = Math.round(100 * event.loaded / event.total);
//         } else if (event instanceof HttpResponse) {
//           this.message = event.body.message;
//           console.log(this.message);
//           //this.fileInfos = this.uploadService.getFiles();
//         }
//       },
//       error: (err: any) => {
//         console.log(err);
//         this.progress = 0;

//         if (err.error && err.error.message) {
//           this.message = err.error.message;
//         } else {
//           this.message = 'Could not upload the file!';
//         }
//       },
//       complete: () => {
//         this.currentFile = undefined;
//       }
//     });
//   }

// }




updateErrorMessage() {
  const nameControl = this.AddEventForm.get('name');
  const startTimeControl = this.AddEventForm.get('startTime');
 const eventAddressControl= this.AddEventForm.get('eventAddress');
  const endTimeControl = this.AddEventForm.get('endTime');
  const descriptionControl = this.AddEventForm.get('description');
  const startDateControl = this.AddEventForm.get('startDate');
  const endDateControl = this.AddEventForm.get('endDate');
  const eventPicControl = this.AddEventForm.get('eventPic');


  if (nameControl?.hasError('required')) {
    this.errorMessage = 'You must enter an event name';
  } else if (nameControl?.hasError('maxlength')) {
    this.errorMessage = 'exceeds max character length';
  } else if (descriptionControl?.hasError('required')) {
    this.errorMessage = 'You must enter a description of the event';
  } else if(descriptionControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  }  else if (startTimeControl?.hasError('required')) {
    this.errorMessage = 'You must enter a start time';
  } else if(startTimeControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  } else if (endTimeControl?.hasError('required')) {
    this.errorMessage = 'You must enter an end time';
  } else if(endTimeControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  } else if(startDateControl?.hasError('required')){
    this.errorMessage = 'you must enter a start date';
  }else if(startDateControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  }else if(endDateControl?.hasError('required')){
    this.errorMessage = 'you must enter an end date';
  }else if(endDateControl?.hasError('maxLength')){
    this.errorMessage = 'exceeds max character length';
  }else if(eventAddressControl?.hasError('maxLength')){
    this.errorMessage = 'exceeds max character length';
  }else if(eventAddressControl?.hasError('required')){
    this.errorMessage = 'you must enter an address for the event';
  }  else if(eventPicControl?.hasError('maxLength')){
    this.errorMessage = 'exceeds max character length';
  }else if(eventPicControl?.hasError('required')){
    this.errorMessage = 'you must upload an event picture';
  } else if(eventPicControl?.hasError('invalidFileType')){
    this.errorMessage = 'File must be a PNG or JPEG image';
  }else if(eventPicControl?.hasError('fileSizeExceeded')){
    this.errorMessage = 'File size must be smaller than 5 MB.';
  }else{
    this.errorMessage = '';
  }
}



}
