
import { HttpRequest, HttpEvent } from '@angular/common/http';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable,  inject, } from '@angular/core';

import { environment } from '../../../../Environments/environment';

import { ReplaySubject, map, of, Observable, catchError } from 'rxjs';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { Job } from '../../../shared/account/job';
import { ServiceResult } from '../../../shared/account/ServiceResult';
import { Education } from '../../../shared/account/education';
import { About } from '../../../shared/account/about';
import { ProfileHeader } from '../../../shared/account/profileHeader';


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {



  

  constructor(private http: HttpClient, private router: Router,private cookieService: CookieService) {
     
    
 }
 

  // upload(model: ProfilePicture): Observable<HttpEvent<any>> {
  //   const formData: FormData = new FormData();

  //   formData.append('profilePic', model.profilePic);

  //   const req = new HttpRequest('POST', `/api/Profile/AddProfilePic`, formData, { withCredentials: true });

  //   return this.http.request(req);
  // }


  // uploadEventPic(model: EventPicture): Observable<HttpEvent<any>> {
  //   const formData: FormData = new FormData();

  //   formData.append('eventPic', model.eventPic);

  //   const req = new HttpRequest('POST', `/api/Profile/AddEventPic`, formData, { withCredentials: true });

  //   return this.http.request(req);
  // }




//   getProfilePicture(model: ProfilePicture): Observable<HttpEvent<any>> {
//     //const formData: FormData = new FormData();

//     //formData.append('profilePic', model.profilePic);

//     const req = new HttpRequest('GET', `/api/Profile/GetProfilePic`,  { withCredentials: true });

//     return this.http.request(req);
//   }


  getProfilePicture() {

  
    return this.http.get<ServiceResult>(`${environment.appUrl}/Profile/GetProfilePic`, { withCredentials: true })
      .pipe(
        map((response: ServiceResult) => {

            //console.log("aaaaaaaaahhhhhhhhhhhhhhhh");
          if (response.success) {
           //console.log("profile pic piccccccc  successfully:", JSON.stringify(response, null, 2));
            this.router.navigateByUrl('/profile'); // Navigate to the home page
          } else {
            //console.log('Failed to add piccccccc:', response);
          }
          return response;
        }),
        catchError((error) => {
          console.error("Error in adding about:", error);
          const errorResponse: ServiceResult = {
            title : error.error.title,
            success: false,
            message: error.error.message || 'Unknown error'
          };
          return of(errorResponse);
        })
      );
  }

}