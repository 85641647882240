import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, inject, } from '@angular/core';
import { Register } from '../shared/account/register';

import { Login } from '../shared/account/login';
import { User } from '../shared/account/user';
import { AuthResponse } from '../shared/account/AuthResponse';
import { ReplaySubject, map, of, Observable, catchError } from 'rxjs';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { ServiceResult } from '../shared/account/ServiceResult';

import { environment } from '../../Environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private userSource = new ReplaySubject<AuthResponse | null>(1);

  user$ = this.userSource.asObservable();

  constructor(private http: HttpClient, private router: Router,private cookieService: CookieService) {

    console.log("account service check auth");
      this.checkAuthStatus();
 }

  // refreshUser(jwt: string | null) {
  //   if (jwt === null) {
  //     this.userSource.next(null);
  //     return of(undefined);
  //   }

  //   let headers = new HttpHeaders();
  //   headers = headers.set('Authorization', 'Bearer ' + jwt);

  //   return this.http.get<User>(`/api/Account/refresh-user-token`, {headers}).pipe(
  //     map((user: User) => {
  //       if (user) {
  //         this.setUser(user);
  //       }
  //     })
  //   )
  // }

  // refreshUser(): Observable<AuthResponse | undefined> {
  //   // if (token === null) {
  //   //   this.userSource.next(null);
  //   //   return of(undefined);
  //   // }

  //   let headers = new HttpHeaders();
  //   headers = headers.set('Authorization', 'Bearer ');

  //   return this.http.get<AuthResponse>(`/api/Account/refresh-user-token`, { headers, withCredentials: true })
  //     .pipe(
  //       map((authResponse: AuthResponse) => {
  //         if (authResponse.isAuthenticated && authResponse.token) {
  //           this.userSource.next(authResponse);
  //         }
  //         return authResponse;
  //       }),
  //       catchError((error) => {
  //         console.error("Error in refreshUser:", error);
  //         return of(undefined);
  //       })
  //     );
  // }

  checkAuthStatus() {
   
      this.http.post<AuthResponse>(`${environment.appUrl}/Account/refresh-user-token`,{}, { withCredentials: true })
        .pipe(
          map((authResponse: AuthResponse) => {
            if (authResponse.isAuthenticated && authResponse.token) {
              // console.log("User authenticated:", authResponse);
              this.userSource.next(authResponse);
            } else {
              // console.log("User  not authenticated:", authResponse);
              this.userSource.next(null);
            }
          }),
          catchError((error) => {
            
            console.error("Error checking auth status:", error);
            this.userSource.next(null);
            return of(undefined as unknown as AuthResponse);
          })
        ).subscribe();
    
  }


  login(model: Login) {
    return this.http.post<AuthResponse>(`${environment.appUrl}/Account/login`, model, 
      { withCredentials: true})
    .pipe(
      map((authResponse: AuthResponse) => {
        // console.log("$%$%$%$%$%$%$%");
        if (authResponse.isAuthenticated && authResponse.token) {
          // console.log("authreposen::::::",JSON.stringify(authResponse, null, 2));

          // console.log(authResponse, "inaccointservice")
          this.userSource.next(authResponse);
        } else {
          console.error('Invalid AuthResponse received:', authResponse);
        }
        return authResponse;
      }),
      catchError((error) => {
        console.error("Error in login dude:", error);
        const errorResponse: AuthResponse = {
          isAuthenticated: false,
          token: '',
          refreshToken: '',
          firstName: '',
          lastName: '',
          message: '',
          title: '',
          success: false,
          error: error.error.error || 'Unknown error'
        };
        return of(errorResponse);
      })
    );
  }


  handleGoogleSignIn( credential: string ) {
   
console.log("cred in account google",credential);
    // Make the HTTP POST request
    const options = {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true
  };

    return this.http
        .post<AuthResponse>(`${environment.appUrl}/Account/login-google`, { GoogleCredential: credential }, options)
        .pipe(
          map((authResponse: AuthResponse) => {
            // console.log("$%$%$%$%$%$%$%");
            if (authResponse.isAuthenticated && authResponse.token) {
              // console.log("authreposen::::::",JSON.stringify(authResponse, null, 2));
    
               console.log(authResponse, "inaccointservice")
              this.userSource.next(authResponse);
            } else {
              console.error('Invalid AuthResponse nin google login received:', authResponse);
            }
            console.log(authResponse, "google authresposen")
            return authResponse;
          }),
          catchError((error) => {
        
            console.error("Error in google login dude:", error);
            const errorResponse: AuthResponse = {
              isAuthenticated: false,
              token: '',
              refreshToken: '',
              firstName: '',
              lastName: '',
              message: '',
              title: '',
              success: false,
              error: error.error.error || 'Unknown error'
            };
            console.log("error reposnse in google", errorResponse);
            return of(errorResponse);
          })
        );
}

  
  register(model: Register) {
    return this.http.post<ServiceResult>(`${environment.appUrl}/Account/register`, model, { withCredentials: true })
    .pipe(
      map((serviceResult: ServiceResult) => {
        if (serviceResult.success) {
          console.log("register success");

          // console.log(serviceResult, "inaccointservice")
          //this.userSource.next(serviceResult);
        } else {
          //console.log('Invalid AuthResponse received:', serviceResult);
          console.log("register fail");
        }
        return serviceResult;
      }),
      catchError((error) => {
        console.error("Error in register dude:", error);
        const errorResponse: ServiceResult = {
        title: 'register error',
        success: false,
        message: 'error in register'
        };
        return of(errorResponse);
      })
    );
  }


  logout() {
    
      this.http.post(`${environment.appUrl}/Account/logout`, {}, { withCredentials: true }).subscribe(() => {
        // Delete the JWT cookie
        console.log("logout reached", environment.appUrl)
        this.cookieService.delete(environment.userKey, '/', '', true, 'Strict');
        this.userSource.next(null); // Clear the current user observable
        this.router.navigateByUrl('/home'); // Navigate to the home page
      });
    
  }


  // register(model: Register) {
  //   return this.http.post(`/api/Account/register`, model);
  // }


//   private setUser(authResponse: AuthResponse) {

//     if (this.isBrowser){
//       console.log("----------------------user:"+ authResponse.token,authResponse.firstName, authResponse.lastName, "env.UserKey:"+ environment.userKey)
//     this.cookieService.set(environment.userKey, authResponse.token, { secure: false, sameSite: 'Strict' });
//     console.log("------------------user.jwt:"+ authResponse.token, "env.UserKey:"+ environment.userKey)
//     this.userSource.next(authResponse);
//   }

// }

// getJWT() {

//   console.log("888888888888888888888")
//   if (this.isBrowser){
//   const key = this.cookieService.get(environment.userKey);
//   console.log("key:" +key, "userkey:"+ environment.userKey)
//   if (key) {
//     const authResponse: AuthResponse = JSON.parse(key);
//     console.log("key is true")
//     return authResponse.token;
//   } else {
//     console.log("key is false")
//     return null;
//   }
//  }

//  console.log("i555555555555555555555555555")
//  return null;
// }





}
