
import { Component, Inject, ChangeDetectionStrategy, inject, OnInit} from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';


import {SharedService} from '../../shared/shared.service'
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import { EventChatComponent } from '../event-chat/event-chat.component';  
import {MatCheckboxModule} from '@angular/material/checkbox';

import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
 
} from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

import { ServicesResult } from '../../shared/account/ServicesResult';

import{Event as EventDTO} from '../../shared/event/event'

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
@Component({
  selector: 'app-event',
  standalone: true,
  imports: [EventChatComponent,MatIconModule,MatCheckboxModule, MatDialogTitle, MatFormFieldModule,MatInputModule,MatSelectModule, MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule,CommonModule,
   
    MatButtonModule,
    MatCardModule,
    
    ],
  template: `

      <!-- Event card -->
      <mat-card class="custom-card mx-3 my-5 rounded-lg shadow-lg text-start" >
       
          <mat-card-title class="my-3 px-3">{{ event.name }}</mat-card-title>
       
        <img mat-card-image [src]="event.eventPic" alt="event image" style="height:70%;">
        <mat-card-content class="text-start">
          <p class="d-flex align-items-center mt-3"><mat-icon class="me-2" aria-label="info">info</mat-icon>{{ event.description }}</p>
          <p class="d-flex align-items-center"><mat-icon class="me-2" aria-label="schedule">schedule</mat-icon> {{ event.startTimeString }} - {{ event.endTimeString }}</p>
          <p class="d-flex align-items-center"><mat-icon class="me-2" aria-label="event">event</mat-icon> {{ event.startDateString }} - {{ event.endDateString }}</p>
          <p class="d-flex align-items-center"><mat-icon class="me-2" aria-label="place">place</mat-icon> {{ event.eventAddress }}</p>
        </mat-card-content>
        <app-event-chat [event]="event"></app-event-chat>
      </mat-card>
      


  `,
  styles: `.custom-card {
    width: 70vw; /* 70% of the viewport width */
    max-height: 80vh; /* Set max height to allow scrolling */
    overflow-y: auto; /* Enables vertical scrolling for overflow content */
    padding: 1rem; /* Optional: Adjust padding for spacing */
  }
  
  
 `
})
export class EventComponent implements OnInit {



  constructor(private router:Router,@Inject(MAT_DIALOG_DATA) public event:EventDTO,
  private dialogRef: MatDialogRef<EventComponent>,) {

  }

  ngOnInit(): void {
    //console.log("event passed to event modal:",this.event);
  }


}
