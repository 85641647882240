
import { Component, OnInit ,Inject,  ViewChild, ElementRef,afterNextRender,AfterViewInit, ChangeDetectionStrategy} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { ValidationMessagesComponent } from '../../../../shared/errors/validation-messages/validation-messages.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {provideNativeDateAdapter} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { merge, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {ProfileService} from '../../services/profileService'
import {SharedService} from '../../../../shared/shared.service'
import { HttpErrorResponse } from '@angular/common/http';

import {
  MatDialogModule,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Job } from '../../../../shared/account/job';

@Component({
  selector: 'app-edit-work-modal',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule,MatFormFieldModule, MatInputModule, MatSelectModule,MatDatepickerModule,MatCheckboxModule,   CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    FormsModule,
    ValidationMessagesComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
  <div class="container text-center rounded-lg shadow-lg">
  <form [formGroup]="editExperienceForm" (ngSubmit)="saveWorkExperience()" autocomplete="off" id="editExperience">

  
<h2 mat-dialog-title>Experience</h2>
<mat-dialog-content class="mat-typography">
<div class="col-12">
<mat-form-field class="mt-5" appearance="fill" class="full-width">
  <mat-label for="positionTitle">Title</mat-label>
  <input matInput formControlName="positionTitle" id="positionTitle">
  <mat-error *ngIf="submitted && editExperienceForm.get('positionTitle')?.hasError('required')">
            position is required
          </mat-error>
          <mat-error *ngIf="submitted && editExperienceForm.get('positionTitle')?.hasError('maxlength')">
            Max length is 30 characters
          </mat-error>
</mat-form-field>
</div>
<div class="col-12">
<mat-form-field class="mt-4" appearance="fill" class="full-width">
  <mat-label for="companyName">Company</mat-label>
  <input matInput formControlName="companyName" id="companyName" >
  <mat-error *ngIf="submitted && editExperienceForm.get('companyName')?.hasError('required')">
            company is required
          </mat-error>
          <mat-error *ngIf="submitted && editExperienceForm.get('companyName')?.hasError('maxlength')">
            Max length is 30 characters
          </mat-error>
</mat-form-field>
</div>
<div class="col-12">
<mat-form-field class="mt-4" appearance="fill" class="full-width">
  <mat-label for="positionDescription">Description</mat-label>
  <textarea matInput formControlName="positionDescription" id="positionDescription" ></textarea>
  <mat-error *ngIf="submitted && editExperienceForm.get('positionDescription')?.hasError('required')">
        description is required
          </mat-error>
          <mat-error *ngIf="submitted && editExperienceForm.get('positionDescription')?.hasError('maxlength')">
            Max length is 500 characters
          </mat-error>
</mat-form-field>
</div>




<div class="col-12">
<mat-checkbox formControlName="stillWorkHere" id="stillWorkHere" >I still work here</mat-checkbox>
  </div>

<div class="row g-3">

<div class="col-12 col-md-6">
<mat-form-field class="mt-1" >
  <mat-label for="startDate">Start Date</mat-label>
  <input matInput formControlName="startDate" [matDatepicker]="startDate" id="startDate">
  <mat-error *ngIf="submitted && editExperienceForm.get('startDate')?.hasError('required')">
        start date is required
          </mat-error>
          <mat-error *ngIf="submitted && editExperienceForm.get('startDate')?.hasError('maxlength')">
            Max length is 30 characters
          </mat-error>
  <mat-hint>MM/DD/YYYY</mat-hint>
  <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
  <mat-datepicker #startDate></mat-datepicker>
</mat-form-field>
</div>

<div class="col-12 col-md-6">
<mat-form-field class="mt-1">
  <mat-label for ="endDate">End Date</mat-label>
  <input matInput formControlName="endDate" [matDatepicker]="endDate" id="endDate" >
  <mat-error *ngIf="submitted && editExperienceForm.get('endDate')?.hasError('required')">
        end date is required
          </mat-error>
          <mat-error *ngIf="submitted && editExperienceForm.get('endDate')?.hasError('maxlength')">
            Max length is 30 characters
          </mat-error>
  <mat-hint>MM/DD/YYYY</mat-hint>
  <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
  <mat-datepicker #endDate></mat-datepicker>
</mat-form-field>
</div>

</div>
  </mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button  cdkFocusInitial  type="submit">Save</button>
</mat-dialog-actions>
 
  </form>
  </div>


  `,
  styles: `.full-width {
    width: 100%; /* Ensures the field spans the full container width */
  }`
})
export class EditWorkModalComponent implements OnInit {
  
  editExperienceForm: FormGroup = new FormGroup({
    // stillWorkHere: new FormControl(false) // Default value: unchecked
  });


  submitted = false;
  errorMessages: string[] = [];
  errorMessage = '';
  
  returnUrl: string | null = null;

  constructor(

    @Inject(MAT_DIALOG_DATA) public job:Job,
    private dialogRef: MatDialogRef<EditWorkModalComponent>,
     private profileService: ProfileService,  // Inject ProfileService here
  private sharedService: SharedService  ,   // Inject SharedService here
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {  
    
    
    this.editExperienceForm = this.formBuilder.group({
      jobId: [job.jobId],  // Hidden, not displayed in the form
    positionTitle: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    companyName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    positionDescription: new FormControl('', [Validators.required, Validators.maxLength(3000)]),
    stillWorkHere: new FormControl(false),
    startDate: new FormControl('', [Validators.required, Validators.maxLength(15)]),
    endDate: new FormControl('', [Validators.required, Validators.maxLength(15)])
    
  });



  merge(
    this.editExperienceForm.get('positionTitle')!.statusChanges!,
    this.editExperienceForm.get('positionTitle')!.valueChanges!,
    this.editExperienceForm.get('companyName')!.statusChanges!,
    this.editExperienceForm.get('companyName')!.valueChanges!,
    this.editExperienceForm.get('positionDescription')!.statusChanges!,
    this.editExperienceForm.get('positionDescription')!.valueChanges!,
    this.editExperienceForm.get('stillWorkHere')!.statusChanges!,
    this.editExperienceForm.get('stillWorkHere')!.valueChanges!,
    this.editExperienceForm.get('startDate')!.statusChanges!,
    this.editExperienceForm.get('startDate')!.valueChanges!,
    this.editExperienceForm.get('endDate')!.statusChanges!,
    this.editExperienceForm.get('endDate')!.valueChanges!
  
  )
    .pipe(takeUntilDestroyed())
    .subscribe(() => this.updateErrorMessage());
  
  }







ngOnInit(): void {
  this.initializeForm();
      // Listen for changes in the isChecked checkbox
      this.editExperienceForm.get('stillWorkHere')?.valueChanges.subscribe((stillWorkHere: boolean) => {
        this.toggleEndDateValidator(stillWorkHere);
      });
  
    
  };

  
  
initializeForm() {
  this.editExperienceForm = this.formBuilder.group({
    jobId: [this.job.jobId],  // Hidden so is inlcuded in model for use in backend, not displayed in the form
    positionTitle: [this.job.positionTitle, [Validators.required, Validators.maxLength(30)]],
    companyName: [this.job.companyName, [Validators.required, Validators.maxLength(30)]],
    positionDescription: [this.job.positionDescription, [Validators.required, Validators.maxLength(3000)]],
    stillWorkHere: [this.job.stillWorkHere ],
    startDate: [this.job.startDate, [Validators.required, Validators.maxLength(30)]],
    endDate: [this.job.endDate, [Validators.required, Validators.maxLength(30)]],
  });
}




toggleEndDateValidator(stillWorkHere: boolean) {
  const endDateControl = this.editExperienceForm.get('endDate');

  if (stillWorkHere) {
    // If "I still work here" is checked, remove the required validator for End Date
    endDateControl?.clearValidators();
  } else {
    // If unchecked, add the required validator back to End Date
    endDateControl?.setValidators([Validators.required, Validators.maxLength(15)]);
  }
  // Update the validity of the endDate field after changing the validators
  endDateControl?.updateValueAndValidity();
}

saveWorkExperience() {
  this.submitted = true;
  this.errorMessages = [];

  if (this.editExperienceForm.valid) {
    this.profileService.editWorkExperience(this.editExperienceForm.value).subscribe({
      next: (response: any) => {
        //console.log(":response!!!!!!!!!!!!:", response);
        if (response.success) {
          this.sharedService.showNotification(response.success, response.title, response.message);
          this.dialogRef.close();
         // console.log(":response@@@@@@@", response);
         
        } else {
          this.sharedService.showNotification(response.success, response.title, response.message);
         // console.log(":response&&&&&&&&:", response);
        }
      },
      error: (error: HttpErrorResponse) => {
        // Log the entire error object for debugging
        console.error('Error in adding work experience:', error);
       // console.log(":response&&&&&&&&:");

        // Handle the error gracefully and display to the user
        this.sharedService.showNotification(false, 'Error', error.message || 'Unknown error occurred');
      }
    });
  }  else{
   // console.log("invalid form");
   // console.log(this.editExperienceForm.value);
    this.focusFirstInvalidControl();
  }

}


focusFirstInvalidControl() {
  let form = document.getElementById('editExperience'); // <-- your formID
  if(form){
    let firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
    firstInvalidControl.scrollIntoView();
    (firstInvalidControl as HTMLElement).focus();
  }
  
}

updateErrorMessage() {
  const positionControl = this.editExperienceForm.get('positionTitle');
  const companyControl = this.editExperienceForm.get('companyName');
  const descriptionControl = this.editExperienceForm.get('positionDescription');
  const startDateControl = this.editExperienceForm.get('startDate');
  const endDateControl = this.editExperienceForm.get('endDate');

  if (positionControl?.hasError('required')) {
    this.errorMessage = 'You must enter a position';
  } else if (positionControl?.hasError('maxlength')) {
    this.errorMessage = 'exceeds max character length';
  } else if (companyControl?.hasError('required')) {
    this.errorMessage = 'You must enter a company';
  } else if(companyControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  }else if(descriptionControl?.hasError('required')){
    this.errorMessage = 'you must enter a description';
  }else if(descriptionControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  }else if(startDateControl?.hasError('required')){
    this.errorMessage = 'you must enter a start date';
  }else if(startDateControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  }else if(endDateControl?.hasError('required')){
    this.errorMessage = 'you must enter an end date';
  }else if(endDateControl?.hasError('maxLength')){
    this.errorMessage = 'exceeds max character length';
  } else{
    this.errorMessage = '';
  }
}


}
