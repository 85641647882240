
import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, ChangeDetectionStrategy, inject, OnInit,AfterViewInit } from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';

import '@googlemaps/extended-component-library/api_loader.js';



import {SharedService} from '../shared/shared.service'
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';

import {Event} from '../shared/event/event'
import {
  MatDialog
 
} from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import {EventService} from '../Account/profile/services/eventService'
import { ServicesResult } from '../shared/account/ServicesResult';
import { FileUploadService } from '../Account/profile/services/fileUploadService';
import { AddEventModalComponent } from './add-event-modal/add-event-modal.component';
import { EventComponent } from './event/event.component';


@Component({
  selector: 'app-events-page',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [RouterModule,MatMenuModule,MatButtonModule,MatCardModule,MatIconModule,MatButtonModule,CommonModule],
  template: `

<!-- <button mat-menu-item (click)="openAddEventDialog()">Add Position</button> -->

<div *ngIf="!isChildRouteActive" class="container text-center" >
  <div class="row justify-content-center">
    <!-- Column for events -->
    <div class="col-12 col-lg-6">
      <!-- Event card -->
            <!-- Add Position button -->
      <button
        mat-raised-button
        class="w-100 mt-5 rounded-lg shadow-lg addEventBtn"
        (click)="openAddEventDialog()"
      >
        Add New Event
      </button>
    
      
      <mat-card id="eventContainer" class="my-5  rounded-lg shadow-lg w-100 text-start" *ngFor="let event of events" (click)="goToEventModal(event)">
       
          <mat-card-title class="my-3 px-3">{{ event.name }}</mat-card-title>
       
        <img mat-card-image [src]="event.eventPic" alt="event image" class="eventImage">
        <mat-card-content class="text-start">
          <p class="d-flex align-items-center mt-3"><mat-icon class="me-2" aria-label="info">info</mat-icon>{{ event.description }}</p>
          <p class="d-flex align-items-center"><mat-icon class="me-2" aria-label="schedule">schedule</mat-icon> {{ event.startTimeString }} - {{ event.endTimeString }}</p>
          <p class="d-flex align-items-center"><mat-icon class="me-2" aria-label="event">event</mat-icon> {{ event.startDateString }} - {{ event.endDateString }}</p>
          <p class="d-flex align-items-center"><mat-icon class="me-2" aria-label="place">place</mat-icon> {{ event.eventAddress }}</p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<router-outlet></router-outlet> <!-- This is needed for child routes -->
        <!-- <div [id]="'map-' + event.eventId"></div> -->

<!--         
           <gmp-map map-id="4f33f0e7f6ab915e" [center]="event.center" zoom="15">
        <gmp-advanced-marker [position]="event.position"></gmp-advanced-marker>
</gmp-map>    -->

<!-- <gmp-map map-id="4f33f0e7f6ab915e" center="-11,11" zoom="15">
        <gmp-advanced-marker position="-11,11"></gmp-advanced-marker>
</gmp-map>   -->

    <!-- <gmpx-api-loader key="AIzaSyCDRCW8LibsSE8deZsLgKvchXQ5K72GNns" solution-channel="GMP_GCC_mapandmarker_v2" />   -->
    <!-- Empty column (only visible on larger screens) -->
    <!-- <div class="col-lg-3 d-none d-lg-block">
    </div> -->


  `,
  styles: `

::ng-deep .addEventBtn {
  background-color: #4F4F4F !important; /* Red background */
  color: #FFFFFF !important; /* White text */
}
  .col-12.col-lg-6 {
    height: 100vh; /* Set to full viewport height for testing */
  }

  .eventImage{
    width: 100%; /* Make image responsive */
  height: 40vh; /* Fixed height for uniformity */
  object-fit: cover; /* Crop image to fill container */
  border-bottom: 2px solid #d6d8db; /* Very light grey */
  border-top: 2px solid #d6d8db; /* Very light grey */
  }

  gmp-map {
  width: 100%;
  height: 40em;
}

#eventContainer {
  border: 2px solid #d6d8db; /* Very light grey */
  
  
  cursor: pointer ; /* Change this to any desired cursor style */
}
  `
})
export class EventsPageComponent implements OnInit {

  readonly dialog = inject(MatDialog);
  events: Event[] = []; // Initialize with an empty array

  isChildRouteActive = false;
 
  // center: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  // position: google.maps.LatLngLiteral= { lat: 0, lng: 0 };
 
  constructor( private router: Router,private route: ActivatedRoute, private fileUploadService: FileUploadService, private sharedService: SharedService,private cdr: ChangeDetectorRef, private eventService: EventService){



    // Check if the current route has a child route (i.e., workExperience or education) and if so make parent comp disapeear when go to child compeonetn
this.router.events.subscribe(() => {
  this.isChildRouteActive = this.route.firstChild !== null;
});
  }

//   ngOnInit(): void {
//  this.initializeEventFeed();
//   }
  
  
ngOnInit() {
    // Load Google Maps script dynamically
    this.loadGoogleScript()
      .then(() => {
        this.initializeEventFeed();
    
  
      })
      .catch((error) => {
        console.error('Google Maps API failed to load:', error);
        //console.log('Google Maps API failed to load:');
      });

  // Subscribe to router events to detect navigation back to /profile
  this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd && event.urlAfterRedirects === '/eventfeed') {
     // Load Google Maps script dynamically
    this.loadGoogleScript()
    .then(() => {
      this.initializeEventFeed();
  

    })
    .catch((error) => {
      console.error('Google Maps API failed to load:', error);
      //console.log('Google Maps API failed to load:');
    });

    }
});


  }


  // ngAfterViewInit(): void {
  //   this.initializeMaps();
  // }



  openAddEventDialog(){
    // this.removeGoogleMapScript();
    const dialogRef= this.dialog.open(AddEventModalComponent)

    // Listen for the event emitted from the modal
  dialogRef.componentInstance.eventCreated.subscribe((newEvent: Event) => {
    //console.log("New event created:", newEvent);
    this.events = [...this.events, newEvent]; // Add the new event to the list
  });
  }





loadGoogleScript(): Promise<void>{
  return new Promise((resolve,reject)=> {
   
      //console.log("google script loaded");
     // console.log('scriptElementRef:', this.scriptElementRef);  // Check if scriptElementRef is set
      const script = document.createElement('script');
      script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCDRCW8LibsSE8deZsLgKvchXQ5K72GNns&libraries=places,maps&v=weekly";
      script.async = true;
      script.defer = true;
      script.onload = () => resolve();
      script.onerror = (error) => reject(error);
      document.head.appendChild(script);  // Add script to the head or any other appropriate locatio
    
  })


}

  initializeEventFeed() {
    this.eventService.getEvents().subscribe({
      next: (events: Event[]) => {  
       // console.log("Received event data:", events);  // Log the result object
        // Map through the received jobs array and add each job to the list
    
        this.events = events.map(event => ({
          name: event.name || '',  // Default if empty
          description: event.description || '', 
          startDate: event.startDate || new Date('2024-09-17'),
          endDate: event.endDate || new Date('2024-09-17'),
          startTime: event.startTime || new Date('2024-09-17'),
          endTime: event.endTime|| new Date('2024-09-17'),
          eventId: event.eventId || 0,
          eventAddress: event.eventAddress || '',
          lat: event.lat || 0,
          lng: event.lng || 0,
          eventPicFile: null,
          eventPic: event.eventPic || '',
          startDateString: event.startDateString || '',
          endDateString: event.endDateString || '',
          endTimeString:event.endTimeString || '',
          startTimeString:event.startTimeString || '',
         // center: { lat: event.lat, lng: event.lng },  // Set center as an object
         //position: { lat: event.lat, lng: event.lng } ,


        }));
    

                // Trigger change detection manually
        this.cdr.markForCheck();


       // this.initializeMaps();
      },
      error: (error) => {
        console.error('Error fetching event list:', error);
        // Handle error and set default values
       // Set default job in case of error (or handle error as needed)
       this.events = [{
        name: '',
        description: '',
        eventAddress: '',
        startDate: new Date('2024-09-17'),
        endDate: new Date('2024-09-17'),
        startTime: new Date('2024-09-17'),
        endTime: new Date('2024-09-17'),
        startDateString: '',
        endDateString:  '',
        endTimeString: '',
        startTimeString: '',
        eventId: 0,
        lat :0,
        lng:0,
        //center: { lat: 0, lng: 0},  // Set center as an object
        //position: { lat: 0, lng: 0 } ,
        eventPicFile: null,
        eventPic: ''
      }];
              // Trigger change detection manually
              this.cdr.markForCheck();
      },
      complete: () => {
        //console.log('event retrieval completed');
      },
    });
  }
  
  goToEventModal(event: Event){
    console.log("gotoeventmodalmethod");
    this.dialog.open(EventComponent, {data:event,})


    
      }
    
  // initializeMaps(): void {
  //    // Check if this.events is not null or undefined
  // if (this.events && this.events.length > 0) {
  //   this.events.forEach(event => {
  //     const mapContainer = document.getElementById(`map-${event.eventId}`);
  //     if (mapContainer) {
  //       const map = new google.maps.Map(mapContainer, {
  //         center: event.center,
  //         zoom: 14
  //       });

  //       new google.maps.Marker({
  //         position: event.position,
  //         map: map
  //       });
  //     }
  //   });
  // } else {
  //   console.warn("No events available to initialize maps.");
  // }
  // }


}
