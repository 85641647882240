import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, Input  } from '@angular/core';
import { FormsModule } from '@angular/forms';
@Component({
  selector: 'app-event-comment-input',
  standalone: true,
  imports: [FormsModule],
  template: `
   
  <form #commentForm="ngForm"  (ngSubmit)="sendEventComment()" autocomplete="off">
<div class="row">

<div class="col-9">
  <input type="text" class="form-control" name="commentText" [(ngModel)]="commentText" required placeholder="send comment">



</div>

<div class="col-3">
  <div class="d-grid">
<button class="btn btn-dark btn-block" [disabled]= "!commentForm.valid" type="submit"> comment</button>
  </div>

</div>
</div>
  </form>

  `,
  styles: ``
})
export class EventCommentInputComponent implements OnInit {






  commentText:string='';
  @Output() contentEmitter = new EventEmitter();
  
  
  constructor(private cdr: ChangeDetectorRef){}
  
  
    ngOnInit(): void {
  
    }
  
    sendEventComment(){
  if(this.commentText.trim() !==""){
  this.contentEmitter.emit(this.commentText);
  
 // console.log("commentText:",this.commentText)
  }
  
  this.commentText= "";
  
  
    }

}
