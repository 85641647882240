import { Component, Input, OnDestroy, OnInit,ViewChild , ElementRef ,OnChanges, SimpleChanges,ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ChatService } from '../../Account/profile/services/ChatService';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DirectMessagesComponent } from '../direct-messages/direct-messages.component';
import { ChatInputComponent } from '../chat-input/chat-input.component';
import { ApplicationUser } from '../../shared/account/applicationUser';
import { ProfileHeader } from '../../shared/account/profileHeader';
import { CommonModule } from '@angular/common';
import { DirectMessage } from '../../shared/chat/directMessage';
import { EventComment } from '../../shared/chat/eventComment';

@Component({
  selector: 'app-private-chat-modal',
  standalone: true,
  imports: [DirectMessagesComponent,ChatInputComponent,MatIconModule,CommonModule],
  template: `
<div class="modal-header">
<img [src]="eventComment?.profilePic" alt="Avatar" class="avatar mr-3">
<div class="d-flex flex-column">
    <h4 class="modal-title mb-0">{{eventComment.firstName}} {{eventComment.lastName}}</h4>
    <!-- <h6 class="text-muted"><small>{{receiver.banner}}</small></h6> -->
  </div>
  <button type="button" class="btn-close" (click)="activeModal.dismiss('cross click')"></button>
</div>
<div class="modal-body">
  <!-- <div class="card border-secondary"> -->
<!-- <div class="card-header h3 text-muted text-center">Messages</div> -->
<div class="card-body overflow-auto position-relative" #messageContainer (scroll)="onScroll()" style="max-height: 300px;">
  
<div class="card-text" *ngFor="let message of chatHistoryList">
<div class="d-flex align-itmes-start">
<img src={{message?.profilePic}} alt="Avatar" class="avatar me-2"> 
<div class="d-flex flex-column">
<span class="fw-bold">{{message.firstName}} {{message.lastName}}&middot;{{message.localTime}}{{message.date}}</span>
<span class="ml-1 mb-3 mt-1"> {{message.messageContent}}</span>
</div>
</div>
</div>
    <app-direct-messages  [directMessages]="chatService.privateMessages"></app-direct-messages>
  </div>
  <div *ngIf="!isScrolledToBottom" class="d-grid gap-2 mt-2 scroll-circle">
  <mat-icon class="scroll-icon" (click)="scrollToBottom()">arrow_downward</mat-icon>


  </div>
<div class="card-footer mt-2">
<app-chat-input (contentEmitter)="sendMessage($event)"></app-chat-input>

<!-- </div> -->


<!-- </div> -->
</div>

  `,
  styles: `.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
 }
 .card-body {
  position: relative;       /* Ensures the icon is positioned within this container */
}

.scroll-icon {
  position: absolute;       /* Position relative to the container */
  right: 40px;              /* Distance from the right edge of the container */
  bottom: 70px;             /* Distance from the bottom of the container */
  cursor: pointer;          /* Pointer cursor for interactivity */
   font-size: 24px;          /* Adjust icon size */
  color: white;           /* Set icon color */
  background-color: blue;  /* Optional background for visibility */
  // padding: 8px;             /* Padding for a larger clickable area */
  // border-radius: 50%;       /* Circular background */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow */
  transition: transform 0.2s ease-in-out, color 0.2s; /* Smooth hover transition */
}

.scroll-icon:hover {
  color: #0056b3;           /* Darker color on hover */
  transform: scale(1.1);    /* Slightly enlarge the icon on hover */
}

.scroll-circle{

}
`
})
export class PrivateChatModalComponent implements OnInit, OnDestroy {
//@Input() receiver!:ApplicationUser;
//@Input() receiver: ProfileHeader = {} as ProfileHeader;  // Initialize with a default object
  @Input() eventComment!: EventComment;
// @Input() receiverId!: string; 
chatHistoryList: DirectMessage[] | null=null; 


constructor(public chatService:ChatService, public activeModal:NgbActiveModal,private cdr: ChangeDetectorRef){

}
  ngOnDestroy(): void {
this.chatService.closePrivateChatMessage(this.eventComment);
  }



  // ngOnInit(): void {
  //   const senderId = this.chatService.myUser!.applicationUserId;
  //   const receiverId = this.eventComment.userId!;
  
  //   this.chatService.openPrivateChat(senderId, receiverId).then(() => {
  //     console.log('Private chat initialized.');
  //     console.log(" senderid",senderId );

  //     console.log(" recever id",receiverId );
  //     this.initializeChatHistory();
  //   }).catch(error => {
  //     console.error('Error initializing private chat:', error);
  //   });
  // }
  

  ngOnInit(): void {
    const senderId = this.chatService.myUser!.applicationUserId;
    const receiverId = this.eventComment.userId!;

    // this.chatService.createPrivateChatConnection(senderId, receiverId);
    // this.initializeChatHistory();
    console.log('Private chat initialized.');
      console.log(" senderid",senderId );
  
      console.log(" recever id",receiverId );
    this.chatService.createPrivateChatConnection(senderId, receiverId)
   
      this.initializeChatHistory();
  
  }


  initializeChatHistory() {
    this.chatService.getPrivateChatHistory(this.chatService.myUser!,this.eventComment).subscribe({
      
      next: (chatHistoryList: DirectMessage[]) => {  
       // console.log("senderid and receiuverr:",this.chatService.myUser,this.eventComment);
        //console.log("Received chat history data:", chatHistoryList);  // Log the result object
        // Map through the received jobs array and add each job to the list


        
        this.chatHistoryList = chatHistoryList.map(directMessage => {



              // Ensure timestamp is parsed correctly or defaults to a specific date if undefined
              const timeStamp = directMessage.timestamp ? new Date(directMessage.timestamp) : null;
        
        return {
          messageContent: directMessage.messageContent || '',  // Default if empty
          senderId: directMessage.senderId || '', 
          receiverId: directMessage.receiverId || '', 
          timeStamp: directMessage.timestamp || new Date('2024-09-17'),
          profilePic:directMessage.profilePic || '',
          date:  timeStamp ? timeStamp.toLocaleDateString() : '9-17',
          firstName: directMessage.firstName || '',
          lastName: directMessage.lastName || '',
          isGoogleAccount: directMessage.isGoogleAccount,
          useGooglePic: directMessage.useGooglePic ,
          googleProfilePic:directMessage.googleProfilePic ,

          localTime: timeStamp ? timeStamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : ''

  
    

        }

        });
    

                // Trigger change detection manually
        this.cdr.markForCheck();


       // this.initializeMaps();
      },
      error: (error) => {
        console.error('Error fetching chat history:', error);
        // Handle error and set default values
       // Set default job in case of error (or handle error as needed)
       this.chatHistoryList = [{
        messageContent: '',
        senderId: '',
        receiverId: '',
        timestamp: new Date('2024-09-17'),
        isGoogleAccount: false,
        useGooglePic: false,
        googleProfilePic: ''
       
      }];
              // Trigger change detection manually
              this.cdr.markForCheck();
      },
      complete: () => {
       // console.log('chat history retrieval completed');
      },
    });
  }
  





  @ViewChild('messageContainer') messageContainer!: ElementRef;
  isScrolledToBottom = true;

  onScroll() {
    const container = this.messageContainer.nativeElement;
    // Check if user has not scrolled to the bottom
    this.isScrolledToBottom = container.scrollHeight - container.scrollTop === container.clientHeight;
  }

  scrollToBottom() {
    const container = this.messageContainer.nativeElement;
    container.scrollTop = container.scrollHeight;  // Scroll to bottom
    this.isScrolledToBottom = true;  // Hide button after scrolling
  }

  sendMessage(messageContent: string){

    this.chatService.sendPrivateMessage(this.eventComment, messageContent)

console.log("privae chat send message log",this.eventComment,  messageContent );
  }

}
