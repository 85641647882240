import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, ChangeDetectionStrategy, inject, OnInit,  AfterViewInit } from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';

import '@googlemaps/extended-component-library/api_loader.js';



import {SharedService} from '../../../../shared/shared.service'
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';

import {Event as EventDTO} from '../../../../shared/event/event'
import {
  MatDialog
 
} from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import {EventService} from '../../../../Account/profile/services/eventService'
import { ServicesResult } from '../../../../shared/account/ServicesResult';
import { FileUploadService } from '../../../../Account/profile/services/fileUploadService';
import { EditMyEventComponent } from '../edit-my-event/edit-my-event.component';
import { ProfileService } from '../../services/profileService';
// import { AddEventModalComponent } from './add-event-modal/add-event-modal.component';



@Component({
  selector: 'app-my-events',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [RouterModule,MatMenuModule,MatButtonModule,MatCardModule,MatIconModule,MatButtonModule,CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
 <div *ngIf="!isChildRouteActive" class="container text-center" >
  <div class="row justify-content-center">
    <!-- Column for events -->
    <div class="col-12 col-lg-6">
      <!-- Event card -->

 <!-- Display this message when there are no events -->
 <div *ngIf="events!.length === 0" class="no-events-message mt-5">
    <h3>No events to display.</h3>
    <p>Create a new event to get started!</p>
  </div>

       
      <mat-card id="eventContainer" class="mx-3 my-5 rounded-lg shadow-lg" *ngFor="let singleEvent of events">
           
    <!-- Container for icons aligned to the end -->
    <mat-card-actions class="position-absolute top-0 end-0 mt-2 d-flex gap-2">




<button mat-button  [matMenuTriggerFor]="workMenu" ><mat-icon  aria-label="edit icon" fontIcon="edit"></mat-icon><mat-menu #workMenu="matMenu">
<button mat-menu-item (click)="openEditMyEventDialog(singleEvent)" >Edit</button>
<button mat-button (click)="deleteEvent(singleEvent)">
        <mat-icon aria-label="delete icon" fontIcon="delete">delete</mat-icon>
      </button>

</mat-menu>

</button>





</mat-card-actions>
       
          <mat-card-title class="my-3">{{ singleEvent.name }}</mat-card-title>
       
        <img mat-card-image [src]="singleEvent.eventPic" alt="event image">
        <mat-card-content>
          <p class="my-3">{{ singleEvent.description }}</p>
          <p>Start :{{ singleEvent.startTimeString }}</p>
          <p>End: {{ singleEvent.endTimeString }}</p>
          <p>Date: {{ singleEvent.startDateString }}</p>
          <p>{{ singleEvent.endDateString }}</p>
          <p>Location: {{ singleEvent.eventAddress }}</p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
  `,
  styles: `.col-12.col-lg-6 {
    height: 100vh; /* Set to full viewport height for testing */
  }
  
  gmp-map {
  width: 100%;
  height: 40em;
}`
})
export class MyEventsComponent implements OnInit{

  readonly dialog = inject(MatDialog);
  events: EventDTO[] | null=null; 
  isChildRouteActive = false;
 
  // center: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  // position: google.maps.LatLngLiteral= { lat: 0, lng: 0 };
 
  constructor( private profileService: ProfileService, private router: Router,private route: ActivatedRoute, private fileUploadService: FileUploadService, private sharedService: SharedService,private cdr: ChangeDetectorRef, private eventService: EventService){



    // Check if the current route has a child route (i.e., workExperience or education) and if so make parent comp disapeear when go to child compeonetn
this.router.events.subscribe(() => {
  this.isChildRouteActive = this.route.firstChild !== null;
});
  }


  openEditMyEventDialog(singleEvent:EventDTO){
    // this.removeGoogleMapScript();
    this.dialog.open(EditMyEventComponent, {
      data: singleEvent         // Pass the Job model here
    })
  }


 
  ngOnInit() {
    // Load Google Maps script dynamically
    this.loadGoogleScript()
      .then(() => {
        this.initializeEventFeed();
    
  
      })
      .catch((error) => {
        console.error('Google Maps API failed to load:', error);
       // console.log('Google Maps API failed to load:');
      });

  // Subscribe to router events to detect navigation back to /profile
  this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd && event.urlAfterRedirects === '/eventfeed') {
     // Load Google Maps script dynamically
    this.loadGoogleScript()
    .then(() => {
      this.initializeEventFeed();
  

    })
    .catch((error) => {
      console.error('Google Maps API failed to load:', error);
      //console.log('Google Maps API failed to load:');
    });

    }
});


  }








loadGoogleScript(): Promise<void>{
  return new Promise((resolve,reject)=> {
   
      //console.log("google script loaded");
     // console.log('scriptElementRef:', this.scriptElementRef);  // Check if scriptElementRef is set
      const script = document.createElement('script');
      script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCDRCW8LibsSE8deZsLgKvchXQ5K72GNns&libraries=places,maps&v=weekly";
      script.async = true;
      script.defer = true;
      script.onload = () => resolve();
      script.onerror = (error) => reject(error);
      document.head.appendChild(script);  // Add script to the head or any other appropriate locatio
    
  })


}

  initializeEventFeed() {
    this.eventService.getEventsByUser().subscribe({
      next: (events: EventDTO[]) => {  
       // console.log("Received event data:", events);  // Log the result object
        // Map through the received jobs array and add each job to the list
    
        this.events = events.map(event => ({
          name: event.name || '',  // Default if empty
          description: event.description || '', 
          startDate: event.startDate || new Date('2024-09-17'),
          endDate: event.endDate || new Date('2024-09-17'),
          startTime: event.startTime || new Date('2024-09-17'),
          endTime: event.endTime|| new Date('2024-09-17'),
          eventId: event.eventId || 0,
          eventAddress: event.eventAddress || '',
          lat: event.lat || 0,
          lng: event.lng || 0,
          eventPicFile: null,
          eventPic: event.eventPic || '',
          startDateString: event.startDateString || '',
          endDateString: event.endDateString || '',
          endTimeString:event.endTimeString || '',
          startTimeString:event.startTimeString || '',
         // center: { lat: event.lat, lng: event.lng },  // Set center as an object
         //position: { lat: event.lat, lng: event.lng } ,


        }));
    

                // Trigger change detection manually
        this.cdr.markForCheck();


       // this.initializeMaps();
      },
      error: (error) => {
        console.error('Error fetching event list:', error);
        // Handle error and set default values
       // Set default job in case of error (or handle error as needed)
       this.events = [{
        name: '',
        description: '',
        eventAddress: '',
        startDate: new Date('2024-09-17'),
        endDate: new Date('2024-09-17'),
        startTime: new Date('2024-09-17'),
        endTime: new Date('2024-09-17'),
        startDateString: '',
        endDateString:  '',
        endTimeString: '',
        startTimeString: '',
        eventId: 0,
        lat :0,
        lng:0,
        //center: { lat: 0, lng: 0},  // Set center as an object
        //position: { lat: 0, lng: 0 } ,
        eventPicFile: null,
        eventPic: ''
      }];
              // Trigger change detection manually
              this.cdr.markForCheck();
      },
      complete: () => {
        //('event retrieval completed');
      },
    });
  }



  deleteEvent(singleEvent: EventDTO) {
    // Log the job being deleted for debugging
    //console.log("Deleting job:", singleEvent);

    // Call the profile service, passing the job details or job ID as needed
    this.profileService.deleteEvent(singleEvent).subscribe({
        next: (response: any) => {
            //console.log(":response!!!!!!!!!!!!:", response);
            if (response.success) {
              // this.jobs= this.jobs?.filter(j=>j.jobId !==job.jobId) ?? null;
                this.sharedService.showNotification(response.success, response.title, response.message);
                //console.log(":response@@@@@@@", response);
            } else {
                this.sharedService.showNotification(response.success, response.title, response.message);
                //console.log(":response&&&&&&&&:", response);
            }
        },
        error: (error: HttpErrorResponse) => {
            // Log the entire error object for debugging
            console.error('Error in deleting event:', error);
            this.sharedService.showNotification(false, 'Error', error.message || 'Unknown error occurred');
        }
    });
}





}
