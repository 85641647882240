
import { Component, Inject,Input, ChangeDetectionStrategy, inject, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';

import {SharedService} from '../../shared/shared.service'
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import {ChatService} from '../../Account/profile/services/ChatService'
import { ProfileService } from '../../Account/profile/services/profileService';
import {
  MatDialog,
  MatDialogRef
 
} from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { ProfileHeader } from '../../shared/account/profileHeader';
import { ServicesResult } from '../../shared/account/ServicesResult';
import { About } from '../../shared/account/about';
import { Job } from '../../shared/account/job';
import { Education } from '../../shared/account/education';
import { ApplicationUser } from '../../shared/account/applicationUser';
import { ChatInputComponent } from '../../chat/chat-input/chat-input.component';
import { DirectMessagesComponent } from '../../chat/direct-messages/direct-messages.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrivateChatModalComponent } from '../../chat/private-chat-modal/private-chat-modal.component';
import { EventComment } from '../../shared/chat/eventComment';
import {EventCommentInputComponent} from '../../eventsPage/event-comment-input/event-comment-input.component'
import { AccountService } from '../../Account/account.Service';
import{Event as EventDTO} from '../../shared/event/event'
import { EventCommentsComponent } from '../event-comments/event-comments.component';
@Component({
  selector: 'app-event-chat',
  standalone: true,
  imports: [EventCommentsComponent,EventCommentInputComponent,RouterModule,MatMenuModule,MatButtonModule,MatCardModule,MatIconModule,MatButtonModule,CommonModule,ChatInputComponent,DirectMessagesComponent],
  template: `








<hr>
<div class="row mt-3">
 
  <!-- <div class="card border-secondary"> -->
<!-- <div class="card-header h3 text-muted text-center">Messages</div> -->
<div class="card-body overflow-auto position-relative" #messageContainer  style="max-height: 300px;">
  
<div class="card-text" *ngFor="let comment of eventChatHistoryList">
<div class="d-flex align-itmes-start">
<!-- Clickable image with menu trigger -->
<img 
         [src]="comment?.profilePic"
        alt="Avatar" 
        class="avatar me-2" 
        [matMenuTriggerFor]="profileMenu" 
        style="cursor: pointer;" 
      >
      
      <!-- Menu that opens when the image is clicked -->
      <mat-menu #profileMenu="matMenu">
        <button mat-menu-item (click)="viewProfile(comment.userId!)">View Profile</button>
        <button *ngIf="(accountService.user$ | async)?.id !== comment.userId" mat-menu-item (click)="openPrivateChat(comment!)">Direct Message</button>
      </mat-menu>

<div class="d-flex flex-column">
<span class="fw-bold">{{comment.firstName}} {{comment.lastName}}&middot;{{comment.localTime}}{{comment.date}}</span>
<span class="ml-1 mb-3 mt-1"> {{comment.commentText}}</span>
</div>
</div>
</div>
    <app-event-comments  [eventComments]="chatService.eventCommentsMap[event.eventId!] || []"></app-event-comments>
  </div>




 


<app-event-comment-input (contentEmitter)="sendEventComment(event.eventId!,$event)"></app-event-comment-input>




  `,
  styles: `.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
 }`
})
export class EventChatComponent implements OnInit, OnDestroy{

  @Input() event!: EventDTO

 public userId!: string;
  userProfile: ProfileHeader | null =null;
  public myUserProfilePic!: string
  openChat= false;
  user: ApplicationUser | null = null;
  readonly dialog = inject(MatDialog);

  eventChatHistoryList: EventComment[] | null=null; 

  constructor(public accountService: AccountService,private modalService: NgbModal, private dialogRef: MatDialogRef<EventChatComponent>,private router: Router,private route: ActivatedRoute,private profileService: ProfileService,  public chatService: ChatService, private sharedService: SharedService,private cdr: ChangeDetectorRef) {
    

  }
  ngOnDestroy(): void {
    console.log("ngOnDestroy event chat component");

    this.chatService.closeEventChat(this.event!)
        .then(() => {
            console.log("RemoveEventGroup completed. Stopping connection...");
            this.chatService.stopChatConnection();
        })
        .catch(err => {
            console.error("Error in RemoveEventGroup:", err);
            // Still attempt to stop the connection
            this.chatService.stopChatConnection();
        });
}



  ngOnInit(): void {

    // Ensure the user joins the specific event group when the event chat component loads
    // this.chatService.joinEventGroup(this.event.eventId!);

    this.getUserData();
    this.initializeEventChatHistory();

// console.log("users chat exists here:", this.user);
//     if(this.user){
//     this.initializeChatName(this.user);

//     }

    //this.chatService.createChatConnection(this.userId);
  }


  
  viewProfile(userId: string){
    this.dialogRef.close();
    this.router.navigateByUrl(`/view-profile/${userId}`)
  }
  


  getUserData(): void {
    this.profileService.getUser().subscribe({
      next: (user: ApplicationUser) => {
        this.user = user;
       // console.log('User data received:', this.user);
        this.initializeChatName(this.user);
      
      },
      error: (error) => {
        console.error('Error retrieving user data:', error);
      },
    });
  }



  initializeEventChatHistory() {
    this.chatService.getEventChatHistory(this.event).subscribe({
      
      next: (eventChatHistoryList: EventComment[]) => {  
        
       // console.log("Received chat history data in event chat compoennt:", eventChatHistoryList);  // Log the result object
        // Map through the received jobs array and add each job to the list
         // Convert timeStamp to Date if it's not already
         this.eventChatHistoryList = eventChatHistoryList.map(eventComment => {
          // Log the timestamp received from the backend
          // console.log("Full event comment object:", eventComment);

          // console.log("Raw timestamp from backend:", eventComment.timestamp);
  
          // Ensure timestamp is parsed correctly or defaults to a specific date if undefined
          const timeStamp = eventComment.timestamp ? new Date(eventComment.timestamp) : null;
  
          // Log to confirm parsing success or failure
     
          return {
            commentText: eventComment.commentText || '', 
            userId: eventComment.userId || '', 
            firstName: eventComment.firstName || 'mike', 
            lastName: eventComment.lastName || 'smith', 
            eventId: eventComment.eventId || 0, 
            commentId: eventComment.commentId || 0, 
            timeStamp: timeStamp || new Date('2024-09-17'),
            profilePic: eventComment.profilePic || '',
            isGoogleAccount: eventComment.isGoogleAccount,
            useGooglePic: eventComment.useGooglePic,
            googleProfilePic: eventComment.googleProfilePic,
            date: timeStamp ? timeStamp.toLocaleDateString() : '9-17',
            localTime: timeStamp ? timeStamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : ''
          };
        });
                // Trigger change detection manually
        this.cdr.markForCheck();


       // this.initializeMaps();
      },
      error: (error) => {
        console.error('Error fetching event chat history:', error);
        // Handle error and set default values
       // Set default job in case of error (or handle error as needed)
       this.eventChatHistoryList = [{
        commentText: '',
        eventId: 0,
        commentId: 0,
        firstName: '',
        lastName: '',
        timestamp: new Date('2024-09-17'),
        isGoogleAccount: false,
        useGooglePic: false,
        googleProfilePic: ''


       
      }];
              // Trigger change detection manually
              this.cdr.markForCheck();
      },
      complete: () => {
       // console.log('evebt chat history retrieval completed');
      },
    });
  }
  

  initializeChatName(user: ApplicationUser) {
    this.profileService.getProfileHeader(this.user?.id).subscribe({
      next: (userProfile: ProfileHeader) => {  // Now expect ProfileHeader directly
       //console.log("Received profileheader data:", userProfile);  // Log the result object
          // Assign the received profileHeader to the component's profileHeader
          this.userProfile = {
            firstName: userProfile.firstName || '',  // Default if empty
            lastName: userProfile.lastName || '',    // Default if empty
            applicationUserId:userProfile.applicationUserId || '',
            banner: userProfile.banner || '',
            profilePic: userProfile.profilePic || '',
            profilePicFile: null,
            about: userProfile.about || '',
            isGoogleAccount: userProfile.isGoogleAccount,
          googleProfilePic: userProfile.googleProfilePic ,
          useGooglePic:userProfile.useGooglePic

            
                 

                  // Default if empty
          };

         this.userId = userProfile.applicationUserId;
this.myUserProfilePic= userProfile.profilePic;

//console.log("this.eventChatHistoryList![0]?.eventId!:",this.event.eventId)
         if (this.userProfile) {
          // Now that we have the userId, start the chat connection
          //console.log("this.eventChatHistoryList![0]?.eventId!:",this.event.eventId)
          this.chatService.createEventChatConnection(this.userProfile, this.event.eventId!);
          console.log('eventchatcreationinappeventchat');
        } else {
          console.log('User ID is not available');
        }
  
       // Trigger change detection manually
      //this.cdr.detectChanges();  // You can use detectChanges here for immediate effect
                // Trigger change detection manually
        this.cdr.markForCheck();
      },
      error: (error) => {
        console.error('Error fetching profile header:', error);
        // Handle error and set default values
        this.userProfile = {
          firstName: '',
          lastName: '',
          profilePic: '',
          about: '',
          applicationUserId: '',
          profilePicFile: null,
          isGoogleAccount: false,
          googleProfilePic: '',
          useGooglePic: false
        };

        // Trigger change detection manually
     //this.cdr.detectChanges();  // You can use detectChanges here for immediate effect
              // Trigger change detection manually
              this.cdr.markForCheck();
      },
      complete: () => {
       // console.log('profile header retrieval completed');
      },
    });
  }
  
  sendEventComment(eventId: number, commentText:string){

  this.chatService.sendEventComment(eventId, commentText);
}


openPrivateChat(eventComment:EventComment)
{
  
 // console.log(" in event chat comp:",eventComment)
  const modalRef= this.modalService.open(PrivateChatModalComponent);
  console.log("eventcomnentinmodalopen",modalRef.componentInstance.eventComment );
  modalRef.componentInstance.eventComment= eventComment;
  console.log("eventcomnentinmodalopen",modalRef.componentInstance.eventComment );
}

}
