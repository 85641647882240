import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable,  inject, } from '@angular/core';

import { environment } from '../../../../Environments/environment';

import { ReplaySubject, map, of, Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { Job } from '../../../shared/account/job';
import { ServiceResult } from '../../../shared/account/ServiceResult';
import { Education } from '../../../shared/account/education';
import { Event } from '../../../shared/event/event';
import { ProfileHeader } from '../../../shared/account/profileHeader';

import { ServicesResult} from '../../../shared/account/ServicesResult';


@Injectable({
  providedIn: 'root'
})
export class EventService {


  

  constructor(private http: HttpClient, private router: Router,private cookieService: CookieService) {
      
    
 }





    
 addEvent(formData: FormData) {
  //console.log("eventserice hit");
 // console.log("FORMDAT:",formData )
  formData.forEach((value, key) => {
    //console.log(`${key}:`, value);
  });

  
  return this.http.post<ServiceResult>(`${environment.appUrl}/Event/AddEvent`, formData, { withCredentials: true })
    .pipe(
      map((response: ServiceResult) => {
        //console.log("eventserice hit");
        if (response.success) {
          //console.log("event added successfully:", JSON.stringify(response, null, 2));
          this.router.navigateByUrl('/eventfeed'); // Navigate to the home page
        } else {
          console.log('Failed to add event:');
        }
        return response;
      }),
      catchError((error) => {
        console.error("Error in adding event:", error);
        const errorResponse: ServiceResult = {
          title : error.error.title,
          success: false,
          message: error.error.message || 'Unknown error'
        };
        return of(errorResponse);
      })
    );
}






getEvents(): Observable<Event[]> {
  return this.http.get<ServicesResult<Event[]>>(`${environment.appUrl}/Event/GetEvents`, { withCredentials: true })
    .pipe(
      map((response: ServicesResult<Event[]>) => {
        if (response.success && response.result) {
          //console.log('event received:', response.result);  // Log the profile data
          return response.result;  // Return the ProfileHeader object
        } else {
          throw new Error(response.message || 'Failed to get event');
        }
      }),
      catchError((error) => {
        console.error('eventlist:', error);
        return throwError(() => error);
      })
    );
}



getEventsByUser(): Observable<Event[]> {
  return this.http.get<ServicesResult<Event[]>>(`${environment.appUrl}/Event/GetEventsByUser`, { withCredentials: true })
    .pipe(
      map((response: ServicesResult<Event[]>) => {
        if (response.success && response.result) {
          //console.log('event received:', response.result);  // Log the profile data
          return response.result;  // Return the ProfileHeader object
        } else {
          throw new Error(response.message || 'Failed to get event');
        }
      }),
      catchError((error) => {
        console.error('eventlist:', error);
        return throwError(() => error);
      })
    );
}











}
