import { Component, OnInit ,Inject,  ViewChild, ElementRef,afterNextRender,AfterViewInit, ChangeDetectionStrategy} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { ValidationMessagesComponent } from '../../../../shared/errors/validation-messages/validation-messages.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {provideNativeDateAdapter} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { merge, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {ProfileService} from '../../services/profileService'
import {SharedService} from '../../../../shared/shared.service'


import {
  MatDialogModule,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-add-work-modal',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule,MatFormFieldModule, MatInputModule, MatSelectModule,MatDatepickerModule,MatCheckboxModule,   CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    FormsModule,
    ValidationMessagesComponent ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
<div class="container text-center">
  <form [formGroup]="experienceForm" (ngSubmit)="addWorkExperience()" autocomplete="off" id="experienceForm">
    <h2 mat-dialog-title>Experience</h2>
    <mat-dialog-content class="mat-typography">
      
      <!-- Position Title Field -->
      <div class="col-12">
        <mat-form-field  appearance="fill" class="full-width mt-3">
          <mat-label>Title</mat-label>
          <input matInput formControlName="positionTitle" placeholder="ex: project manager">
          <mat-error *ngIf="submitted && experienceForm.get('positionTitle')?.hasError('required')">
            Position is required
          </mat-error>
          <mat-error *ngIf="submitted && experienceForm.get('positionTitle')?.hasError('maxlength')">
            Max length is 50 characters
          </mat-error>
        </mat-form-field>
      </div>
      
      <!-- Company Name Field -->
      <div class="col-12">
        <mat-form-field appearance="fill" class="full-width mt-1">
          <mat-label>Company</mat-label>
          <input matInput formControlName="companyName" placeholder="ex: Toyota">
          <mat-error *ngIf="submitted && experienceForm.get('companyName')?.hasError('required')">
            Company is required
          </mat-error>
          <mat-error *ngIf="submitted && experienceForm.get('companyName')?.hasError('maxlength')">
            Max length is 50 characters
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Description Field -->
      <div class="col-12">
        <mat-form-field appearance="fill" class="full-width mt-1">
          <mat-label>Description</mat-label>
          <textarea matInput formControlName="positionDescription"></textarea>
          <mat-error *ngIf="submitted && experienceForm.get('positionDescription')?.hasError('required')">
            Description is required
          </mat-error>
          <mat-error *ngIf="submitted && experienceForm.get('positionDescription')?.hasError('maxlength')">
            Max length is 3000 characters
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Still Work Here Checkbox -->
      <div class="col-12">
        <mat-checkbox formControlName="stillWorkHere">I still work here</mat-checkbox>
      </div>
      
      <!-- Start Date and End Date Fields Side by Side -->
      <div class="row g-3">
        <div class="col-12 col-md-6">
          <mat-form-field class="mt-1">
            <mat-label>Start Date</mat-label>
            <input matInput formControlName="startDate" [matDatepicker]="startDate">
            <mat-error *ngIf="submitted && experienceForm.get('startDate')?.hasError('required')">
              Start date is required
            </mat-error>
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-6">
          <mat-form-field class="mt-1">
            <mat-label>End Date</mat-label>
            <input matInput formControlName="endDate" [matDatepicker]="endDate">
            <mat-error *ngIf="submitted && experienceForm.get('endDate')?.hasError('required')">
              End date is required
            </mat-error>
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

    </mat-dialog-content>

    <mat-dialog-actions align="end"> 
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button cdkFocusInitial type="submit">Save</button>
    </mat-dialog-actions>
  </form>
</div>

  `,
  styles: `.full-width {
    width: 100%; /* Ensures the field spans the full container width */
  }`
})
export class AddWorkModalComponent implements OnInit {


  experienceForm: FormGroup = new FormGroup({
    stillWorkHere: new FormControl(false) // Default value: unchecked
  });
  submitted = false;
  errorMessages: string[] = [];
  errorMessage = '';
  
  returnUrl: string | null = null;

  constructor(

    private dialogRef: MatDialogRef<AddWorkModalComponent>,
     private profileService: ProfileService,  // Inject ProfileService here
  private sharedService: SharedService  ,   // Inject SharedService here
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {  
    
    
    this.experienceForm = this.formBuilder.group({
    positionTitle: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    companyName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    positionDescription: new FormControl('', [Validators.required, Validators.maxLength(3000)]),
    stillWorkHere: new FormControl(false),
    startDate: new FormControl('', [Validators.required, Validators.maxLength(15)]),
    endDate: new FormControl('', [Validators.required, Validators.maxLength(15)])
    
  });



  merge(
    this.experienceForm.get('positionTitle')!.statusChanges!,
    this.experienceForm.get('positionTitle')!.valueChanges!,
    this.experienceForm.get('companyName')!.statusChanges!,
    this.experienceForm.get('companyName')!.valueChanges!,
    this.experienceForm.get('positionDescription')!.statusChanges!,
    this.experienceForm.get('positionDescription')!.valueChanges!,
    this.experienceForm.get('stillWorkHere')!.statusChanges!,
    this.experienceForm.get('stillWorkHere')!.valueChanges!,
    this.experienceForm.get('startDate')!.statusChanges!,
    this.experienceForm.get('startDate')!.valueChanges!,
    this.experienceForm.get('endDate')!.statusChanges!,
    this.experienceForm.get('endDate')!.valueChanges!
  
  )
    .pipe(takeUntilDestroyed())
    .subscribe(() => this.updateErrorMessage());
  
  }







ngOnInit(): void {
  this.initializeForm();
      // Listen for changes in the isChecked checkbox
      this.experienceForm.get('stillWorkHere')?.valueChanges.subscribe((stillWorkHere: boolean) => {
        this.toggleEndDateValidator(stillWorkHere);
      });
  
    
  };

  
  
initializeForm() {
  this.experienceForm = this.formBuilder.group({
    positionTitle: ['', [Validators.required, Validators.maxLength(50)]],
    companyName: ['', [Validators.required, Validators.maxLength(50)]],
    positionDescription: ['', [Validators.required, Validators.maxLength(3000)]],
    stillWorkHere: [false ],
    startDate: ['', [Validators.required, Validators.maxLength(15)]],
    endDate: ['', [Validators.required, Validators.maxLength(15)]],
  });
}




toggleEndDateValidator(stillWorkHere: boolean) {
  const endDateControl = this.experienceForm.get('endDate');

  if (stillWorkHere) {
    // If "I still work here" is checked, remove the required validator for End Date
    endDateControl?.clearValidators();
  } else {
    // If unchecked, add the required validator back to End Date
    endDateControl?.setValidators([Validators.required, Validators.maxLength(15)]);
  }
  // Update the validity of the endDate field after changing the validators
  endDateControl?.updateValueAndValidity();
}

addWorkExperience() {
  this.submitted = true;
  this.errorMessages = [];

  if (this.experienceForm.valid) {
    this.profileService.addWorkExperience(this.experienceForm.value).subscribe({
      next: (response: any) => {
       // console.log(":response!!!!!!!!!!!!:", response);
        if (response.success) {
          this.sharedService.showNotification(response.success, response.title, response.message);
          this.dialogRef.close();
          //console.log(":response@@@@@@@", response);
          
        } else {
          this.sharedService.showNotification(response.success, response.title, response.message);
          //console.log(":response&&&&&&&&:", response);
        }
      },
      error: (error: HttpErrorResponse) => {
        // Log the entire error object for debugging
        console.error('Error in adding work experience:', error);
       // console.log(":response&&&&&&&&:");

        // Handle the error gracefully and display to the user
        this.sharedService.showNotification(false, 'Error', error.message || 'Unknown error occurred');
      }
    });
  } else{
   // console.log("invalid form");
   // console.log(this.experienceForm.value);
    this.focusFirstInvalidControl();
  }
}

focusFirstInvalidControl() {
  let form = document.getElementById('experienceForm'); // <-- your formID
  if(form){
    let firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
    firstInvalidControl.scrollIntoView();
    (firstInvalidControl as HTMLElement).focus();
  }

}


updateErrorMessage() {
  const positionControl = this.experienceForm.get('positionTitle');
  const companyControl = this.experienceForm.get('companyName');
  const descriptionControl = this.experienceForm.get('positionDescription');
  const startDateControl = this.experienceForm.get('startDate');
  const endDateControl = this.experienceForm.get('endDate');

  if (positionControl?.hasError('required')) {
    this.errorMessage = 'You must enter a position';
  } else if (positionControl?.hasError('maxlength')) {
    this.errorMessage = 'exceeds max character length';
  } else if (companyControl?.hasError('required')) {
    this.errorMessage = 'You must enter a company';
  } else if(companyControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  }else if(descriptionControl?.hasError('required')){
    this.errorMessage = 'you must enter a description';
  }else if(descriptionControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  }else if(startDateControl?.hasError('required')){
    this.errorMessage = 'you must enter a start date';
  }else if(startDateControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  }else if(endDateControl?.hasError('required')){
    this.errorMessage = 'you must enter an end date';
  }else if(endDateControl?.hasError('maxLength')){
    this.errorMessage = 'exceeds max character length';
  } else{
    this.errorMessage = '';
  }
}

}
