import { Component, OnInit,Inject, inject, ViewChild, ElementRef,afterNextRender,AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { AccountService } from '../account.Service';
import {  FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';

import { MatDividerModule } from '@angular/material/divider';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ValidationMessagesComponent } from "../../shared/errors/validation-messages/validation-messages.component";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { merge, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SharedService } from '../../shared/shared.service';
import { User } from '../../shared/account/user';
import { AuthResponse } from '../../shared/account/AuthResponse';
import { ProfileHeaderComponent } from '../profile/profilePageModals/profile-header/profile-header.component';
import { MatDialog } from '@angular/material/dialog';
import { ProfileRegisterHeaderComponent } from '../profile/profilePageModals/profile-header-register/profile-header-register.component';

import { RouterModule } from '@angular/router';
import { environment } from '../../../Environments/environment';



@Component({
    selector: 'app-register',
    standalone: true,
    template: `
  <!-- Full viewport container -->
<div  class="d-flex vh-100 justify-content-center align-items-center">
  <!-- Card container -->
  <mat-card class="form-signin p-4 col-12 col-md-6 col-lg-3 mt-5 rounded-lg shadow-lg">
    <h3 class="text-center pt-3 fw-normal">Join an authentic community and network today</h3>
    <form [formGroup]="registerForm" (ngSubmit)="register()" autocomplete="off">
      <div class="form-group">
        <mat-form-field class="full-width pt-2 px-3" appearance="fill">
          <mat-label>First name</mat-label>
          <input matInput formControlName="firstName" placeholder="John">
          <mat-error *ngIf="submitted && registerForm.get('firstName')?.hasError('required')">
            First name is required
          </mat-error>
          <mat-error *ngIf="submitted && registerForm.get('firstName')?.hasError('maxlength')">
            Max length is 15 characters
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width pt-3 px-3" appearance="fill">
          <mat-label>Last name</mat-label>
          <input matInput formControlName="lastName" placeholder="Smith">
          <mat-error *ngIf="submitted && registerForm.get('lastName')?.hasError('required')">
            Last name is required
          </mat-error>
          <mat-error *ngIf="submitted && registerForm.get('lastName')?.hasError('maxlength')">
            Max length is 15 characters
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width pt-3 px-3" appearance="fill">
          <mat-label>Email address</mat-label>
          <input matInput formControlName="email" placeholder="user@email.com">
          <mat-error *ngIf="submitted && registerForm.get('email')?.hasError('required')">
            Email is required
          </mat-error>
          <mat-error *ngIf="submitted && registerForm.get('email')?.hasError('email')">
            Not a valid email
          </mat-error>
          <mat-error *ngIf="submitted && registerForm.get('email')?.hasError('maxlength')">
            Email exceeds max length
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width pt-3 px-3" appearance="fill">
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password" [type]="hide ? 'password' : 'text'" placeholder="Password">
          <button type="button" mat-button matSuffix (click)="hide = !hide" class="mx-3">{{hide ? 'show' : 'hide'}}</button>
          <mat-error *ngIf="submitted && registerForm.get('password')?.hasError('required')">
            Password is required
          </mat-error>
          <mat-error *ngIf="submitted && registerForm.get('password')?.hasError('minlength')">
            Password must be at least 6 characters long
          </mat-error>
          <mat-error *ngIf="submitted && registerForm.get('password')?.hasError('maxlength')">
            Password cannot be longer than 15 characters
          </mat-error>
          <mat-error *ngIf="submitted && registerForm.get('password')?.hasError('pattern')">
            Password must contain at least one non-alphanumeric character
          </mat-error>
        </mat-form-field>

        <div class="d-grid  pt-3 px-5">
          <button mat-flat-button color="primary" class="rounded-pill py-4"  type="submit">Create account</button>
        </div>
      </div>
    </form>

    <!-- Google Sign-In Section -->
    <section class="text-center mt-3">
      <div id="g_id_onload"
           [attr.data-client_id]="clientID"
           data-context="signup"
           data-ux_mode="popup"
           data-auto_select="true"
           data-itp_support="true"
           data-use_fedcm_for_prompt="true"
           data-callback="handleGoogleRegister">
      </div>

      <div class="g_id_signin d-flex justify-content-center"
           data-type="standard"
           data-shape="rectangular"
           data-theme="outline"
           data-text="signin_with"
           data-size="large"
           data-logo_alignment="left">
      </div>
    </section>
  </mat-card>
</div>


  `,
    styles: [`
    .full-width {
      width: 100%;
    }
  `],



    imports: [CommonModule,
      ProfileRegisterHeaderComponent,
      MatDividerModule,RouterModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      MatInputModule,
      MatButtonModule,
      MatCardModule,
      FormsModule,
      ValidationMessagesComponent],
      changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent  implements OnInit {
  registerForm: FormGroup = new FormGroup({});
  submitted = false;
  errorMessages: string[] = [];
  errorMessage = '';
  hide = true;
  registerUri!: string;
  clientID!: string;
  //readonly dialog = inject(MatDialog);

registerData={}
  constructor(
    private accountService: AccountService,
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
    private router: Router) {


      this.accountService.user$.pipe(take(1)).subscribe({
        next: (authResponse: AuthResponse | null) => {
          if (authResponse) {
            this.router.navigateByUrl('/home');
          }
        }
      })

      
      this.registerForm = this.formBuilder.group({
        firstName: new FormControl('', [Validators.required, Validators.maxLength(15)]),
        lastName: new FormControl('', [Validators.required, Validators.maxLength(15)]),
        email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(30)]),
        password: new FormControl('', [Validators.required,Validators.minLength(6), 
          Validators.maxLength(15), Validators.pattern('^\d*[a-zA-Z][a-zA-Z0-9]*$')])
      });
  
      merge(
        this.registerForm.get('firstName')!.statusChanges!,
        this.registerForm.get('firstName')!.valueChanges!,
        this.registerForm.get('lastName')!.statusChanges!,
        this.registerForm.get('lastName')!.valueChanges!,
        this.registerForm.get('email')!.statusChanges!,
        this.registerForm.get('email')!.valueChanges!,
        this.registerForm.get('password')!.statusChanges!,
        this.registerForm.get('password')!.valueChanges!
      )
        .pipe(takeUntilDestroyed())
        .subscribe(() => this.updateErrorMessage());


    }


      // Use ngAfterViewInit to ensure the view is fully initialized
  ngAfterViewInit(): void {

   
      console.log("in browser");
     // console.log('scriptElementRef:', this.scriptElementRef);  // Check if scriptElementRef is set
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);  // Add script to the head or any other appropriate locatio
    
    
      
  }

  ngOnInit(): void {
    this.initializeForm();
          // Dynamically set the login URI based on the environment
          this.registerUri = `${environment.appUrl}/Account/register-google`;
          this.clientID = `${environment.clientID}`;

          (window as any).handleGoogleRegister = (response: any) => this.handleGoogleRegister(response.credential);

     // Read the query parameters from the URL to get the result of the registration
    //  this.activatedRoute.queryParams.subscribe(params => {
    //   let success = params['success'] === 'true'; // Check if registration was successful
    //   let message = params['message'];            // Get the message from the query params
      
    //   let title = success ? 'Google Registration Successful' : 'Google Registration Failed';

    //   // Show the notification dialog based on the success/failure
    //   console.log("!!!!!!!!!!!!!!!!",title, success, message);
    //   this.sharedService.showNotification(success, title, message);
      
    // });

//     if(currentRoute === 'register'){
//       console.log("registerpath:", currentRoute);
//       this.register();
//     } else if(currentRoute === 'register-google'){
//       console.log("registerGooglepath:", currentRoute);
// this.registerGoogle();
//     }
  

  }

  

  initializeForm() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(15)]],
      lastName: ['', [Validators.required, Validators.maxLength(15)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(30)]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(15),Validators.pattern('^(?=.*[^a-zA-Z0-9]).{6,15}$')]],
    })
  }

  handleGoogleRegister(credential: string ) {
    console.log("hit handlegooglesignin in login comp");
    console.log("credential in googlesign",credential );
    this.accountService.handleGoogleSignIn(credential).subscribe({
      next: (response: AuthResponse) => {
        console.log(response, "*****************response in handlegooglesignin in login comp");
        //console.log("frintdn login test::", response);
        //console.log("frintdn fewfwe test::", response.success);
        if (!response.isAuthenticated || !response.success) {
          // Handle unsuccessful response and errors
          this.sharedService.showNotification(response.success, response.title, response.message);
      
        } else
        if (!response.profileCompleted) {
         // console.log("profile complete is false", response.profileCompleted);
          // Redirect to profile setup if profile is incomplete
          this.router.navigateByUrl('/profileSetup');
        } else if (response.isAuthenticated && response.success) {

          //console.log("success and auth");
          // Redirect to main page if authenticated, successful, and profile completed
          this.router.navigateByUrl('/eventfeed');
        } 
      },
      error: (error) => {
        console.log('Unexpected error:', error);
        this.errorMessages.push('Unexpected error occurred.');
      }

    })

    
}

  register() {
    this.submitted = true;
    this.errorMessages = [];
  
    if (this.registerForm.valid) {
      this.accountService.register(this.registerForm.value).subscribe({
        next: (response: any) => {
          //console.log(":register response:", response);
          //console.log(":register form response:",this.registerForm.value);
  
  
          if (response.success) {
            //this.sharedService.showNotification(response.success, response.title, response.message);
  //console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$")
        // Redirect to the profile setup page
       this.router.navigate(['/profileSetup'], { 
        queryParams: { 
          email: this.registerForm.get('email')?.value || '',
          firstName: this.registerForm.get('firstName')?.value || '',
          lastName: this.registerForm.get('lastName')?.value || ''
        }
      });
          } else {
            this.sharedService.showNotification(response.success, response.title, response.message);
          }
        },
        error: (error) => {
          if (error.error.errors) {
            console.log(error.error.errors);
            this.errorMessages = error.error.errors;
          } else {
            this.errorMessages.push(error.error);
          }
        }
      });
    }
  }
  

  
// openProfileHeaderDialog(registerData: any) {
//  return this.dialog.open(ProfileRegisterHeaderComponent, {
 
//   data: registerData // Pass the register form data here
// });

// }

  updateErrorMessage() {
    const firstNameControl = this.registerForm.get('firstName');
    const lastNameControl = this.registerForm.get('lastName');
    const emailControl = this.registerForm.get('email');
    const passwordControl = this.registerForm.get('password');
    if (emailControl?.hasError('required')) {
      this.errorMessage = 'You must enter a value';
    } else if (emailControl?.hasError('email')) {
      this.errorMessage = 'Not a valid email';
    } else if (emailControl?.hasError('maxlength')) {
      this.errorMessage = 'cannot exceed 30 characters';
    } else if (passwordControl?.hasError('required')) {
      this.errorMessage = 'You must enter a password';
    } else if (passwordControl?.hasError('maxlength')) {
      this.errorMessage = 'password cannot be greater than 15 characters long';
    } else if (passwordControl?.hasError('minlength')) {
      this.errorMessage = 'password must be at least 6 characters long';
    } else if(passwordControl?.hasError('pattern')) {
      this.errorMessage = 'password must contain at least one non alphanumeric character';
    } else if (firstNameControl?.hasError('required')) {
      this.errorMessage = 'You must enter a password';
    } else if (firstNameControl?.hasError('maxlength')) {
      this.errorMessage = 'name cannot be greater than 15 characters long';
    } else if (lastNameControl?.hasError('required')) {
      this.errorMessage = 'You must enter a password';
    } else if (lastNameControl?.hasError('maxlength')) {
      this.errorMessage = 'name cannot be greater than 15 characters long';
    } else {
      this.errorMessage = '';
    }
  }
}
