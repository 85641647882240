import { Component, Inject, ChangeDetectionStrategy, inject, OnInit } from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';

import {ProfileHeaderComponent} from '../profilePageModals/profile-header/profile-header.component';
import {FileUploadService} from '../services/fileUploadService'
import {AddWorkModalComponent} from '../profilePageModals/add-work-modal/add-work-modal.component';

import {AddEducationModalComponent} from '../profilePageModals/add-education-modal/add-education-modal.component';
import { ProfileService } from '../services/profileService';
import {SharedService} from '../../../shared/shared.service'
import { ActivatedRoute, Router, RouterModule,NavigationEnd  } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';


import {
  MatDialog
 
} from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { ProfileHeader } from '../../../shared/account/profileHeader';
import { ServicesResult } from '../../../shared/account/ServicesResult';
import { About } from '../../../shared/account/about';
import { Job } from '../../../shared/account/job';
import { Education } from '../../../shared/account/education';
import { WorkExperiencePageComponent } from '../work-experience-page/work-experience-page.component';
import { ApplicationUser } from '../../../shared/account/applicationUser';

@Component({
  selector: 'app-read-only-profile',
  standalone: true,
  imports: [RouterModule,MatMenuModule,MatButtonModule,MatCardModule,MatIconModule,MatButtonModule,CommonModule],
  template: `


  <div  class="container mt-5">
  <div class="d-flex flex-column align-items-center">
    


    <!-- Card 1 -->
  
      <mat-card class="example-card col-12 col-md-10 col-lg-8 mb-3  rounded-lg shadow-lg" >
      <mat-card-header class="d-flex justify-content-between align-items-center">
      
      
 <div class="d-flex flex-column">
 <img [src]="profileHeader?.profilePic"   alt="Avatar" class="avatar my-3 mx-3">
          <mat-card-title>{{profileHeader?.firstName}}  {{profileHeader?.lastName}}</mat-card-title>
          <mat-card-subtitle>{{profileHeader?.banner}}</mat-card-subtitle>
        
          <h6 *ngIf="profileHeader?.linkedinURL" class="mt-3">LinkedIn: <a [href]="profileHeader?.linkedinURL" target="_blank">{{profileHeader?.linkedinURL}}</a>
</h6>
<h6 *ngIf="profileHeader?.personalWebsite" class="mt-1" >personal website: <a [href]="profileHeader?.personalWebsite" target="_blank">{{profileHeader?.personalWebsite}}</a>
</h6>
       

        <mat-card-title class="mt-3">About</mat-card-title>
        

    <p>
      {{profileHeader?.about}}
    </p>
  
          </div>



  </mat-card-header>
       
      </mat-card>
   



    

    <!-- Card 3 -->
    <mat-card class="example-card col-12 col-md-10 col-lg-8 mb-3 rounded-lg shadow-lg" >
    <mat-card-header class="d-flex justify-content-between align-items-center">
    <mat-card-title class=" h1">Experience</mat-card-title>
    
      
  </mat-card-header>
        <mat-card-content class="mt-5" *ngFor= "let job of jobs">
          <hr>
        <mat-card-title>{{job.positionTitle}} </mat-card-title>
        <mat-card-subtitle  >{{job.companyName}} </mat-card-subtitle>
       
          <mat-card-subtitle class="mb-3">{{job.startDateString}}-{{job.endDateString !== '' ? job.endDateString : 'present' }}</mat-card-subtitle >
         

          <p class="fw-normal">{{job.positionDescription}}</p>
  
        </mat-card-content>
      </mat-card>
    

    <!-- Card 4 -->
    
    <mat-card class="example-card col-12 col-md-10 col-lg-8 mb-3 rounded-lg shadow-lg">
    <mat-card-header class="d-flex justify-content-between align-items-center">
    <mat-card-title class="mb-3" >Education</mat-card-title>
    
      <!-- Container for icons aligned to the end -->
    
  </mat-card-header>
        <mat-card-content class="mt-3" *ngFor ="let education of educationList">
          <hr>
         <mat-card-title>{{education.institutionName}}</mat-card-title>
         <mat-card-subtitle >{{education.degreeType}}</mat-card-subtitle >
         <mat-card-subtitle class="mb-3">{{education.startDateString}}-{{education.endDateString !== '' ? education.endDateString : 'present' }}</mat-card-subtitle >
         <mat-card-subtitle >{{education.major}}</mat-card-subtitle >
         <mat-card-subtitle >{{education.minor}}</mat-card-subtitle >
         <p> graduated:{{education.graduated}}</p>
        </mat-card-content>
      </mat-card>
    </div>
    
  </div>




  `,
  styles: `

.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
 }
  .example-card {
    max-width: 400px;
  }
  .example-header-image {
  background-image: url('https://material.angular.io/assets/img/examples/shiba1.jpg');
  background-size: cover;
  }
  
`
})
export class ReadOnlyProfileComponent implements OnInit {
  profileHeader: ProfileHeader | null =null;
  about: About | null=null ;
  jobs: Job[] | null=null; 
  educationList: Education[] | null=null; 
  user: ApplicationUser | null = null;
  userId: string= '';

  readonly dialog = inject(MatDialog);


  constructor(private router: Router,private route: ActivatedRoute, private fileUploadService: FileUploadService, private profileService: ProfileService, private sharedService: SharedService,private cdr: ChangeDetectorRef){



  }

  async ngOnInit(): Promise<void> {
    try {
      // Wait for getUserData to complete
      this.userId = this.route.snapshot.paramMap.get('userId') ?? '';
      // Now initialize other data that depends on this.user
       this.initializeProfileHeader(this.userId);
       this.initializeJobs(this.userId);
       this.initializeEducation(this.userId);
      
   
    } catch (error) {
      console.error("Error loading user data:", error);
    }
  }
  





initializeProfileHeader(userId: string) {
  this.profileService.getProfileHeader(userId).subscribe({
    next: (profileHeader: ProfileHeader) => {  // Now expect ProfileHeader directly
      //console.log("Received ProfileHeader data:", profileHeader);  // Log the result object
        // Assign the received profileHeader to the component's profileHeader
        this.profileHeader = {
          firstName: profileHeader.firstName || '',  // Default if empty
          lastName: profileHeader.lastName || '',    // Default if empty
          banner: profileHeader.banner || '',                        // Default if empty
          linkedinURL: profileHeader.linkedinURL || '',              // Default if empty
          personalWebsite: profileHeader.personalWebsite || '' ,
           profilePicFile: null,
          profilePic: profileHeader.profilePic || '',
          about: profileHeader.about || '',
          applicationUserId: profileHeader.applicationUserId || '',
          isGoogleAccount: profileHeader.isGoogleAccount,
          googleProfilePic: profileHeader.googleProfilePic ,
          useGooglePic: profileHeader.useGooglePic
                // Default if empty
        };

        //console.log("asasasasasa",profileHeader.banner)
              // Trigger change detection manually
      this.cdr.markForCheck();
    },
    error: (error) => {
      console.error('Error fetching profile header:', error);
      // Handle error and set default values
      this.profileHeader = {
        firstName: '',
        lastName: '',
        banner: '',
        linkedinURL: '',
        personalWebsite: '',
        profilePicFile: null,
        profilePic: '',
        about:'',
        applicationUserId:'',
        isGoogleAccount: false,
        googleProfilePic:'',
        useGooglePic: false
      };
            // Trigger change detection manually
            this.cdr.markForCheck();
    },
    complete: () => {
      //console.log('Profile header retrieval completed');
    },
  });
}







initializeJobs(userId: string) {
  this.profileService.getJobs(userId).subscribe({
    next: (jobs: Job[]) => {  
     // console.log("Received about data:", jobs);  // Log the result object
      // Map through the received jobs array and add each job to the list
      this.jobs = jobs.map(job => ({
        positionTitle: job.positionTitle || '',  // Default if empty
        positionDescription: job.positionDescription || '',
        companyName: job.companyName || '',
        startDate: job.startDate || new Date('2024-09-17'),
        endDate: job.endDate || new Date('2024-09-17'),
        endDateString: job.endDateString || '',
        startDateString: job.startDateString || '',
        stillWorkHere: job.stillWorkHere || false,
        jobId: job.jobId 
      }));

       
              // Trigger change detection manually
      this.cdr.markForCheck();
    },
    error: (error) => {
      console.error('Error fetching job:', error);
      // Handle error and set default values
     // Set default job in case of error (or handle error as needed)
     this.jobs = [{
      positionTitle: '',
      positionDescription: '',
      companyName: '',
      startDate: new Date('2024-09-17'),
      endDate: new Date('2024-09-17'),
      stillWorkHere: false,
      startDateString: '',
      endDateString:'',
      jobId: 1
    }];
            // Trigger change detection manually
            this.cdr.markForCheck();
    },
    complete: () => {
     // console.log('job retrieval completed');
    },
  });
}



initializeEducation(userId: string) {
  this.profileService.getEducation(userId).subscribe({
    next: (educationList: Education[]) => {  
      //console.log("Received education data:", educationList);  // Log the result object
      // Map through the received jobs array and add each job to the list
      this.educationList = educationList.map(education => ({
        institutionName: education.institutionName || '',  // Default if empty
        major: education.major || '', 
        minor: education.minor || '', 
        startDate: education.startDate || new Date('2024-09-17'),
        endDate: education.endDate || new Date('2024-09-17'),
        graduated: education.graduated || false,
        startDateString: education.startDateString || '',
        endDateString: education.endDateString || '',
        educationId: education.educationId || 0
      }));

       
              // Trigger change detection manually
      this.cdr.markForCheck();
    },
    error: (error) => {
      console.error('Error fetching education list:', error);
      // Handle error and set default values
     // Set default job in case of error (or handle error as needed)
     this.educationList = [{
      institutionName: '',
      major: '',
      minor: '',
      startDate: new Date('2024-09-17'),
      endDate: new Date('2024-09-17'),
      graduated: false,
      startDateString: '',
      endDateString:'',
      educationId: 0
    }];
            // Trigger change detection manually
            this.cdr.markForCheck();
    },
    complete: () => {
      //console.log('job retrieval completed');
    },
  });
}




}
