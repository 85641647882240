import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, inject, ChangeDetectorRef } from '@angular/core';



import { ReplaySubject, map, of, Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { Job } from '../../../shared/account/job';
import { ServiceResult } from '../../../shared/account/ServiceResult';
import { Education } from '../../../shared/account/education';
import { Event } from '../../../shared/event/event';
import { ProfileHeader } from '../../../shared/account/profileHeader';

import { BehaviorSubject } from 'rxjs';
import { Event as EventDTO } from '../../../shared/event/event';
import { ServicesResult} from '../../../shared/account/ServicesResult';
import * as signalR from '@microsoft/signalr';

import { ApplicationUser } from '../../../shared/account/applicationUser';
import { DirectMessage } from '../../../shared/chat/directMessage';
import { MatDialog } from '@angular/material/dialog';
import { PrivateChatModalComponent } from '../../../chat/private-chat-modal/private-chat-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventComment } from '../../../shared/chat/eventComment';
import { environment } from '../../../../Environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ChatService {

  public eventChatConnection?: signalR.HubConnection;
  public privateChatConnection?: signalR.HubConnection;
  public onlineEventUsers: ProfileHeader[] = [];
  public onlinePrivateChatUsers: ProfileHeader[] = [];

  eventCommentsMap: { [eventId: number]: EventComment[] } = {};
  //eventComments: EventComment[] = [];
  currentEventId: number | null = null;


 public myUser: ProfileHeader | null=null;
 public directMessages: DirectMessage[]=[];
 privateMessages: DirectMessage[]=[];
 privateEventComments: EventComment[]=[];
 privateMessageInitiated = false;
 privateEventCommentInitiated = false;
 readonly dialog = inject(MatDialog);
 eventComments: EventComment[] = [];

  constructor(private http: HttpClient, private modalService: NgbModal,  private router: Router,private cookieService: CookieService,@Inject(PLATFORM_ID) private platformId: object) {
    
    
 }


 createEventChatConnection(userProfile: ProfileHeader, eventId?: number) {
  this.eventChatConnection = new signalR.HubConnectionBuilder()
  .configureLogging(signalR.LogLevel.Debug)  // add this for diagnostic clues
      .withUrl(`${environment.appUrl}/hubs/chat`, {
        skipNegotiation: true,  // skipNegotiation as we specify WebSockets
        transport: signalR.HttpTransportType.WebSockets  // force WebSocket transport
      })
      .withAutomaticReconnect()
      .build();

 // console.log("********************:");
  this.myUser = userProfile;
  //console.log("chat url:", `${environment.appUrl}/hubs/chat`);

  

  this.eventChatConnection.start().then(() => {
      console.log("Connected to SignalRrrrrrrrrrrrrr hub");

      // Once connected, join the event group by invoking the method on the server
      this.eventChatConnection!.invoke("JoinEventGroup", eventId)
          .then(() => console.log("Joined event group:"))
          .catch(error => console.error("Error joining group:", error));
  }).catch(error => {
      console.log("Connection error:");
  });

  // Listen for the UserConnected event
  this.eventChatConnection.on('UserConnected', () => {
      this.EventAddUserConnectionId(userProfile);
  });



    this.eventChatConnection.on('OnlineUsers', (onlineUsers: ProfileHeader[]) => {
        // Log the data to confirm it's an array
        console.log("Received onlineUsers from SignalR:", onlineUsers);
      
        // Directly assign the array to your component's onlineUsers variable
        this.onlineEventUsers = [...onlineUsers];

      
        // Log the updated onlineUsers array to confirm the change
       // console.log("Updated onlineUsers array:", this.onlineUsers);
      });



      this.eventChatConnection.on('NewEventComment', (eventComment: EventComment) => {
        const eventId = eventComment.eventId;
    
        // Ensure eventId is valid
        if (!eventId) {
            console.error("Received a NewEventComment without a valid eventId:", eventComment);
            return; // Exit if eventId is undefined
        }
    
        // Ensure there's an array for this event in the map
        if (!this.eventCommentsMap[eventId]) {
            this.eventCommentsMap[eventId] = [];
        }
    
        // Add the new comment to the correct array
        this.eventCommentsMap[eventId].push(eventComment);
    
        console.log(`New comment for event ${eventId}:`, eventComment);
        console.log('Updated eventCommentsMap after NewEventComment:', this.eventCommentsMap);
    });
    


      this.eventChatConnection.on('NewPrivateEventComment',(eventComment: EventComment) =>{

        // Log the data to confirm it's an array
        console.log("NewPrivateEventComment", eventComment);
      
        this.privateEventComments =[...this.privateEventComments, eventComment];

         // Log the updated onlineUsers array to confirm the change
        // console.log("directmessages [] from service 2:", this.eventComments);
         
      })



      this.eventChatConnection.on('CloseEventChat', (eventId: number) => {
        console.log(`CloseEventChat triggered for event ${eventId}`);
    
        // Clear the event comments for this eventId
        if (eventId && this.eventCommentsMap[eventId]) {
            delete this.eventCommentsMap[eventId];
            console.log(`Cleared comments for event ${eventId}:`, this.eventCommentsMap);
        }
    
        // Additional cleanup
        this.privateEventCommentInitiated = false;
        this.modalService.dismissAll();
    });
    
    
 }

 createPrivateChatConnection( senderId: string, receiverId: string) {
  // Initialize the SignalR connection
  this.privateChatConnection = new signalR.HubConnectionBuilder()
    .configureLogging(signalR.LogLevel.Debug) // Enable logging for debugging
    .withUrl(`${environment.appUrl}/hubs/chat`, {
      skipNegotiation: true, // Skip negotiation as WebSockets is directly used
      transport: signalR.HttpTransportType.WebSockets, // Use WebSocket transport
    })
    .withAutomaticReconnect() // Reconnect automatically if the connection is lost
    .build();




    this.privateChatConnection.start().then(() => {
      console.log("Connected to SignalR hub for private chat1111111111111111111111");
  
      // Add the user to the private chat group
      this.privateChatConnection!.invoke("CreatePrivateChat", senderId, receiverId)
        .then(() => console.log(`Private chat group created between ${senderId} and ${receiverId}`))
        .catch(error => console.error("Error creating private chat group:", error));


        console.log("Connected to SignalR hub for private chat222222222222222222222222");
      // Add the sender as a private chat user
      this.privateChatConnection!.invoke("AddPrivateUserConnectionId", this.myUser)
        .then(() => console.log("Sender added to private chat users."))
        .catch(error => console.error("Error adding private chat user:", error));
    }).catch(error => {
      console.error("Error starting private chat connection:", error);
    });
  
 

  // Handle SignalR events
  this.privateChatConnection.on("UserConnected", () => {
    this.DMAddUserConnectionId(this.myUser!);
  });

  this.privateChatConnection.on("OnlineUsers", (onlineUsers: ProfileHeader[]) => {
    console.log("Received online users for private chat:", onlineUsers);
    this.onlinePrivateChatUsers = [...onlineUsers];
  });

  this.privateChatConnection.on("NewDirectMessage", (directMessage: DirectMessage) => {
    console.log("Received a new direct message:", directMessage);
    this.directMessages = [...this.directMessages, directMessage];
  });

  this.privateChatConnection.on("NewPrivateMessage", (directMessage: DirectMessage) => {
    console.log("Received a new private message:", directMessage);
    this.privateMessages = [...this.privateMessages, directMessage];
  });

  this.privateChatConnection.on("ClosePrivateChat", () => {
    console.log("Private chat closed.");
    this.privateMessageInitiated = false;
    this.privateMessages = [];
    this.modalService.dismissAll();
  });
}



  // // Join the specified event group
  // joinEventGroup(eventId: number) {
  //   console.log(`Joining event group: ${eventId}`);
  //   return this.chatConnection?.invoke('JoinEventGroup', eventId)
  //     .catch(error => console.log(error));
  // }


 stopChatConnection(){
    this.eventChatConnection?.stop().catch(error=>console.log(error));
 }

async EventAddUserConnectionId(userProfile: ProfileHeader ){
    return this.eventChatConnection?.invoke('AddEventUserConnectionId', userProfile ).catch(error=> console.log(error));
}

async DMAddUserConnectionId(userProfile: ProfileHeader ){
  return this.privateChatConnection?.invoke('AddPrivateUserConnectionId', userProfile ).catch(error=> console.log(error));
}





// async openPrivateChat(senderId: string, receiverId: string): Promise<void> {
//   try {
//     await this.privateChatConnection?.invoke('CreatePrivateChat', senderId, receiverId);
//     console.log('CreatePrivateChat invoked successfully.');
//   } catch (error) {
//     console.error('Error invoking CreatePrivateChat:', error);
//   }
// }

async sendPrivateMessage(eventComment: EventComment, messageContent: string) {
  const formattedDateTime = new Date();
  const formattedLocalDate = formattedDateTime.toLocaleDateString();
  const formattedLocalTime = formattedDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  console.log("private message ----------------------e:", eventComment, messageContent);
  const directMessage: DirectMessage = {
    senderId: this.myUser?.applicationUserId || '',
    receiverId: eventComment.userId,
    messageContent,
    firstName: this.myUser?.firstName || '',
    lastName: this.myUser?.lastName || '',
    profilePic: this.myUser?.profilePic || '',
    banner: this.myUser?.banner || '',
    timestamp: formattedDateTime,
    date: formattedLocalDate,
    localTime: formattedLocalTime,
    isGoogleAccount: this.myUser?.isGoogleAccount || false,
    useGooglePic: this.myUser?.useGooglePic || false,
    googleProfilePic: this.myUser?.googleProfilePic || ''
  };

  console.log("Attempting to send message:", directMessage);



  return this.privateChatConnection?.invoke('ReceivePrivateMessage', directMessage)
      .then(() => {
        // Log success and directMessage after receiving
        console.log("Private direct messsge message received");
      })
      .catch(error => {
        // Log error and the directMessage
        console.log("Error receiving private dm message:############", error);
      });
  // Ensure the connection is active
  // if (this.privateChatConnection?.state !== signalR.HubConnectionState.Connected) {
  //   console.error("Private chat connection is not established. Cannot send message.");
  //   return;
  // }

  // try {
  //   if (!this.privateMessageInitiated) {
  //     this.privateMessageInitiated = true;

  // try {
  //     console.log("Creating private chat with:", directMessage);

  //     await this.privateChatConnection!.invoke('NewDirectMessage', directMessage);
  //     console.log("Private chat created. Message sent:", directMessage);

  //     this.privateMessages = [...this.privateMessages, directMessage];
  //   // } else {
  //   //   console.log("Sending private message:", directMessage);

  //   //   await this.privateChatConnection.invoke('ReceivePrivateMessage', directMessage);
  //   //   console.log("Private message received by server:", directMessage);
  //   // }
  // } catch (error) {
  //   console.error("Error in sendPrivateMessage:", error);
  // }
}



async sendEventComment(eventId: number, commentText: string) {
  const formattedDateTime = new Date();
  const formattedLocalDate = formattedDateTime.toLocaleDateString();
  const formattedLocalTime = formattedDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  // Construct EventComment object including profile header details
  const eventComment: EventComment = {
    userId: this.myUser?.applicationUserId || '',
    eventId: eventId,
    commentText: commentText,
    firstName: this.myUser?.firstName || '',
    lastName: this.myUser?.lastName || '',
    profilePic: this.myUser?.profilePic || '',
    timestamp: formattedDateTime,
    date: formattedLocalDate,
    localTime: formattedLocalTime,
    isGoogleAccount: this.myUser?.isGoogleAccount || false,
    useGooglePic: this.myUser?.useGooglePic || false,
    googleProfilePic: this.myUser?.googleProfilePic || ''
  };

 // console.log("Sending EventComment!!!!!!!!!!!!!!!!!:", eventComment);

  // // Send the message to the backend for processing and broadcasting to the event group
  // return this.chatConnection?.invoke('ReceiveEventMessage', eventComment)
  //   .then(() => {
  //     console.log("Event comment sent!!!!!!!!!!!!!!!!!:", eventComment);
  //     // Update the event comments array on the client to immediately display the new comment
  //     this.eventComments = [...this.eventComments, eventComment];
  //   })
  //   .catch(error => console.log("Error sending event commen!!!!!!!!!!!!!t:", error));
  // if (!this.privateEventCommentInitiated) {
  //   this.privateEventCommentInitiated = true;
  
  //   // Log before invoking 'CreatePrivateChat'
  //   console.log("Sending CreatePrivateChat with directMessage#####################:", eventComment);
  
  //   return this.chatConnection?.invoke('CreateEventChat', eventComment)
  //     .then(() => {
  //       // Log success and directMessage after creation
  //       console.log("Private chat created. Message sent#######################", eventComment);
        
  //       // Add message to private messages array
  //       this.eventComments = [...this.eventComments, eventComment];
  //     })
  //     .catch(error => {
  //       // Log error and the directMessage
  //       console.log("Error creating eventComment chat:################", error, "eventComment:", eventComment);
  //     });
  
  // } else {
    // Log before invoking 'ReceivePrivateMessage'
    //console.log("Sending ReceivePrivate  3#########eventCommentMessage with eventComment:", eventComment);
  
    return this.eventChatConnection?.invoke('ReceiveEventMessage', eventComment)
      .then(() => {
        // Log success and directMessage after receiving
        console.log("Private eventComment message received");
      })
      .catch(error => {
        // Log error and the directMessage
        console.log("Error receiving private eventComment message:############", error);
      });
  // }
}


async closePrivateChatMessage(otherUser:EventComment){
  return this.privateChatConnection?.invoke('RemovePrivateChat', this.myUser, otherUser ).catch(error=> console.log(error));

}


async closeEventChat(event: EventDTO): Promise<void> {
  console.log("Invoking RemoveEventGroup...");
  if (!this.eventChatConnection) {
      console.warn("SignalR connection is not initialized.");
      return;
  }

  if (this.eventChatConnection.state === signalR.HubConnectionState.Connected) {
      try {
          await this.eventChatConnection.invoke('RemoveEventGroup', event.eventId);
          console.log(`RemoveEventGroup invoked for eventId: ${event.eventId}`);
      } catch (error) {
          console.error("Error invoking RemoveEventGroup:", error);
          throw error; // Propagate the error
      }
  } else {
      console.warn("SignalR connection is not active.");
  }
}


getPrivateChatHistory(sender: ProfileHeader, receiver: EventComment): Observable<DirectMessage[]> {
  return this.http.get<ServicesResult<DirectMessage[]>>(`${environment.appUrl}/Chat/GetPrivateChatHistory?senderId=${sender.applicationUserId}&receiverId=${receiver.userId}`, { withCredentials: true })
    .pipe(
      map((response: ServicesResult<DirectMessage[]>) => {
        if (response.success && response.result) {
         // console.log('private chat hisotry received:', response.result);  // Log the profile data
          return response.result;  // Return the ProfileHeader object
        } else {
          throw new Error(response.message || 'Failed to get chat histlory');
        }
      }),
      catchError((error) => {
        console.error('proavtechat list:', error);
        return throwError(() => error);
      })
    );
}




getEventChatHistory(event: EventDTO): Observable<EventComment[]> {
  return this.http.get<ServicesResult<EventComment[]>>(`${environment.appUrl}/Chat/GetEventChatHistory?eventId=${event.eventId}`, { withCredentials: true })
    .pipe(
      map((response: ServicesResult<EventComment[]>) => {
        if (response.success && response.result) {
         // console.log('event chat hisotry received:', response.result);  // Log the profile data
          return response.result;  // Return the ProfileHeader object
        } else {
          throw new Error(response.message || 'Failed to get event chat histlory');
        }
      }),
      catchError((error) => {
        console.error(' event chat list:', error);
        return throwError(() => error);
      })
    );
}

}