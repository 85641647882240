import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout'; // Import Flex Layout Module
import {MatMenuModule} from '@angular/material/menu';


import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouterLink } from '@angular/router';
import { AccountService } from '../../Account/account.Service';

@Component({
  selector: 'app-navigation-bar',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    RouterLink
  ],
  template: `
    <mat-sidenav-container>
      <mat-sidenav #sidenav mode="over" opened="false" role= "navigation">
        <mat-nav-list>
          <!-- <a mat-list-item routerLink="/home" (click)="sidenav.close()" >
          <span class="nav-caption" >Home</span></a> -->
          <a mat-list-item routerLink="/register" (click)="sidenav.close()" *ngIf="(accountService.user$ | async)=== null">
          <span class="nav-caption" >join</span></a>
          <a mat-list-item routerLink="/login" (click)="sidenav.close()" *ngIf="(accountService.user$ | async)=== null">
          <span class="nav-caption">login</span></a>
          <a mat-list-item routerLink="/eventfeed" (click)="sidenav.close()" *ngIf="(accountService.user$ | async)!== null">
          <span class="nav-caption">Events</span></a>
          <div class="nav-caption" *ngIf="(accountService.user$ | async) as user">
       
       <button mat-button [matMenuTriggerFor]="menu">Profile</button>
<mat-menu #menu="matMenu">

<button mat-menu-item><a (click)="sidenav.close()" routerLink="/profile">profile</a></button>
<button mat-menu-item><a  (click)="sidenav.close()" routerLink="/myevents">my Events</a></button>

</mat-menu>

             </div> 
          <a mat-list-item (click)="logout(); sidenav.close()" *ngIf="(accountService.user$ | async)!== null">
          <span class="nav-caption">logout</span></a>
        </mat-nav-list>
      </mat-sidenav>

      <mat-sidenav-content>
        <mat-toolbar>
          <!-- Hamburger menu icon only visible on handset screens -->
          <button mat-icon-button (click)="sidenav.toggle()" *ngIf="isHandset$ | async">
            <mat-icon>menu</mat-icon>
          </button>

          <!-- Navigation items only visible on non-handset screens -->
          <div fxFlex fxLayout fxLayoutAlign="end" *ngIf="!(isHandset$ | async)">
            <ul fxLayout fxLayoutGap="15px" class="navigation-items">
              <!-- <li><a routerLink="/home">Home</a></li> -->
              <li *ngIf="(accountService.user$ | async)=== null"><a routerLink="/register">join</a></li>
              <li *ngIf="(accountService.user$ | async)=== null"><a routerLink="/login">login</a></li>
              <li *ngIf="(accountService.user$ | async)!== null"><a routerLink="/eventfeed">events</a></li>
              <div  *ngIf="(accountService.user$ | async) as user">
       
       <button mat-button [matMenuTriggerFor]="menu">Profile</button>
<mat-menu #menu="matMenu">

<button mat-menu-item><a routerLink="/profile">profile</a></button>
<button mat-menu-item><a routerLink="/myevents">my Events</a></button>

</mat-menu>

             </div> 
              <!-- <li *ngIf="(accountService.user$ | async)!== null"><a routerLink="/profile">profile</a></li> -->
              <li class="text-white" *ngIf="(accountService.user$ | async)!== null"><a class="logoutText"(click)="logout()">logout</a></li>
            </ul>
          </div>
          
        </mat-toolbar>
      </mat-sidenav-content>
    </mat-sidenav-container>
  `,
  styles: [`


    a {
      text-decoration: none;
      color: black;
      cursor: pointer;
    }
    
.logoutText:hover, logoutText:active {
  color: lightgray;

}

    a:hover, a:active {
      color: lightgray;
    }
    
    
    .nav-caption {
      display: inline-block;
      padding-left: 6px;
    }
    
    .navigation-items {
      list-style-type: none;
      padding: 0;
      margin: 0;
     
    }

// mat-sidenav-container,
// mat-sidenav-content {
//   height: 100%;
// }

mat-sidenav {
  width: 250px; /* Adjust the width as needed */
  height: 100vh; /* Full viewport height */
  position: fixed; /* Position the sidenav outside its parent */
  top: 0; /* Align with the top of the viewport */
  left: 0; /* Align with the left edge of the viewport */
  z-index: 1000; /* Ensure it appears above other elements */
  background-color: white; /* Optional: Add a background color */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for visual separation */
}

    
    mat-toolbar {
      border-radius: 3px;
      background-color: #C5050C;
      
    }
  `]
})
export class NavigationBarComponent {
  isHandset$: Observable<boolean>;

  constructor(private breakpointObserver: BreakpointObserver, public accountService: AccountService) {
    this.isHandset$ = this.breakpointObserver.observe([Breakpoints.Handset])
      .pipe(
        map(result => result.matches)
      );
  }

  logout(){
this.accountService.logout();
  };

  onSidenavClose() {
    // Logic to close the sidenav, if necessary
  }
}
