
import { Component, OnInit ,Inject, ViewChild, ElementRef,afterNextRender,AfterViewInit, ChangeDetectionStrategy} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { ValidationMessagesComponent } from '../../../../shared/errors/validation-messages/validation-messages.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {provideNativeDateAdapter} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { merge, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {ProfileService} from '../../services/profileService'
import {SharedService} from '../../../../shared/shared.service'
import { HttpErrorResponse } from '@angular/common/http';

import {
  MatDialogModule,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Education } from '../../../../shared/account/education';


@Component({
  selector: 'app-edit-education-modal',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule,MatFormFieldModule, MatInputModule, MatSelectModule,MatDatepickerModule,MatCheckboxModule,   CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    FormsModule,
    ValidationMessagesComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
<div class="container text-center ">
  <form [formGroup]="editEducationForm" (ngSubmit)="saveEducation()" autocomplete="off" id="editEducation">

  
<h2 mat-dialog-title>Education</h2>
<mat-dialog-content class="mat-typography">
<div class="col-12">
<mat-form-field appearance="fill" class="full-width mt-5">
  <mat-label>institution name</mat-label>
  <input matInput formControlName="institutionName" placeholder="ex:UW-Madison">
  <mat-error *ngIf="submitted && editEducationForm.get('institutionName')?.hasError('required')">
            institution name is required
          </mat-error>
          <mat-error *ngIf="submitted && editEducationForm.get('institutionName')?.hasError('maxlength')">
            Max length is 30 characters
          </mat-error>
</mat-form-field>
</div>
<div class="col-12">
<mat-form-field appearance="fill" class="full-width mt-1">
  <mat-label>Major</mat-label>
  <input matInput formControlName="major" placeholder="ex:Biology">
  <mat-error *ngIf="submitted && editEducationForm.get('major')?.hasError('required')">
            major  is required
          </mat-error>
          <mat-error *ngIf="submitted && editEducationForm.get('major')?.hasError('maxlength')">
            Max length is 30 characters
          </mat-error>
</mat-form-field>

</div>
<div class="col-12">
<mat-form-field appearance="fill" class="full-width mt-1">
  <mat-label>Minor</mat-label>
  <input matInput formControlName="minor" placeholder="ex:English literature">

          <mat-error *ngIf="submitted && editEducationForm.get('minor')?.hasError('maxlength')">
            Max length is 30 characters
          </mat-error>
</mat-form-field>

</div>

<div class="col-12">
<mat-form-field appearance="fill" class="full-width mt-1">
  <mat-label>Degree Type</mat-label>
  <input matInput formControlName="degreeType" placeholder="ex:Bachelor of Science">

          <mat-error *ngIf="submitted && editEducationForm.get('degreeType')?.hasError('maxlength')">
            Max length is 30 characters
          </mat-error>
</mat-form-field>

</div>

<div class="col-12">
<mat-checkbox formControlName="graduated" >did you graduate?</mat-checkbox>

  </div>




<div class="row g-3">

<div class="col-12 col-md-6">
<mat-form-field class="mt-1">
  <mat-label>Start Date</mat-label>
  <input matInput formControlName="startDate" [matDatepicker]="EducationStartDate">
  <mat-error *ngIf="submitted && editEducationForm.get('startDate')?.hasError('required')">
        start date is required
          </mat-error>
          <mat-error *ngIf="submitted && editEducationForm.get('startDate')?.hasError('maxlength')">
            Max length is 30 characters
          </mat-error>
  <mat-hint>MM/DD/YYYY</mat-hint>
  <mat-datepicker-toggle matIconSuffix [for]="EducationStartDate"></mat-datepicker-toggle>
  <mat-datepicker #EducationStartDate></mat-datepicker>
</mat-form-field>
</div>

<div class="col-12 col-md-6">
<mat-form-field class="mt-1">
  <mat-label>End Date</mat-label>
  <input matInput formControlName="endDate" [matDatepicker]="EducationEndDate">
  <mat-error *ngIf="submitted && editEducationForm.get('endDate')?.hasError('required')">
        end date is required
          </mat-error>
          <mat-error *ngIf="submitted && editEducationForm.get('endDate')?.hasError('maxlength')">
            Max length is 30 characters
          </mat-error>
  <mat-hint>MM/DD/YYYY</mat-hint>
  <mat-datepicker-toggle matIconSuffix [for]="EducationEndDate"></mat-datepicker-toggle>
  <mat-datepicker #EducationEndDate></mat-datepicker>
</mat-form-field>
</div>


</div>
  </mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button  cdkFocusInitial  type="submit">Save</button>
</mat-dialog-actions>
 
  </form>


  `,
  styles: `.full-width {
    width: 100%; /* Ensures the field spans the full container width */
  }`
})
export class EditEducationModalComponent implements OnInit{

 
  editEducationForm: FormGroup = new FormGroup({
    // stillWorkHere: new FormControl(false) // Default value: unchecked
  });


  submitted = false;
  errorMessages: string[] = [];
  errorMessage = '';
  
  returnUrl: string | null = null;

  constructor(
   
    @Inject(MAT_DIALOG_DATA) public education:Education,
    private dialogRef: MatDialogRef<EditEducationModalComponent>,
     private profileService: ProfileService,  // Inject ProfileService here
  private sharedService: SharedService  ,   // Inject SharedService here
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {  
    
    
    this.editEducationForm = this.formBuilder.group({
    educationId: [education.educationId],  // Hidden, not displayed in the form
    institutionName: new FormControl('', [ Validators.required,Validators.maxLength(30)]),
    major: new FormControl('', [ Validators.maxLength(30)]),
    minor: new FormControl('', [ Validators.maxLength(30)]),
    degreeType: new FormControl('', [ Validators.maxLength(30)]),
    graduated: new FormControl(false),
    startDate: new FormControl('', [Validators.required, Validators.maxLength(15)]),
    endDate: new FormControl('', [Validators.required, Validators.maxLength(15)])
    
  });



  merge(
    this.editEducationForm.get('institutionName')!.statusChanges!,
    this.editEducationForm.get('institutionName')!.valueChanges!,
    this.editEducationForm.get('major')!.statusChanges!,
    this.editEducationForm.get('major')!.valueChanges!,
    this.editEducationForm.get('minor')!.statusChanges!,
    this.editEducationForm.get('minor')!.valueChanges!,
    this.editEducationForm.get('degreeType')!.statusChanges!,
    this.editEducationForm.get('degreeType')!.valueChanges!,
    this.editEducationForm.get('startDate')!.statusChanges!,
    this.editEducationForm.get('startDate')!.valueChanges!,
    this.editEducationForm.get('endDate')!.statusChanges!,
    this.editEducationForm.get('endDate')!.valueChanges!,
    this.editEducationForm.get('graduated')!.statusChanges!,
    this.editEducationForm.get('graduated')!.valueChanges!
  
  )
    .pipe(takeUntilDestroyed())
    .subscribe(() => this.updateErrorMessage());
  
  }







ngOnInit(): void {
  this.initializeForm();
      // Listen for changes in the isChecked checkbox
      this.editEducationForm.get('graduated')?.valueChanges.subscribe((graduated: boolean) => {
        this.toggleEndDateValidator(graduated);
      });
  
    
  };

  
  
initializeForm() {
  this.editEducationForm = this.formBuilder.group({
    educationId: [this.education.educationId],  // Hidden so is inlcuded in model for use in backend, not displayed in the form
    institutionName: [this.education.institutionName, [Validators.required, Validators.maxLength(30)]],
    major: [this.education.major, [ Validators.maxLength(30)]],
    minor: [this.education.minor, [ Validators.maxLength(30)]],
    degreeType: [this.education.degreeType, [ Validators.maxLength(30)]],
    graduated: [this.education.graduated],
    startDate: [this.education.startDate, [Validators.required, Validators.maxLength(30)]],
    endDate: [this.education.endDate, [Validators.required, Validators.maxLength(30)]],
  });
}




toggleEndDateValidator(graduated: boolean) {
  const endDateControl = this.editEducationForm.get('endDate');

  if (graduated) {
    // If "I still work here" is checked, remove the required validator for End Date
    endDateControl?.clearValidators();
  } else {
    // If unchecked, add the required validator back to End Date
    endDateControl?.setValidators([Validators.required, Validators.maxLength(15)]);
  }
  // Update the validity of the endDate field after changing the validators
  endDateControl?.updateValueAndValidity();
}

saveEducation() {
  this.submitted = true;
  this.errorMessages = [];

  if (this.editEducationForm.valid) {
    this.profileService.editEducationExperience(this.editEducationForm.value).subscribe({
      next: (response: any) => {
       // console.log(":response!!!!!!!!!!!!:", response);
        if (response.success) {
          this.sharedService.showNotification(response.success, response.title, response.message);
          this.dialogRef.close();
          //console.log(":response@@@@@@@", response);
          
        } else {
          this.sharedService.showNotification(response.success, response.title, response.message);
         // console.log(":response&&&&&&&&:", response);
        }
      },
      error: (error: HttpErrorResponse) => {
        // Log the entire error object for debugging
        console.error('Error in adding work experience:', error);
        //console.log(":response&&&&&&&&:");

        // Handle the error gracefully and display to the user
        this.sharedService.showNotification(false, 'Error', error.message || 'Unknown error occurred');
      }
    });
  }else{
    //console.log("invalid form");
   // console.log(this.editEducationForm.value);
    this.focusFirstInvalidControl();
  }
}


focusFirstInvalidControl() {
  let form = document.getElementById('editEducation'); // <-- your formID
  if(form){
    let firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
    firstInvalidControl.scrollIntoView();
    (firstInvalidControl as HTMLElement).focus();
  }
  
}

updateErrorMessage() {
  const institutionNameControl = this.editEducationForm.get('institutionName');
  const majorControl = this.editEducationForm.get('major');
  const minorControl = this.editEducationForm.get('minor');
  const degreeTypeControl = this.editEducationForm.get('degreeType');
  const startDateControl = this.editEducationForm.get('startDate');
  const endDateControl = this.editEducationForm.get('endDate');

  if (institutionNameControl?.hasError('required')) {
    this.errorMessage = 'You must enter a position';
  } else if (institutionNameControl?.hasError('maxlength')) {
    this.errorMessage = 'exceeds max character length';
  } else if (majorControl?.hasError('maxLength')) {
    this.errorMessage = 'exceeds max character length';
  } else if(minorControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  }else if(degreeTypeControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  }else if(startDateControl?.hasError('required')){
    this.errorMessage = 'you must enter a start date';
  }else if(startDateControl?.hasError('maxlength')){
    this.errorMessage = 'exceeds max character length';
  }else if(endDateControl?.hasError('required')){
    this.errorMessage = 'you must enter an end date';
  }else if(endDateControl?.hasError('maxLength')){
    this.errorMessage = 'exceeds max character length';
  } else{
    this.errorMessage = '';
  }
}


}
