import { Component } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';

import { RouterLink } from '@angular/router';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [MatToolbarModule,RouterLink],
  template: `
   <mat-toolbar>
    <span><a routerLink="/home">Network App</a></span>
  </mat-toolbar>
  `,
  styles: ``
})
export class HeaderComponent {

}
