import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient,  withInterceptors ,withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { SpinnerInterceptor } from './shared/interceptors/SpinnerInterceptor';
// import { jwtInterceptor } from './shared/interceptors/jwt.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()), // Include interceptors from DI bc its the way to do it for standalone angular
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    provideClientHydration(),
   // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true } // Register interceptor separately
  ]
  
};

