import { Routes } from '@angular/router';
import {LoginComponent } from './Account/login/login.component'
import {ProfileComponent } from './Account/profile/profile.component'
import {RegisterComponent } from './Account/Register/register.component'

import {HomePageComponent } from './homepage/home-page.component'
import { AuthorizationGuard } from './shared/guards/authorization.guard';
import { EventsPageComponent } from './eventsPage/events-page.component';
import {WorkExperiencePageComponent} from './Account/profile/work-experience-page/work-experience-page.component'
import { EducationPageExperienceComponent } from './Account/profile/education-experience-page/education-experience-page.component';
import { EventComponent } from './eventsPage/event/event.component';
import { MyEventsComponent } from './Account/profile/myEvents/my-events/my-events.component';
import { ProfileRegisterHeaderComponent } from './Account/profile/profilePageModals/profile-header-register/profile-header-register.component';
import { ReadOnlyProfileComponent } from './Account/profile/read-only-profile/read-only-profile.component';


export const routes: Routes = [

    { path: 'home', component: HomePageComponent },
    // {path: '',
    //     runGuardsAndResolvers: "always",
    //     canActivate: [AuthorizationGuard],
    //     children: [
    //         {path: 'Events', component: EventsPageComponent}
    //     ]
    // },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent},
       {path: 'profileSetup', component: ProfileRegisterHeaderComponent},
       {path: 'view-profile/:userId', component: ReadOnlyProfileComponent},
    
    { path: 'eventfeed', component: EventsPageComponent},
     { path: 'myevents', component: MyEventsComponent },
    //{ path: 'register-google', component: RegisterComponent }
    {path: 'profile', component: ProfileComponent,
        children:[{path: 'workExperience', component: WorkExperiencePageComponent},
            {path: 'educationExperience', component: EducationPageExperienceComponent}
        ]
    },
    
];
