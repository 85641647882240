import { AbstractControl, ValidatorFn } from '@angular/forms';

export function fileValidator(allowedTypes: string[], maxSizeInMB: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const file = control.value;

    if (file) {
       // console.log("maxsize:", maxSizeInMB);
        //console.log("filetypess:", allowedTypes);
        //console.log("file:", file);
      const fileType = file.type;
     //console.log("filetype",fileType);
      const fileSize = file.size;

      const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // Convert MB to Bytes

      // Check if file type is allowed and if size is within limit
      const validType = allowedTypes.includes(fileType);
      const validSize = fileSize <= maxSizeInBytes;

      if (!validType) {
        return { invalidFileType: true };
      }

      if (!validSize) {
        return { fileSizeExceeded: true };
      }
    }
    return null;
  };
}
