import { Component, OnInit ,Inject,  ViewChild, ElementRef,afterNextRender,AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../account.Service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { ValidationMessagesComponent } from '../../shared/errors/validation-messages/validation-messages.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { merge, take } from 'rxjs';
import { SharedService } from '../../shared/shared.service';
import { User } from '../../shared/account/user';
import { AuthResponse } from '../../shared/account/AuthResponse';
import { environment } from '../../../Environments/environment';
declare let google: any;
@Component({
  selector: 'app-login',
  standalone: true,
  template: `
    <div class="d-flex vh-100 justify-content-center align-items-center">
      <mat-card class="form-signin p-4 col-12 col-md-6 col-lg-3 mt-5  rounded-lg shadow-lg">
        <form [formGroup]="loginForm" (ngSubmit)="login()" autocomplete="off">
          <h3 class="text-center pt-3 fw-normal">Login</h3>
          <div class="form-group">
          <mat-form-field class="full-width pt-2 px-3" appearance="fill">
            <mat-label> email address</mat-label>
            <input matInput formControlName="userName" placeholder="user@email.com">
            <mat-error *ngIf="submitted && loginForm.get('userName')?.hasError('required')">
              Email is required
            </mat-error>
            <mat-error *ngIf="submitted && loginForm.get('userName')?.hasError('email')">
              Not a valid email
            </mat-error>
          </mat-form-field>

          <mat-form-field class="full-width pt-3 px-3" appearance="fill">
            <mat-label>Password</mat-label>
            <input matInput type="password" formControlName="password"  [type]="hide ? 'password' : 'text'"  placeholder="Password">
            <button type = "button" mat-button matSuffix (click)="hide = !hide" class="mx-3">{{hide ? 'show' : 'hide'}}</button>
            <mat-error *ngIf="submitted && loginForm.get('password')?.hasError('required')">
              Password is required
            </mat-error>
          </mat-form-field>

          <div *ngIf="errorMessages.length > 0">
            <app-validation-messages [errorMessages]="errorMessages"></app-validation-messages>
          </div> 

          <div class="d-grid  pt-3 px-5">
            <button mat-flat-button color="primary" class="rounded-pill py-4" type="submit">Log in</button>
          </div>
          </div>
        </form>

       
        <!-- Google Sign-In Section -->
     <section class="text-center mt-3">
         <div id="g_id_onload"
    [attr.data-client_id]="clientID"
     data-context="signin"
     data-ux_mode="popup"
     data-auto_select="true"
     data-itp_support="true"
     data-use_fedcm_for_prompt="true"
     data-callback="handleGoogleSignIn">
    
</div>

<div class="g_id_signin d-flex justify-content-center"
     data-type="standard"
     data-shape="rectangular"
     data-theme="outline"
     data-text="signin_with"
     data-size="large"
     data-logo_alignment="left">
</div> 
    </section> 
    <!-- <div class="mt-4 text-center">
          <a class="btn btn-link">
            <h6>Forgot username or password</h6>
          </a>
        </div> -->
      </mat-card>
    </div>
  `,
  styles: [`
    .full-width {
      width: 100%;
    }
  `],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    FormsModule,
    ValidationMessagesComponent
  ]
})
export class LoginComponent implements OnInit {
  //@ViewChild('googlescriptDiv') scriptElementRef!: ElementRef;

  loginUri!: string;
  clientID!: string;
  loginForm: FormGroup = new FormGroup({});
  submitted = false;
  errorMessages: string[] = [];
  errorMessage = '';
  hide = true;
  returnUrl: string | null = null;


  constructor(
   
    private accountService: AccountService,
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    
  
    //console.log('FormBuilder:', formBuilder);
    

    this.accountService.user$.pipe(take(1)).subscribe({
      next: (authResponse: AuthResponse | null) => {
        if (authResponse) {
          console.log(authResponse)
          this.router.navigateByUrl('/');
        } else {
          this.activatedRoute.queryParamMap.subscribe({
            next: (params: any) => {
              if (params) {
                this.returnUrl = params.get('returnUrl');
              }
            }
          })
        }
      }
    })

    this.loginForm = this.formBuilder.group({
      userName: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });

    merge(
      this.loginForm.get('userName')!.statusChanges!,
      this.loginForm.get('userName')!.valueChanges!,
      this.loginForm.get('password')!.statusChanges!,
      this.loginForm.get('password')!.valueChanges!
    )
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateErrorMessage());
  }

  // Use ngAfterViewInit to ensure the view is fully initialized
  ngAfterViewInit(): void {

   
     
    
       
  }

  ngOnInit(): void {
    this.initializeForm();
   console.log("environment login:", environment)

      // script.src = 'https://accounts.google.com/gsi/client';
       // Dynamically set the login URI based on the environment
       //console.log("environment appurl:", environment.appUrl)
    this.loginUri = `${environment.appUrl}/Account/login-google`;
   // console.log("environment uri:", this.loginUri)
    this.clientID = `${environment.clientID}`;

    console.log("in browser");
    // console.log('scriptElementRef:', this.scriptElementRef);  // Check if scriptElementRef is set
     const script = document.createElement('script');
     script.src = 'https://accounts.google.com/gsi/client';
     script.async = true;
     script.defer = true;
     document.head.appendChild(script);  // Add script to the head or any other appropriate locatio
   
    // Expose the handleGoogleSignIn method globally
    (window as any).handleGoogleSignIn = (response: any) => this.handleGoogleSignIn(response.credential);
      
    };

   


  initializeForm() {
    this.loginForm = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }


  handleGoogleSignIn(credential: string ) {
    console.log("hit handlegooglesignin in login comp");
    console.log("credential in googlesign",credential );
    this.accountService.handleGoogleSignIn(credential).subscribe({
      next: (response: AuthResponse) => {
        console.log(response, "*****************response in handlegoogleregisterin login comp");
        //console.log("frintdn login test::", response);
        //console.log("frintdn fewfwe test::", response.success);
        if (!response.isAuthenticated || !response.success) {
          // Handle unsuccessful response and errors
          this.sharedService.showNotification(response.success, response.title, response.message);
      
        } else
        if (!response.profileCompleted) {
         // console.log("profile complete is false", response.profileCompleted);
          // Redirect to profile setup if profile is incomplete
          this.router.navigateByUrl('/profileSetup');
        } else if (response.isAuthenticated && response.success) {

          //console.log("success and auth");
          // Redirect to main page if authenticated, successful, and profile completed
          this.router.navigateByUrl('/eventfeed');
        } 
      },
      error: (error) => {
        console.log('Unexpected error:', error);
        this.errorMessages.push('Unexpected error occurred.');
      }

    })

    
}

  login() {
    this.submitted = true;
    this.errorMessages = [];
  
    if (this.loginForm.valid) {
      this.accountService.login(this.loginForm.value).subscribe({
        next: (response: AuthResponse) => {
          //console.log("frintdn login test::", response);
          //console.log("frintdn fewfwe test::", response.success);
          if (!response.profileCompleted) {
           // console.log("profile complete is false", response.profileCompleted);
            // Redirect to profile setup if profile is incomplete
            this.router.navigateByUrl('/profileSetup');
          } else if (response.isAuthenticated && response.success) {

            //console.log("success and auth");
            // Redirect to main page if authenticated, successful, and profile completed
            this.router.navigateByUrl('/eventfeed');
          } else if (!response.isAuthenticated || !response.success) {
            // Handle unsuccessful response and errors
            this.sharedService.showNotification(response.success, response.title, response.message);
           // console.log("not succes or not auth", response.success);
            // if (response.error) {
            //   console.log("newcode err:", response.error);
            //   console.log('stringified:', response.error);
            //   this.errorMessages.push(response.error);
            // } else {
            //   console.log("unknown err:");
            //   this.errorMessages.push('Unknown error occurred.');
            // }
          }
        },
        error: (error) => {
          console.log('Unexpected error:', error);
          this.errorMessages.push('Unexpected error occurred.');
        }
      });
    }
  }
  


  updateErrorMessage() {
    const userNameControl = this.loginForm.get('userName');
    const passwordControl = this.loginForm.get('password');
    if (userNameControl?.hasError('required')) {
      this.errorMessage = 'You must enter a value';
    } else if (userNameControl?.hasError('email')) {
      this.errorMessage = 'Not a valid email';
    } else if (passwordControl?.hasError('required')) {
      this.errorMessage = 'You must enter a password';
    } else {
      this.errorMessage = '';
    }
  }
}
