import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  template: `
 <footer class= "footer">
 
  </footer>

  `,
  styles:
   `.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    background-color: #cccccc;
    bottom: 0;
    width: 100vw;
    position: fixed;
    height: 50px;
  }
  
  .footer fa-icon {
    color: black;
    text-decoration: none;
    margin: 0 40px;
    font-size: 25px;
  }`
})
export class FooterComponent {

}
