import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable,  inject, } from '@angular/core';

import { environment } from '../../../../Environments/environment';

import { ReplaySubject, map, of, Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { Job } from '../../../shared/account/job';
import { ServiceResult } from '../../../shared/account/ServiceResult';
import { Education } from '../../../shared/account/education';
import { About } from '../../../shared/account/about';
import { ProfileHeader } from '../../../shared/account/profileHeader';

import { ServicesResult} from '../../../shared/account/ServicesResult';
import { ApplicationUser } from '../../../shared/account/applicationUser';

import { Event as EventDTO } from '../../../shared/event/event';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {

 public user:ApplicationUser= { id: '',
  userName: '',
  email: ''};


  

  constructor(private http: HttpClient, private router: Router,private cookieService: CookieService) {
    
    
 }



 addWorkExperience(model: Job) {

  // Conditionally handle endDate based on the isChecked flag
  if (model.stillWorkHere) {
    model.endDate = null;  // Set to null for ongoing jobs
  }
    return this.http.post<ServiceResult>(`${environment.appUrl}/Profile/AddExperience`, model, { withCredentials: true })
      .pipe(
        map((response: ServiceResult) => {
          if (response.success) {
           // console.log("Work experience added successfully:", JSON.stringify(response, null, 2));
            this.router.navigateByUrl('/profile'); // Navigate to the home page
          } else {
            console.log('Failed to add work experience:');
          }
          return response;
        }),
        catchError((error) => {
          console.error("Error in adding work experience hereherhe:", error);
          const errorResponse: ServiceResult = {
            title : error.error.title,
            success: false,
            message: error.error.message || 'Unknown error'
          };
          return of(errorResponse);
        })
      );
  }

  



  
  editEducationExperience(model: Education) {

    // Conditionally handle endDate based on the isChecked flag
    if (model.graduated) {
      model.endDate = null;  // Set to null for ongoing jobs
    }

      // Log the model data to check the fields
  //console.log("education Model data before sending to backend:", model);
      return this.http.put<ServiceResult>(`${environment.appUrl}/Profile/editEducationExperience`, model, { withCredentials: true })
        .pipe(
          map((response: ServiceResult) => {
            if (response.success) {
              //console.log("education edited:", JSON.stringify(response, null, 2));
              this.router.navigateByUrl('/profile'); // Navigate to the home page
            } else {
              console.log('Failed to EDIT education  experience:');
            }
            return response;
          }),
          catchError((error) => {
            console.error("Error in editing eduvation experience :", error);
            const errorResponse: ServiceResult = {
              title : error.error.title,
              success: false,
              message: error.error.message || 'Unknown error'
            };
            return of(errorResponse);
          })
        );
    }



    
  editWorkExperience(model: Job) {

    // Conditionally handle endDate based on the isChecked flag
    if (model.stillWorkHere) {
      model.endDate = null;  // Set to null for ongoing jobs
    }

      // Log the model data to check the fields
  //console.log("Model data before sending to backend:", model);
      return this.http.put<ServiceResult>(`${environment.appUrl}/Profile/editWorkExperience`, model, { withCredentials: true })
        .pipe(
          map((response: ServiceResult) => {
            if (response.success) {
              //console.log("Work edited:", JSON.stringify(response, null, 2));
              this.router.navigateByUrl('/profile'); // Navigate to the home page
            } else {
              console.log('Failed to EDIT work experience:');
            }
            return response;
          }),
          catchError((error) => {
            console.error("Error in editing work experience hereherhe:", error);
            const errorResponse: ServiceResult = {
              title : error.error.title,
              success: false,
              message: error.error.message || 'Unknown error'
            };
            return of(errorResponse);
          })
        );
    }


    deleteWorkExperience(model: Job) {
        // Log the model data to check the fields
    //console.log("work model to delete:", model);
        return this.http.delete<ServiceResult>(`${environment.appUrl}/Profile/DeleteJob`, { withCredentials: true, body:model })
          .pipe(
            map((response: ServiceResult) => {
              if (response.success) {
                console.log("Work deelted:");
                //this.router.navigateByUrl('/profile'); // Navigate to the home page
              } else {
                console.log('Failed to delete work experience:');
              }
              return response;
            }),
            catchError((error) => {
              console.error("Error in deleting work experience hereherhe:", error);
              const errorResponse: ServiceResult = {
                title : error.error.title,
                success: false,
                message: error.error.message || 'Unknown error'
              };
              return of(errorResponse);
            })
          );
      }


      
    deleteEducation(model: Education) {
      // Log the model data to check the fields
  //console.log("education model to delete:", model);
      return this.http.delete<ServiceResult>(`${environment.appUrl}/Profile/DeleteEducation`, { withCredentials: true, body:model })
        .pipe(
          map((response: ServiceResult) => {
            if (response.success) {
              console.log("education deelted:");
              //this.router.navigateByUrl('/profile'); // Navigate to the home page
            } else {
              console.log('Failed to  delete education experience:');
            }
            return response;
          }),
          catchError((error) => {
            console.error("Error in deleting work education hereherhe:", error);
            const errorResponse: ServiceResult = {
              title : error.error.title,
              success: false,
              message: error.error.message || 'Unknown error'
            };
            return of(errorResponse);
          })
        );
    }


        
    deleteEvent(model: EventDTO) {
      // Log the model data to check the fields
  //console.log("evengt model to delete:", model);
      return this.http.delete<ServiceResult>(`${environment.appUrl}/Event/DeleteEvent`, { withCredentials: true, body:model })
        .pipe(
          map((response: ServiceResult) => {
            if (response.success) {
              console.log("event deelted:");
              //this.router.navigateByUrl('/profile'); // Navigate to the home page
            } else {
              console.log('Failed to  delete event experience:');
            }
            return response;
          }),
          catchError((error) => {
            console.error("Error in deleting event hereherhe:", error);
            const errorResponse: ServiceResult = {
              title : error.error.title,
              success: false,
              message: error.error.message || 'Unknown error'
            };
            return of(errorResponse);
          })
        );
    }


  


  
 addEducation(model: Education) {

  
    return this.http.post<ServiceResult>(`${environment.appUrl}/Profile/AddEducation`, model, { withCredentials: true })
      .pipe(
        map((response: ServiceResult) => {
          if (response.success) {
           // console.log("Education added successfully:", JSON.stringify(response, null, 2));
            this.router.navigateByUrl('/profile'); // Navigate to the home page
          } else {
            console.log('Failed to add education:');
          }
          return response;
        }),
        catchError((error) => {
          console.error("Error in adding education:", error);
          const errorResponse: ServiceResult = {
            title : error.error.title,
            success: false,
            message: error.error.message || 'Unknown error'
          };
          return of(errorResponse);
        })
      );
  }


    
//  addAbout(model: About) {

  
//   return this.http.post<ServiceResult>(`/api/Profile/AddAbout`, model, { withCredentials: true })
//     .pipe(
//       map((response: ServiceResult) => {
//         if (response.success) {
//           console.log("About added successfully:", JSON.stringify(response, null, 2));
//           this.router.navigateByUrl('/profile'); // Navigate to the home page
//         } else {
//           console.log('Failed to add about:', response);
//         }
//         return response;
//       }),
//       catchError((error) => {
//         console.error("Error in adding about:", error);
//         const errorResponse: ServiceResult = {
//           title : error.error.title,
//           success: false,
//           message: error.error.message || 'Unknown error'
//         };
//         return of(errorResponse);
//       })
//     );
// }


    
addProfileHeader(formData: FormData) {

  
  return this.http.post<ServiceResult>(`${environment.appUrl}/Profile/AddProfileHeader`, formData, { withCredentials: true })
    .pipe(
      map((response: ServiceResult) => {
        if (response.success) {
         // console.log("profile header added successfully:", JSON.stringify(response, null, 2));
          this.router.navigateByUrl('/profile'); // Navigate to the home page
        } else {
          console.log('Failed to add about:');
        }
        return response;
      }),
      catchError((error) => {
        console.error("Error in adding about:", error);
        const errorResponse: ServiceResult = {
          title : error.error.title,
          success: false,
          message: error.error.message || 'Unknown error'
        };
        return of(errorResponse);
      })
    );
}


    
AddPersonalInfoRegister(formData: FormData) {

  
  return this.http.post<ServiceResult>(`${environment.appUrl}/Profile/AddPersonalInfoRegister`, formData, { withCredentials: true })
    .pipe(
      map((response: ServiceResult) => {
        if (response.success) {
          console.log("AddPersonalInfoRegister header added successfully:");
          //this.router.navigateByUrl('/profile'); // Navigate to the home page
        } else {
          console.log('Failed to add AddPersonalInfoRegister:');
        }
        return response;
      }),
      catchError((error) => {
        console.error("Error in adding AddPersonalInfoRegister:", error);
        const errorResponse: ServiceResult = {
          title : error.error.title,
          success: false,
          message: error.error.message || 'Unknown error'
        };
        return of(errorResponse);
      })
    );
}



getProfileHeader(userId): Observable<ProfileHeader> {
  return this.http.get<ServicesResult<ProfileHeader>>(`${environment.appUrl}/Profile/GetProfileHeader?userId=${userId}`, { withCredentials: true })
    .pipe(
      map((response: ServicesResult<ProfileHeader>) => {
       // console.log('$$$$$$$$$$$$$$$$:', userId);  // Log the profile data
        if (response.success && response.result) {
         // console.log('Profile header received:', response.result.firstName);  // Log the profile data
          return response.result;  // Return the ProfileHeader object
        } else {
          throw new Error(response.message || 'Failed to get profile header');
        }
      }),
      catchError((error) => {
        console.error('Error in getting profile header:', error);
        return throwError(() => error);
      })
    );
}

getUser(): Observable<ApplicationUser> {
  return this.http.get<ServicesResult<ApplicationUser>>(`${environment.appUrl}/Profile/GetUser`, { withCredentials: true })
    .pipe(
      map((response: ServicesResult<ApplicationUser>) => {
        //console.log('applicationus:', response.result);  // Log the profile data
        if (response.success && response.result) {
          this.user= response.result;
         // console.log('applicationuser:', response.result);  // Log the profile data
          return response.result;  // Return the ProfileHeader object
        } else {
          throw new Error(response.message || 'Failed to get applicationuser');
        }
      }),
      catchError((error) => {
        console.error('Error in getting applicationuser:', error);
        return throwError(() => error);
      })
    );
}


// getAbout(): Observable<About> {
//   return this.http.get<ServicesResult<About>>(`/api/Profile/GetAbout`, { withCredentials: true })
//     .pipe(
//       map((response: ServicesResult<About>) => {
//         if (response.success && response.result) {
//           console.log('About received:', response.result);  // Log the profile data
//           return response.result;  // Return the ProfileHeader object
//         } else {
//           throw new Error(response.message || 'Failed to get About');
//         }
//       }),
//       catchError((error) => {
//         console.error('About:', error);
//         return throwError(() => error);
//       })
//     );
// }



getJobs(userId): Observable<Job[]> {
  return this.http.get<ServicesResult<Job[]>>(`${environment.appUrl}/Profile/GetJobs?userId=${userId}`, { withCredentials: true })
    .pipe(
      map((response: ServicesResult<Job[]>) => {
        if (response.success && response.result) {
        // console.log('Job received:', response.result);  // Log the profile data
          return response.result;  // Return the ProfileHeader object
        } else {
          throw new Error(response.message || 'Failed to get Job');
        }
      }),
      catchError((error) => {
        console.error('joblist:', error);
        return throwError(() => error);
      })
    );
}


getEducation(userId): Observable<Education[]> {
  return this.http.get<ServicesResult<Education[]>>(`${environment.appUrl}/Profile/GetEducation?userId=${userId}`, { withCredentials: true })
    .pipe(
      map((response: ServicesResult<Education[]>) => {
        if (response.success && response.result) {
         // console.log('education list received:', response.result);  // Log the profile data
          return response.result;  // Return the ProfileHeader object
        } else {
          throw new Error(response.message || 'Failed to get education');
        }
      }),
      catchError((error) => {
        console.error('educationlist :', error);
        return throwError(() => error);
      })
    );
}





    









}
