import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MatDialogActions,
  MAT_DIALOG_DATA,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { CommonModule } from '@angular/common'
@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [MatButtonModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent,CommonModule],
  template: ` 
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <mat-dialog-content >
    {{ data.message }}
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close cdkFocusInitial>Ok</button> 
  </mat-dialog-actions>
    `,
  styles: [`
  .bg-success {
    background-color: #d4edda;
  }
  .bg-danger {
    background-color: #f8d7da;
  }
`]
})
export class NotificationComponent {

  constructor(
    public dialogRef: MatDialogRef<NotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, success: boolean,message: string }
  ) {}

}


