import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { DirectMessage } from '../../shared/chat/directMessage';
import { CommonModule } from '@angular/common';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import { ChatService } from '../../Account/profile/services/ChatService';


@Component({
  selector: 'app-direct-messages',
  standalone: true,
  imports: [CommonModule,MatMenuModule, MatIconModule],
  template: `
 <div style="overflow-y:auto; ">


<div class="card-text" *ngFor="let directMessage of directMessages">
<div class="d-flex align-itmes-start">
   <!-- Clickable image with menu trigger -->
   <img 
   [src]="directMessage?.profilePic" 
        alt="Avatar" 
        class="avatar me-2" 
        [matMenuTriggerFor]="profileMenu" 
        style="cursor: pointer;" 
      >
      
      <!-- Menu that opens when the image is clicked -->
      <mat-menu #profileMenu="matMenu">
        <button mat-menu-item (click)="viewProfile()">View Profile</button>
        <button mat-menu-item (click)="sendPrivateMessage(directMessage)">Send Message</button>
      </mat-menu>

<div class="d-flex flex-column">
<span class="fw-bold">{{directMessage.firstName}} {{directMessage.lastName}}&middot;{{directMessage.localTime}}{{directMessage.date}}</span>
<span class="ml-1 mb-3 mt-1"> {{directMessage.messageContent}}</span>
</div>
</div>
</div>



</div>
  `,
  styles: `.avatar {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    border-radius: 50%;
 }
 `
})
export class DirectMessagesComponent implements OnInit{
@Input() directMessages: DirectMessage[]=[];


constructor(private chatService: ChatService,private cdr: ChangeDetectorRef){}


viewProfile(){

  //console.log("view profile clicked");
}


sendPrivateMessage(directMessage: DirectMessage){

  
  console.log("send private message clicked",directMessage );
  console.log("send private message clicked");
}

  ngOnInit(): void {
  
   
  }


  

}
